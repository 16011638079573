import { apiPost } from "src/helpers/axios/config";
import { ISendEmailRequestBody, ISendSmsRequestBody } from "src/types";


class MarketingService {
  private readonly marketingUrl = "marketings";

  async sendEmailToSubscriber(body: ISendEmailRequestBody) {
    return await apiPost({ apiPath: `${this.marketingUrl}/send-email`, data: body });
  }

  async sendSmsToSubscription(body: ISendSmsRequestBody) {
    return await apiPost({ apiPath: `${this.marketingUrl}/send-sms`, data: body });
  }
}
export const marketingService = new MarketingService();
