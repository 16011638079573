export const statusClassName = (status: string) => {
  const classNames = {
    "PENDING": "bg-yellow-400",
    "CONFIRMED": "bg-green-300",
    "PROCESSING": "bg-blue-500 text-white",
    "OUT_FOR_DELIVERY": "bg-indigo-500 text-white",
    "DELIVERED": "bg-green-700 text-white",
    "CANCELLED": "bg-red-500 text-white",
    "RETURNED": "bg-red-400 text-white",
    "REFUNDED": "bg-red-500 text-white",
    "PAID": "bg-green-500 text-white",
    "FAILED": "bg-red-500 text-white",
    "DELIVERY_CHARGE_PAID": "bg-green-300"
  };
  return classNames[status];
};

export const statusTextClassName = (status: string) => {
  const classNames = {
    "PENDING": "text-yellow-400",
    "CONFIRMED": "text-green-300",
    "PROCESSING": "text-blue-500",
    "OUT_FOR_DELIVERY": "text-indigo-500",
    "DELIVERED": "text-green-700",
    "CANCELLED": "text-red-500",
    "RETURNED": "text-red-400",
    "REFUNDED": "text-red-500",
    "PAID": "text-green-500",
    "FAILED": "text-red-500",
    "DELIVERY_CHARGE_PAID": "text-green-300"
  };
  return classNames[status];
};
