import useRequest from "src/hooks/useRequest";
import { IRole } from "src/types/acl";

export const useRoleDetails = (id?: string) => {
  const url = `/roles/${id}`;
  const { data, mutate, ...rest } = useRequest(!!id ? url : null, url);

  return {
    data: data as IRole,
    isLoading: rest.isLoading,
    error: rest.error,
    mutate,
  };
};
