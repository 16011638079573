// apexcharts
import { ApexOptions } from 'apexcharts';

const chartOptions: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    type: 'datetime',
    labels: {
      hideOverlappingLabels: false,
      show: true,
      showDuplicates: true,
      datetimeUTC: true,
    },
  },
  legend: {
    width: 200
  }
};

export default chartOptions;