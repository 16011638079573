import React from 'react'
import Badge from '@mui/material/Badge';
import classnames from 'classnames'
interface IEcomBadge {
    children?: React.ReactNode;
    classNames?: string;
}
export default function EcomBadge({ children, classNames }: IEcomBadge) {
  return (
    <div className={classnames('px-2 rounded-md', classNames)}>
        {children}
    </div>
  )
}
