import { FC, ReactNode, useContext } from "react";
import { Box, alpha, lighten, useTheme } from "@mui/material";
import { Outlet, Navigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { authService } from "src/services";
import { SidebarContext } from "src/contexts/SidebarContext";
interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const { showSidebar } = useContext(SidebarContext);
  const theme = useTheme<any>();
  
  if (!authService.isLoggedIn()) {
    return <Navigate to={"/login"} />;
  }
  return (
    <>
      <Box
        className="bg-white"
        sx={{
          flex: 1,
          height: "100%",

          ".MuiPageTitle-wrapper": {
            background:
              theme.palette.mode === "dark"
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === "dark"
                ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15,
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1,
                )}, 0px 5px 12px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.05,
                )}`,
          },
        }}
      >
        <Box sx={{
          [theme.breakpoints.up(showSidebar ? "lg" : "xxl")]: {
            ml: `${theme.sidebar.width}`,
          },
        }}>
          <Header />
        </Box>
        <Sidebar />
        <Box
          sx={{
            position: "relative",
            zIndex: 5,
            display: "block",
            flex: 1,
            [theme.breakpoints.up(showSidebar ? "lg" : "xxl")]: {
              ml: `${theme.sidebar.width}`,
            },
          }}
        >
          <Box display="block" className="mx-5">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
