import React, { useState } from 'react'
import Spinner from 'src/generic-ui/Spinner';
import { usePaymentlog } from 'src/hooks/orders/usePaymentLogs'
import { QueryParamsType } from 'src/types';
import BasicTable from 'src/generic-ui/BasicTable'
import CustomPagination from 'src/generic-ui/CustomPagination/CustomPagination';
import dateUtils from 'src/helpers/utility/DateUtils';
import EmptyState from 'src/generic-ui/EmptyStates';
const PAGE_LIMIT = 5;

export default function PaymentLogs({ orderId }: { orderId: string }) {
    const [page, setPage] = useState(1);
    const query: Record<string, QueryParamsType> = {
        limit: PAGE_LIMIT,
        page: page,
        order: orderId
    };
    const { paymentLogs, isLoading, response } = usePaymentlog(query);

    if (isLoading) return <Spinner />;

    const columns = [
        {
            title: "Transaction id",
            accessor: "transactionId",
        },
        {
            title: "Amount",
            accessor: "",
            render: function({ ...data}: any) {
                return (
                    <>
                        ৳{data.amount}
                    </>
                )
            }
        },
        {
            title: "Payment status",
            accessor: 'paymentStatus'
        },
        {
            title: "Card type",
            accessor: '',
            render: function({ ...data}: any) {
                return (
                    <>
                        {data?.paymentValidationData?.card_type}
                    </>
                )
            } 
        },
        {
            title: "Initiated at",
            accessor: '',
            render: function ({ ...data }: any) {
                return (
                    <>
                        {dateUtils.formatDateTime(data.paymentInitiatedAt, "MMM D, YYYY hh:mm a")}
                    </>
                )
            }
        },
        {
            title: "Completed at",
            accessor: '',
            render: function ({ ...data }: any) {
                return (
                    <>
                        {dateUtils.formatDateTime(data.paymentCompletedAt, "MMM D, YYYY hh:mm a")}

                    </>
                )
            }
        },
    ]

    const res: any = response;
    const meta = res?.meta;

    return (
        <>
            {paymentLogs.length > 0 ? <BasicTable tableHeaders={columns} tableRows={paymentLogs} renderTableFooter={
                <div className='flex justify-center my-3'>
                    <CustomPagination
                        onPageChange={(nexPageNumber: number) => {
                            setPage(nexPageNumber);
                        }}
                        totalCount={meta?.total}
                        pageSize={PAGE_LIMIT}
                        currentPage={page}
                    />
                </div>
            } /> : <div className='flex justify-center my-5'>
                    <EmptyState mainTitle='No payment log found' />
                </div>}

        </>
    )
}
