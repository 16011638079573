import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";
import utils from "src/helpers/Utils";
import { CatalogSchemaType } from "src/schemas";

class WarehouseService {
  private readonly url = "warehouses";
  async createWarehouse(body: any) {
    return apiPost({ apiPath: `${this.url}`, data: body });
  }
  async updateWarehouse({
    id,
    ...brand
  }: CatalogSchemaType & {
    id: string;
  }) {
    const data: { [key: string]: any } = {};
    Object.keys(brand).forEach(key => {
      if (!utils.checkIsNullOrUndefined(brand[key])) data[key] = brand[key];
    });

    return apiPatch({ apiPath: `${this.url}/${id}`, data });
  }

  async deleteWarehouse(id: string) {
    return apiDelete({ apiPath: `${this.url}/${id}` });
  }
}

export const warehouseService = new WarehouseService();
