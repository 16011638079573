import React, { useEffect, useMemo, useState } from "react";

import { IconButton, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { DateTimePicker, DateTimePickerProps } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { logger } from "src/services";
import ClearIcon from '@mui/icons-material/Clear';

interface IDatePicker {
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  className?: string;
  disabled?: boolean;
  isReset?: boolean;
}

export default function DatePicker({
  onChange,
  value,
  label,
  className,
  disabled = false,
  isReset
}: IDatePicker) {
  const [date, setDate] = useState<Dayjs | null>(dayjs(value));


  useEffect(() => {
    if(isReset) {
      setDate(null)
    }
  }, [isReset])
  

  const handleReset = () => {
    setDate(null);
  };

  const selectedDate = useMemo(() => {
    return date;
  }, [date]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={selectedDate}
        views={["year", "month", "day"]}
        disabled={disabled}
        className={className}
        onChange={newValue => {
          setDate(newValue);
          onChange?.(dayjs(newValue).toISOString());
        }}
        clearable={true}
        renderInput={props => {
          return (
            <TextField
              {...props}
              margin="normal"
              fullWidth
              label={label}
              inputProps={{ ...props.inputProps, readOnly: true }}
              InputProps={{
                endAdornment: (
                  <React.Fragment>
                    {selectedDate && (
                      <IconButton onClick={handleReset}>
                        <ClearIcon />
                      </IconButton>
                    )}
                    {props.InputProps?.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}
