import useRequest from "src/hooks/useRequest";
import { QueryParamsType } from "src/types";
import { IPermission } from "src/types/acl";
import { usePermissionType } from "../permission-type/usePermissionType";

export const usePermissionDetails = (id?: string) => {
  const url = `/permissions/${id}`;
  const { data, mutate, ...rest } = useRequest(!!id ? url : null, url);

  const query: Record<string, QueryParamsType> = {
    limit: 100,
    page: 1,
    isActive: true
  };
  const { permissionTypes , ...rest2 } = usePermissionType(query);

  return {
    data: data as IPermission,
    isLoading: rest.isLoading || rest2.isLoading,
    error: rest.error || rest2.error,
    mutate,
    permissionTypes,
  };
};
