import { QueryParamsType } from "src/types";
import useRequest from "../useRequest";
import { useCity } from "./useCity";

export const useWarehouseDetails = (id?: string) => {
  const url = `/warehouses/${id}`;
  const { data, mutate, ...rest } = useRequest(!!id ? url : null, url);
  const query: Record<string, QueryParamsType> = {
    limit: 100,
    page: 1,
  };
  const { cities, ...rest2 } = useCity(query);

  return {
    data: data as any,
    isLoading: rest.isLoading || rest2.isLoading,
    error: rest.error || rest2.error,
    mutate,
    cities,
  };
};
