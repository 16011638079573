import { Box, ListItem, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import dateUtils from 'src/helpers/utility/DateUtils';
import { INotification } from 'src/types'

export default function NotificationItem({ item, config }: { item: INotification, config: any }) {    
    return (
        <ListItem
            sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
        >
            <Box flex="1">
                <Box display="flex" justifyContent="space-between">
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {item?.text}
                    </Typography>
                    <Typography variant="caption" sx={{ textTransform: 'none' }} className='ml-3'>
                        {dateUtils.formatDateAsFromNow(item.createdAt)}
                    </Typography>
                </Box>
                <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                >
                    {' '}
                    click <Link to={item?.navigateTo} onClick={() => config.handleClose()}className='underline'>here</Link> to view
                </Typography>
            </Box>
        </ListItem>
    )
}
