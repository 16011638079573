import { Box, Button } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { logger } from "src/services";
import { IProduct } from "src/types";
import NewOrderItemCard from "./NewOrderItemCard";
import SideBarModal from "src/components/modals/SideBarModal";
import SearchProducts from "src/components/SearchProducts/SearchProducts";
import {
  parsedNewlyAddedProductForUpdate,
  parsedProductsForNewAddedOrderItem,
} from "src/transformer/order";
import { orderService } from "src/services/order.service";
import ToastNotification, {
  notifyError,
  notifySuccess,
} from "src/generic-ui/ToastNotification";

export default function NewlyAddedItems({
  existingOrderItems,
  orderStatus,
  orderId,
  mutate,
}) {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      newOrderItems: [],
    },
    onSubmit: async (values, helper) => {
      const parsedItems = values?.newOrderItems?.map(el =>
        parsedNewlyAddedProductForUpdate(el),
      );
      const obj = {
        newOrderItems: parsedItems,
      };

      try {
        const response = await orderService.updateOrderModify(orderId, obj);
        logger.log(response);
        notifySuccess("new order item added successfully");
        mutate();
        helper.resetForm();
      } catch (error) {
        logger.error(error);
        notifyError(error.message);
      }
    },
  });

  const onToggleModal = () => setModalVisible(prev => !prev);

  const onCloseModal = () => {
    onToggleModal();
  };

  const { values, setFieldValue, submitForm } = formik;

  const onSelectProduct = (product: any) => {
    const modifiedProducts: any = parsedProductsForNewAddedOrderItem(product);
    let preProduct = [...values?.newOrderItems];
    const newList = Object.assign([], preProduct);
    newList.push({ ...modifiedProducts });
    setFieldValue("newOrderItems", newList);
  };

  return (
    <>
      {orderStatus === "DELIVERED" || orderStatus === "CANCELLED" ? null : (
        <>
          <div className="flex">
            <p className="m-4 text-base font-semibold">
              Newly added item ({values?.newOrderItems?.length})
            </p>

            <Button
              onClick={e => {
                onToggleModal();
              }}
              color="primary"
              className={
                "h-10 mt-1 mr-4 bg-indigo-500 hover:bg-indigo-700 text-white ml-auto"
              }
            >
              + Add Product
            </Button>

            {values?.newOrderItems?.length > 0 ? (
              <Button
                onClick={() => {
                  submitForm();
                }}
                className="bg-green-500 hover:bg-green-600 text-white h-10 mt-1 mr-4"
              >
                Save changes
              </Button>
            ) : null}
          </div>
          <FormikProvider value={formik}>
            <form>
              <ul role="list" className="mx-4">
                {values?.newOrderItems?.map(
                  (orderItem: IProduct, index: number) => {
                    return (
                      <div key={index}>
                        <NewOrderItemCard orderItem={orderItem} />
                      </div>
                    );
                  },
                )}
              </ul>
            </form>
          </FormikProvider>
          {isModalVisible && (
            <SideBarModal
              isVisible={isModalVisible}
              onClose={onCloseModal}
              left={60}
              allowBackdrop={false}
              title="Add Product"
            >
              <Box p="0.5rem">
                <SearchProducts
                  onClick={onSelectProduct}
                  selectedProducts={[
                    ...values?.newOrderItems,
                    ...existingOrderItems,
                  ]?.map(p => p.product.id)}
                />
              </Box>
            </SideBarModal>
          )}
        </>
      )}
    </>
  );
}
