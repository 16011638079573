export const permissions = {
  home: {
    allPermissions: ["dashboard.view", "report.view", "*"],
    permissionSet: {
      dashboard: {
        view: "dashboard.view",
      },
      report: {
        view: "report.view",
      },
      admin: "*",
    },
  },
  product: {
    allPermissions: [
      "product.create",
      "product.edit",
      "product.view",
      "product.details",
      "inventory.view",
      "price.view",
      "*",
    ],
    permissionSet: {
      product: {
        create: "product.create",
        view: "product.view",
        details: "product.details",
        edit: "product.edit",
      },
      inventory: {
        view: "inventory.view",
      },
      price: {
        view: "price.view",
      },
      admin: "*",
    },
  },
  catalog: {
    allPermissions: [
      "category.view",
      "category.create",
      "category.edit",
      "variant.view",
      "variant-option.view",
      "brand.view",
      "special-category.view",
      "*",
    ],
    permissionSet: {
      category: {
        create: "category.create",
        view: "category.view",
        edit: "category.edit",
      },
      variant: {
        view: "variant.view",
      },
      "variant-option": {
        view: "variant-option.view",
      },
      brand: {
        view: "brand.view",
      },
      "special-category": {
        view: "special-category.view",
      },
    },
  },
  order: {
    allPermissions: [
      "order.create",
      "order.view",
      "order.details",
      "notification.view",
      "*",
    ],
    permissionSet: {
      order: {
        create: "order.create",
        view: "order.view",
        details: "order.details",
      },
      "payment-method": {
        view: "payment-method.view",
      },
      "delivery-charge": {
        view: "delivery-charge.view",
      },
      notification: {
        view: "notification.view",
      },
      admin: "*",
    },
  },
  transaction: {
    allPermissions: [
      "transaction.view",
      "transaction.details",
      "notification.view",
      "*",
    ],
    permissionSet: {
      transaction: {
        view: "transaction.view",
        details: "transaction.details",
      },
      notification: {
        view: "notification.view",
      },
      admin: "*",
    },
  },
  "user-management": {
    allPermissions: ["*", "customer.view"],
    permissionSet: {
      customer: {
        view: "customer.view",
      },
      admin: "*",
    },
  },
  marketing: {
    allPermissions: ["*", "subscription.view", "sms.send", "email.send"],
    permissionSet: {
      subscription: {
        view: "subscription.view",
      },
      sms: {
        send: "sms.send",
      },
      email: {
        send: "email.send",
      },
      admin: "*",
    },
  },
  "business-config": {
    allPermissions: ["*"],
    permissionSet: {
      admin: "*",
    },
  },
  "access-control": {
    allPermissions: ["*"],
    permissionSet: {
      admin: "*",
    },
  },
  others: {
    allPermissions: [
      "rating-review.view",
      "feedback.view",
      "testimonial.view",
      "rating-review.create",
      "*",
    ],
    permissionSet: {
      admin: "*",
      "rating-review": {
        view: "rating-review.view",
        create: "rating-review.create",
      },
      feedback: {
        view: "feedback.view",
      },
      testimonial: {
        view: "testimonial.view",
      },
    },
  },
  gallery: {
    allPermissions: ["gallery.view", "*"],
    permissionSet: {
      view: "gallery.view",
      admin: "*",
    },
  },
  cms: {
    allPermissions: ["banner.view", "banner.create", "*"],
    permissionSet: {
      banner: {
        view: "banner.view",
        create: "banner.create",
      },
      admin: "*",
    },
  },
  location: {
    allPermissions: [
      "city.view",
      "area.view",
      "warehouse.view",
      "warehouse.create",
      "*",
    ],
    permissionSet: {
      city: {
        view: "city.view",
      },
      area: {
        view: "area.view",
      },
      warehouse: {
        view: "warehouse.view",
        create: "warehouse.create",
      },
      admin: "*",
    },
  },
  offer: {
    allPermissions: ["coupon.view", "campaign.view", "*"],
    permissionSet: {
      coupon: {
        view: "coupon.view",
      },
      campaign: {
        view: "campaign.view",
      },
      admin: "*",
    },
  },
};
