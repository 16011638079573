import { IRole } from "src/types/acl";

export const parseUserCreateRequest = (metadata: any, context="create") => {
  const { firstName, lastName, password, roles, email, rolesForUpdate } = metadata;
 
  const obj = {
    firstName,
    lastName,
  };

  if(context === "update") {
    const modifiedRoleForUpdate = rolesForUpdate.map((role: IRole & { isDeleted: boolean }) => {
      const obj = {};
      obj['role'] = role.id;
      role.isDeleted ? obj['isDeleted'] = role.isDeleted : null;
      return obj;
    });
    obj['roles'] = [...modifiedRoleForUpdate]
  }
  else if(context === "create") {
    const modifiedRoleForCreate = roles.map((role: IRole) => {
      return {
        role: role.id,
      };
    });
    obj['email'] = email;
    obj['roles'] = [...modifiedRoleForCreate];
    obj['password'] = password;
  }

  return obj;
};

