import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import SearchPermissions from "src/components/acl/role/SearchPermission";
import SideBarModal from "src/components/modals/SideBarModal";
import {
  DEBOUNCE_DELAY,
  DEFAULT_PAGE_SIZE,
} from "src/constants/globalConstants";
import { DataTable } from "src/generic-ui/DataTable";
import Switch from "src/generic-ui/Switch";
import { useAvailablePermissionAgainstRole } from "src/hooks/acl/permission/useAvailablePermission";
import { useRole } from "src/hooks/acl/role/useRole";
import { useDebounce } from "src/hooks/useDebounce";
import { logger } from "src/services";
import { roleService } from "src/services/acl";
import { IArea } from "src/types";
import { IRole } from "src/types/acl";
import { QueryParamsType } from "src/types/query-params";
import Sidebar from "./Sidebar";
import { permissions } from "src/constants/permissions";
import withPermission from "src/components/permission/withPermission";
import ActionBar from "src/generic-ui/ActionBar";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

function Role() {
  const [isSidebarVisible, setVisible] = useState<boolean>(false);
  const [isEditPermissionSidebarVisible, setEditPermissionSidebarVisible] =
    useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [selectedRowId, setSelectedRowId] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [selectedId, setSelected] = useState<string | null>(null);
  const debouncedSearchTerm = useDebounce({
    searchQuery: searchTerm,
    delay: DEBOUNCE_DELAY,
  });
  const query: Record<string, QueryParamsType> = {
    limit: pageSize,
    page: page + 1,
  };
  if (!!debouncedSearchTerm) query["searchTerm"] = debouncedSearchTerm;

  const { isLoading, roles, mutate, error, meta } = useRole(query);
  const onToggleSideBar = () => setVisible((prev) => !prev);

  const {
    availablePermissions,
    isLoading: isAvailablePermissionLoading,
    mutate: mutateAvailablePermissions,
  } = useAvailablePermissionAgainstRole(selectedRowId, { limit: 100, page: 1 });

  const [rowCountState, setRowCountState] = useState(meta?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.total !== undefined ? meta?.total : prevRowCountState
    );
  }, [meta, setRowCountState]);

  const onClose = () => {
    onToggleSideBar();
    setSelected(null);
  };

  const handleOnCellClick = ({ row }: { row: IRole }) => {
    setSelected(row.id);
    onToggleSideBar();
  };

  const onRefresh = () => {
    mutate();
  };

  const updateElement = async ({
    keyName,
    id,
    value,
  }: {
    keyName: string;
    id: string;
    value: any;
  }) => {
    try {
      await roleService.updateRole(id, { [keyName]: value });
    } catch (error) {
      logger.error(error);
    } finally {
      onRefresh();
    }
  };

  const columns: any = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      renderCell: ({ row }: { row: IArea }) => (
        <Switch
          isChecked={!!row.isActive}
          showPopOver={true}
          onClick={(isChecked: boolean) =>
            updateElement({ keyName: "isActive", value: isChecked, id: row.id })
          }
        />
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: IRole }) => (
        <>
          <Button
            onClick={() => {
              setSelectedRowId(row.id);
              setEditPermissionSidebarVisible(true);
            }}
            className="bg-indigo-600 hover:bg-indigo-700 text-white"
          >
            Edit permission
          </Button>
          <Button
            onClick={() => {
              handleOnCellClick({ row });
            }}
            className="bg-indigo-600 hover:bg-indigo-700 text-white ml-2"
          >
            Edit
          </Button>
        </>
      ),
    },
  ];
  if (error) {
    return <div>Something Went Wrong</div>;
  }

  const onReset = () => {
    setSearchTerm("");
  };

  const showResetAllButton = useMemo(() => {
    return !!debouncedSearchTerm;
  }, [debouncedSearchTerm]);

  const onToggleModal = () => setEditPermissionSidebarVisible((prev) => !prev);

  const onCloseModal = () => {
    onToggleModal();
  };

  const modifiedRoles = roles.filter((role: any) => role.title !== "*");

  return (
    <>
      <Helmet>
        <title>AAAF LTD - Role</title>
      </Helmet>
      <ActionBar title="Roles">
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <input
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className=" block  rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
          />
          <Tooltip arrow title="create">
            <IconButton
              className="bg-indigo-500 hover:bg-indigo-600 text-white ml-2 p-1.5"
              onClick={() => onToggleSideBar()}
            >
              <AddCircleIcon fontSize="medium" className="text-white" />
            </IconButton>
          </Tooltip>
          {showResetAllButton && (
            <Tooltip arrow title="reset filter">
              <IconButton
                className="bg-red-500 hover:bg-red-600 text-white ml-2 p-1.5"
                onClick={() => {
                  onReset();
                }}
              >
                <RestartAltIcon fontSize="medium" className="text-white" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </ActionBar>
      <DataTable
        columns={columns}
        data={modifiedRoles}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        disableColumnMenu={true}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCountState={rowCountState}
      />
      {isSidebarVisible && (
        <Sidebar
          isVisible={isSidebarVisible}
          onClose={onClose}
          selectedId={selectedId}
          onRefresh={onRefresh}
        />
      )}

      {isEditPermissionSidebarVisible && (
        <SideBarModal
          isVisible={isEditPermissionSidebarVisible}
          onClose={onCloseModal}
          left={60}
          allowBackdrop={false}
          title="Assign permissions"
        >
          <Box p="0.5rem">
            <SearchPermissions
              roleId={selectedRowId}
              onClick={() => {}}
              selectedPermissions={
                !isAvailablePermissionLoading
                  ? availablePermissions?.map((p: any) => {
                      if (p.id && p?.isAlreadyAdded === true) {
                        return p.id;
                      }
                    })
                  : []
              }
              config={{ mutateAvailablePermissions }}
            />
          </Box>
        </SideBarModal>
      )}
    </>
  );
}

export default withPermission(
  Role,
  permissions["access-control"].permissionSet.admin
);
