import utils from "src/helpers/Utils";
import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";

class ProductRatingService {
  private readonly url = "product-ratings";
  async createProductRating(body: any) {
    return apiPost({ apiPath: `${this.url}`, data: body });
  }

  async updateProductRating({
    id,
    ...productRating
  }: any) {
    const data: { [key: string]: any } = {};
    Object.keys(productRating).forEach(key => {
      if (!utils.checkIsNullOrUndefined(productRating[key]))
        data[key] = productRating[key];
    });

    return apiPatch({ apiPath: `${this.url}/${id}`, data });
  }

  async deleteProductRating(id: string) {
    return apiDelete({ apiPath: `${this.url}/${id}` });
  }
}

export const productRatingService = new ProductRatingService();
