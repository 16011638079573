import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { useVariantOptions } from 'src/hooks/variant-options/useVariantOptions';
import { QueryParamsType } from 'src/types/query-params';
import _ from 'lodash';
import { useFormikContext } from 'formik';
import { productService } from 'src/services/product.service';
import _without from "lodash/without";
import MuiMultiSelectChipBox from 'src/generic-ui/MuiMultiSelectChipBox';

export default function VariantOptionBox({ variant, config }: any) {
    const { values, setFieldValue } = useFormikContext<any>();
    const productPersistedFormData = productService.getPesistedProductFormData()
    const { selectedVariants, setSelectedVariants, manageVariants, setManageVariants } = config;
    // const query: Record<string, QueryParamsType> = {
    //     limit: 40,
    //     page: 1
    // }
    // query["variant"] = variant.value;

    const { variantOptions, isLoading, mutate } = useVariantOptions({
        limit: 40,
        page: 1,
        variant: variant.value
    });
    useEffect(() => {
        mutate();
    }, [mutate]);
    productPersistedFormData ? productPersistedFormData.manageVariants[variant.label] : []

    const [selectedOptions, setSelectedOptions] = useState<any>(productPersistedFormData ? productPersistedFormData.manageVariants[variant.label] : [])

    useEffect(() => {
        let variantObject = {}
        variantObject[variant.label] = []
        setManageVariants((prev) => {
            return { ...prev, ...variantObject }
        })
    }, [variant.label])

    useEffect(() => {
        setManageVariants((prev) => {
            const label = variant.label;
            prev[label] = selectedOptions;
            return {
                ...prev
            }
        });
        const obj = {};
        obj[variant.label] = selectedOptions;
        setFieldValue('manageVariants', { ...productPersistedFormData.manageVariants, ...obj })

    }, [variant.label, selectedOptions]);

    if (isLoading) return null;

    const modifiedVariantOptions = variantOptions.filter((variantOption) => variantOption.isActive === true).map((activeVariantOption) => {
        return {
            label: activeVariantOption.title,
            value: activeVariantOption.id
        }
    });

    return (
        <div className="border rounded-md px-10 py-5 my-4">
            <div className="flex">
                <p className="mt-1 font-bold uppercase">{variant.label}</p>
                <IconButton aria-label="delete" size="small" className='ml-auto' onClick={() => {
                    const temp = selectedVariants;
                    const modifiedVariants = temp.filter((el) => el.value !== variant.value)
                    setSelectedVariants(modifiedVariants)
                }}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </div>
            <div className="mt-5">
                <MuiMultiSelectChipBox data={modifiedVariantOptions} config={{ selectedOptions, setSelectedOptions }} title={variant.label} />
            </div>
        </div>
    )
}
