import useRequest from "src/hooks/useRequest";
import { IPermissionType } from "src/types/acl";

export const usePermissionTypeDetails = (id?: string) => {
  const url = `/permission-types/${id}`;
  const { data, mutate, ...rest } = useRequest(!!id ? url : null, url);

  return {
    data: data as IPermissionType,
    isLoading: rest.isLoading,
    error: rest.error,
    mutate,
  };
};
