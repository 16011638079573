import { QueryParamsType } from "src/types/query-params";
import { useSwrRequest } from "../useSwrRequest";
import qs from "qs";
import useRequest from "../useRequest";
import { IArea, ICity } from "src/types";
import { logger } from "src/services";

export const useWarehouse = ({
  page = 1,
  limit = 20,
  searchTerm,
  city
}: {
  page: number;
  limit: number;
  searchTerm?: string;
  city?: string;
}) => {
  let query = {
    page,
    limit
  }
  if (!!searchTerm) query["searchTerm"] = searchTerm;
  if (!!city) query["city"] = city;

  const url = `/warehouses?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);
  return {
    warehouses: (data as any) || [],
    mutate,
    isLoading,
    error,
    ...rest,
  };
};
