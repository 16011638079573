// auth
export const LOGIN = "auth/login";
export const RESET_PASSWORD_REQUEST = "auth/reset-password-request"
export const RESET_PASSWORD_VERIFY = "auth/reset-password-verify"

/// orders
export const ORDER_LISTS = "orders";
export const ORDER_DETAILS = "orders/:code";

/// products
export const PRODUCT_LISTS = "products";
export const PRODUCTS = "products";
export const PRODUCTS_CATALOGS = "products/catalogs";

export const FILES = "files";


export const Transacton_LISTS = "payment-logs";
