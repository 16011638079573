import { createSlice } from "@reduxjs/toolkit";

export const adminUserSlice = createSlice({
  name: "admin",
  initialState: {},
  reducers: {
    setAdminDetails: (state, action) => {
      // state.push(action.payload);
      state = action.payload
    },
  },
});

// this is for dispatch
export const { setAdminDetails } = adminUserSlice.actions;

// this is for configureStore
export default adminUserSlice.reducer;
