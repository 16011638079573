import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import React from "react";
import { IModals } from "src/types";
interface ISidebarModal extends IModals {
  children?: React.ReactElement;
  title?: string;
  renderEndIcon?: React.ReactElement;
  left?: number;
  allowBackdrop?: boolean;
}
export default function SideBarModal({
  onClose,
  isVisible,
  children,
  renderEndIcon,
  title = "",
  left = 60,
  allowBackdrop = true,
}: ISidebarModal) {
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick" && allowBackdrop) return;
    onClose();
  };
  return (
    <>
      <Dialog
        sx={{
          position: "absolute",
          maxWidth: `${100 - left}%`,
          height: "100%",
          left: `${left}%`,
        }}
        fullScreen
        open={isVisible}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingX: 2,
            paddingTop: 3,
          }}
        >
          <Typography variant="h4">{title}</Typography>
          <Box>
            {!!renderEndIcon && renderEndIcon}
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        {children}
      </Dialog>
    </>
  );
}
