import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, Popover, Typography } from "@mui/material";
import { logger } from "src/services";
import { orderService } from "src/services/order.service";
import ToastNotification, {
  notifySuccess,
} from "src/generic-ui/ToastNotification";

interface IOrderItemQuantity {
  quantity: number;
  isNewAdded?: boolean;
  productId?: string;
  mutate?: any;
  config?: {
    productId: string;
    mutate: any;
    orderId: string;
  };
}
export default function OrderItemQuantity({
  quantity,
  isNewAdded,
  config,
}: IOrderItemQuantity) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [count, setCount] = useState<number>(1);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOk = async () => {
    //update quantity
    const { productId, orderId, mutate } = config;
    try {
      const body = {
        quantities: [
          {
            product: productId,
            quantity: count,
          },
        ],
      };
      const response = await orderService.updateOrderModify(orderId, body);
      logger.log(response);
      notifySuccess("Quantity updated successfully");
      mutate();
    } catch (error) {
      logger.error(error);
      notifySuccess(error.message);
    }
    setAnchorEl(null);
  };

  const increase = () => {
    setCount(quantity + 1);
  };
  const decrease = () => {
    setCount(quantity - 1);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="flex mt-5 w-28 border py-0 rounded-md font-bold px-1">
      <button
        className=""
        type="button"
        onClick={e => {
          e.preventDefault();
          if (!isNewAdded) {
            handleClick(e);
            increase();
          } else {
            // increase quantity logic
            increase();
          }
        }}
      >
        <AddIcon className="h-5 w-5" />
      </button>

      <input
        type="text"
        className="w-10 h-5 text-center border-transparent bg-white my-0 mx-2 placeholder:text-black"
        placeholder={!isNewAdded ? quantity.toString() : count.toString()}
      />
      <button
        onClick={e => {
          e.preventDefault();
          if (!isNewAdded) {
            handleClick(e);
            decrease();
          } else {
            // decrease quantity logic
            decrease();
          }
        }}
        type="button"
        disabled={quantity <= 1 ? true : false}
      >
        <RemoveIcon className="h-5 w-5" />
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ paddingY: "1rem", paddingX: "1rem" }}>
          Do you want to change the quantity?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleOk}>Yes</Button>
        </Box>
      </Popover>
    </div>
  );
}
