import CheckIcon from '@mui/icons-material/Check';
import { parsedOrderLifeCycle } from 'src/transformer/order';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function OrderLifeCycle({ initialLifeCycles, timelines, currentStatus }) {
    const steps = initialLifeCycles.filter((el) => el.show === true).map((lifeCycle: any, index: number) => {
        if (timelines[index] && lifeCycle.status === currentStatus) {
            if(timelines[index].orderStatus === "DELIVERED") {
                lifeCycle.state = "complete";
                return parsedOrderLifeCycle({ ...lifeCycle, ...timelines[index] })
            }
            lifeCycle.state = "current";
            return parsedOrderLifeCycle({ ...lifeCycle, ...timelines[index] })
        }
        else if(timelines[index]) {
            lifeCycle.state = "complete"
            return parsedOrderLifeCycle({ ...lifeCycle, ...timelines[index] })
        }
        else {
            lifeCycle.title = lifeCycle.status;
            lifeCycle.state = "upcoming";
            return parsedOrderLifeCycle({ ...lifeCycle })
        }
    });
   
    return (
        <nav aria-label="Progress" className='flex justify-center 2xl:block xl:block lg:block'>
            <ol role="list" className="overflow-hidden">
                {steps.map((step, stepIdx) => (
                    <li key={step?.title} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
                        {step?.state === 'complete' ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600" aria-hidden="true" />
                                ) : null}
                                <div className="group relative flex items-start">
                                    <span className="flex h-9 items-center">
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium">{step?.title}</span>
                                        <small className="text-sm text-gray-500">{step?.createdAt ? step?.createdAt : "-"}</small>
                                        <span className="text-sm text-gray-500">{step?.comments ? step?.comments : "-"}</span>
                                    </span>
                                </div>
                            </>
                        ) : step?.state === 'current' ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                                ) : null}
                                <div className="group relative flex items-start" aria-current="step">
                                    <span className="flex h-9 items-center" aria-hidden="true">
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                                            <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium text-indigo-600">{step?.title}</span>
                                        <small className="text-sm text-gray-500">{step?.createdAt ? step?.createdAt : "-"}</small>
                                        <span className="text-sm text-gray-500">{step?.comments ? step?.comments : "-"}</span>
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                                ) : null}
                                <div className="group relative flex items-start">
                                    <span className="flex h-9 items-center" aria-hidden="true">
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                                            <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium text-gray-500">{step?.title}</span>
                                        <small className="text-sm text-gray-500">{step?.createdAt ? step?.createdAt : "-"}</small>
                                        <span className="text-sm text-gray-500">{step?.comments ? step?.comments : "-"}</span>
                                    </span>
                                </div>
                            </>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}
