import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";
import { IPermissionTypeRequestPayload } from "src/types/acl";

class PermissionType {
  private readonly PERMISSION_TYPE_URL = "permission-types";
  async createPermissionType(data: IPermissionTypeRequestPayload) {
    return await apiPost({
      apiPath: this.PERMISSION_TYPE_URL,
      data,
    });
  }

  async updatePermissionType(id: string, data: IPermissionTypeRequestPayload) {
    const url = `${this.PERMISSION_TYPE_URL}/${id}`;
    return await apiPatch({
      apiPath: url,
      data,
    });
  }

  async deletePermissionType(id: string) {
    const url = `${this.PERMISSION_TYPE_URL}/${id}`;
    return await apiDelete({
      apiPath: url,
    });
  }
}

export const permissionTypeService = new PermissionType();
