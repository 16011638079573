import { QueryParamsType } from "src/types/query-params";
import qs from "qs";
import useRequest from "src/hooks/useRequest";
import { IPermission } from "src/types/acl";
import { useMemo } from "react";
import { usePermissionType } from "../permission-type/usePermissionType";

interface IUserPermissionLists {
    page: number;
    limit: number;
    searchTerm?: any;
    permissionType?: string;
    
}

export const usePermissionLists = ({
    page,
    limit,
    searchTerm,
    permissionType,
}: IUserPermissionLists) => {
  const permissionTypeQuery: Record<string, QueryParamsType> = {
    limit: 100,
    page: 1,
  };

  const query: Record<string, QueryParamsType> = {
    limit,
    page,
  }

  if(permissionType) {
    query['permissionType'] = permissionType;
  }

  if(searchTerm) {
    query['searchTerm'] = searchTerm;
  }

  const url = `/permissions?${qs.stringify(query)}`;
  const { data, mutate: mutatePermissionList , isLoading, error, ...rest } = useRequest(url, url);
  const { permissionTypes , ...rest2 } = usePermissionType(permissionTypeQuery);

  return {
    permissions: (data as Array<IPermission>) || [],
    mutatePermissionList,
    isLoading: isLoading || rest2.isLoading,
    error: error || rest2.error,
    permissionTypes,
    ...rest,
  };
};
