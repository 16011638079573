import { IconButton } from '@mui/material';
import React, { useEffect } from 'react'
import MultipleSelectChip from 'src/pages/product/product-list/edit/CustomVariantOptionDrowdown'
import VariantOption from './VariantOption'
import DeleteIcon from '@mui/icons-material/Delete';

export default function Variant({ variant, config }) {
    const { label, value } = variant;
    const { selectedVariants, setSelectedVariants, manageVariants, setManageVariants } = config;

    return (
        <div>
            <div className='flex justify-center mx-auto'>
                <p className='ml-5'>{label}</p>
                <IconButton aria-label="delete" size="small" className='ml-auto mr-5' onClick={() => {
                    const temp = selectedVariants;
                    const modifiedVariants = temp.filter((el) => el.value !== variant.value)
                    setSelectedVariants(modifiedVariants);
                    setManageVariants((prev) => {
                        const obj = {};
                        obj[label] = []
                        return {
                            ...prev,
                            ...obj
                        }
                    })
                }}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </div>
            <div>
                <VariantOption variantId={value} title={label} config={{manageVariants, setManageVariants}}/>
            </div>
        </div>
    )
}
