import { QueryParamsType } from "src/types/query-params";
import qs from "qs";
import useRequest from "src/hooks/useRequest";

interface IUseSubscription {
  page?: number;
  limit?: number;
  searchTerm?: string;
  isActive?: boolean;
}
export const useSubscription = ({
  page = 1,
  limit = 10,
  searchTerm = "",
  isActive
}: IUseSubscription) => {
  const query: Record<string, QueryParamsType> = {
    page,
    limit,
  };
  if (!!searchTerm) query["searchTerm"] = searchTerm;
  if (!!isActive) query["isActive"] = isActive;
  const url = `/news-letter-subscriptions?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);

  return {
    subscriptions: (data as any) || [],
    mutate,
    isLoading: isLoading,
    error: error,
    ...rest,
  };
};
