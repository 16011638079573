import { Button } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import ImageCard from './ImageCard';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormikContext } from 'formik';
import { productService } from 'src/services/product.service';
import AlertNotification from 'src/generic-ui/AlertNotification';
import GalleryLayout from 'src/components/gallery/GalleryLayout';
import Popover from 'src/generic-ui/Popover'

export default function UploadImages() {
  const [open, setOpen] = useState<boolean>(false);
  const [openGallery, setOpenGallery] = useState<boolean>();
  const productPersistedFormData = productService.getPesistedProductFormData()
  const { setFieldValue, errors, touched } = useFormikContext<any>();
  const [imageBoxes, setImageBoxes] = useState(productPersistedFormData ? [...productPersistedFormData.images] : []);
  const [selectedForThumbnail, setSelectedForThumbnail] = useState();

  useEffect(() => {
    if(openGallery === false){
      setImageBoxes(productPersistedFormData?.images)
    }
  }, [openGallery])

  return (
    <div>
      <div className="my-3">
        <AlertNotification message="file is uploaded successfully" open={open} setOpen={setOpen} />
      </div>
      {imageBoxes.length > 0 ? (
        imageBoxes.map((el) => {
          return (<Fragment key={el}>
            <ImageCard imageObject={el} config={{ imageBoxes, setImageBoxes, selectedForThumbnail, setSelectedForThumbnail }} />
          </Fragment>)
        })
      ) : <>
        <div className="border-2 border-dashed text-center px-10 py-7 my-4">
          <p>No image selected yet</p>
        </div>
      </>}
      {errors.images && touched.images ? (
        <p className="text-red-600">{errors.images}</p>
      ) : null}

      <div className="flex">
        <Button
          variant="contained"
          className="mt-2 bg-indigo-600"
          onClick={() => {
            setOpenGallery(true)
          }}
        >
          <AddPhotoAlternateIcon /> <span className="ml-1">Add image</span>
        </Button>

        {imageBoxes.length > 0 && <Button
          variant="contained"
          className="mt-2 ml-2 bg-red-600 hover:bg-red-700"
          onClick={() => { 
            setImageBoxes([]) 
            setFieldValue('images', [])
          }}
        >
          <DeleteIcon fontSize="small" />
          <span className="ml-1">Delete all</span>
        </Button>}

        <Popover
          title=""
          width={1000}
          height={800}
          open={openGallery}
          setOpen={setOpenGallery}
          onClose={() => { setOpenGallery(false) }}
        >
          <GalleryLayout isOpenFromModal modalConfig={{ open: openGallery , setOpen: setOpenGallery }} setFieldValue={setFieldValue}/>
        </Popover>
      </div>
    </div>
  )
}
