export interface IPaymentMethod {
  id: string;
  createdAt: string;
  updatedAt?: null;
  title: string;
  icon: string;
  isActive: boolean;
}

export const PAYMENT_STATUS = [
  "PENDING",
  "PAID",
  "FAILED",
  "DELIVERY_CHARGE_PAID"
];

export const PAYMENT_METHOD = [
  "CASH",
  "GAZEPAY",
];