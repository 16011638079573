import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";
import utils from "src/helpers/Utils";
import { AreaSchemaType, CitySchemaType } from "src/schemas/addressSchema";

class AddressService {
  private readonly cityUrl = "cities";
  private readonly areaUrl = "areas";
  async createCity(city: CitySchemaType) {
    const data: { [key: string]: any } = {};
    Object.keys(city).forEach(key => {
      if (!utils.checkIsNullOrUndefined(city[key])) data[key] = city[key];
    });

    return await apiPost({
      apiPath: this.cityUrl,
      data,
    });
  }

  async updateCity({ id, ...city }: CitySchemaType & { id: string }) {
    const data: { [key: string]: any } = {};
    Object.keys(city).forEach(key => {
      if (!utils.checkIsNullOrUndefined(city[key])) data[key] = city[key];
    });

    return await apiPatch({
      apiPath: `${this.cityUrl}/${id}`,
      data,
    });
  }

  async deleteCity(id: string) {
    return await apiDelete({ apiPath: `${this.cityUrl}/${id}` });
  }

  async createArea(area: AreaSchemaType) {
    const data: { [key: string]: any } = {};
    Object.keys(area).forEach(key => {
      if (!utils.checkIsNullOrUndefined(area[key])) data[key] = area[key];
    });

    return await apiPost({
      apiPath: `${this.areaUrl}`,
      data,
    });
  }

  async updateArea({
    id,
    ...area
  }: AreaSchemaType & {
    id: string;
  }) {
    const data: { [key: string]: any } = {};
    Object.keys(area).forEach(key => {
      if (!utils.checkIsNullOrUndefined(area[key])) data[key] = area[key];
    });

    return await apiPatch({
      apiPath: `${this.areaUrl}/${id}`,
      data,
    });
  }

  async deleteArea(id: string) {
    return await apiDelete({ apiPath: `${this.areaUrl}/${id}` });
  }
}
export const addressService = new AddressService();
