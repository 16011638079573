import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, TextField } from '@mui/material';
import classNames from 'classnames';
import { FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react'
import AlertNotification from 'src/generic-ui/AlertNotification';
import Spinner from 'src/generic-ui/Spinner';
import { useArea } from 'src/hooks/address/useArea';
import { useCity } from 'src/hooks/address/useCity';
import { logger } from 'src/services';
import Area from './Area';
import { orderService } from 'src/services/order.service';
import { notifyError, notifySuccess } from 'src/generic-ui';

const PAGE_SIZE = 40;
interface IUpdateAddressInfo {
    shippingAddress: any;
    orderId: string;
    status: {
        paymentStatus: string;
        orderStatus: string;
    }
}

export default function UpdateAddressInfo({ shippingAddress, orderId, status }: IUpdateAddressInfo) {
    const query: {
        limit: number;
        page: number
    } = {
        limit: PAGE_SIZE,
        page: 1,
    };
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const { isLoading: isLoadingCity, cities, mutate: mutateCity } = useCity(query);
    const { isLoading: isLoadingArea, areas, mutate: mutateArea } = useArea(query);

    const formik = useFormik({
        initialValues: {
            shippingAddress: {
                id: shippingAddress?.id || "",
                customerName: shippingAddress?.customerName || "",
                customerEmail: shippingAddress?.customerEmail || "",
                phoneNumber: shippingAddress?.phoneNumber || "",
                fullAddress: shippingAddress?.fullAddress || "",
                city: shippingAddress?.cityId || "",
                area: shippingAddress?.areaId || "",
            }
        },
        async onSubmit(values) {
            setSubmitting(true);
            try {
                await orderService.updateOrder(orderId, values);
                notifySuccess("address updated successfully");
            }
            catch (error) {
                logger.error(error);
                notifyError(error.mesage);
            }
            finally {
                setSubmitting(false);
            }
        },
    });

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors: formikError,
        touched,
        setValues,
    } = formik;


    if (isLoadingArea || isLoadingCity) return <Spinner />;

    const modifiedArea = areas.filter((department) => department.isActive === true).map((activeDepartment) => {
        return {
            label: activeDepartment.title,
            value: activeDepartment.id
        }
    });


    const defaultAreaOption = modifiedArea.find(
        (option) => values.shippingAddress.area === option.value
    )

    const modifiedCity = cities.filter((department) => department.isActive === true).map((activeDepartment) => {
        return {
            label: activeDepartment.title,
            value: activeDepartment.id
        }
    });

    const defaultCityOption = modifiedCity.find(
        (option) => values.shippingAddress.city === option.value
    );

    return (
        <>
            {status.paymentStatus === "PAID" || status.orderStatus === "DELIVERED" ? <div className='p-5 font-semibold'>You can't update address</div> : <FormikProvider value={formik}>
                <form onSubmit={handleSubmit}>
                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                        <div>
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <div className="">
                                <TextField
                                    className="w-full"
                                    type="text"
                                    name="shippingAddress.customerName"
                                    onChange={handleChange}
                                    value={values.shippingAddress.customerName}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Phone
                            </label>
                            <div className="">
                                <TextField
                                    className="w-full"
                                    type="text"
                                    name="shippingAddress.phoneNumber"
                                    onChange={handleChange}
                                    value={values.shippingAddress.phoneNumber}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                City
                            </label>
                            <div className="">
                                <Autocomplete
                                    id="combo-box-demo"
                                    fullWidth
                                    className=""
                                    value={defaultCityOption}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    options={modifiedCity}
                                    renderInput={(params) => <TextField {...params} />}
                                    onChange={(event: any, newValue: Record<string, any>) => {
                                        handleChange('shippingAddress.city')(newValue.value);
                                    }}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                Area
                            </label>
                            <div className="">
                                <Area cityId={values.shippingAddress.city} oldCityId={shippingAddress?.cityId} />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <TextField
                                type="email"
                                name="shippingAddress.customerEmail"
                                onChange={handleChange}
                                className="w-full"
                                value={values.shippingAddress.customerEmail}
                            />
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                Full Address
                            </label>
                            <div className="">
                                <TextField
                                    rows={5}
                                    name="shippingAddress.fullAddress"
                                    multiline
                                    onChange={handleChange}
                                    className="w-full"
                                    value={values.shippingAddress.fullAddress}
                                >
                                </TextField>
                            </div>
                        </div>
                    </div>

                    <LoadingButton
                        loadingIndicator="Loading..."
                        type="submit"
                        loading={isSubmitting}
                        color="primary"
                        className={classNames(
                            "bg-indigo-600 hover:bg-indigo-800 mt-2 text-white",
                        )}
                    >
                        Submit
                    </LoadingButton>
                </form>
            </FormikProvider>}
        </>
    )
}
