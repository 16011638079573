import { FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { ResetPasswordSchema } from 'src/schemas/userSchema';
import { logger, userService } from 'src/services';
import { notifyError, notifySuccess } from 'src/generic-ui';

interface IResetPasswordConfig {
    setOpen: Dispatch<SetStateAction<boolean>>
}

interface IResetPassword {
    userId: string;
    config: IResetPasswordConfig;
}

export default function ResetPassword({ userId, config }: IResetPassword) {
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const initialValues = {
        password: "",
        confirmPassword: ""
    }

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors: formikError,
        touched,
        setValues,
        setFieldValue
    } = useFormik({
        initialValues: initialValues,
        validationSchema: ResetPasswordSchema,
        enableReinitialize: true,
        async onSubmit(values) {
            const obj = { password: values.password }
            try {
                setSubmitting(true);
                await userService.updateUser(userId, { ...obj });
                notifySuccess("Password reset successfully")
                config.setOpen(false)
            } catch (error) {
                logger.error(error);
                notifyError(error.message)
            } finally {
                setSubmitting(false);
            }
        },
    });


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="New password"
                        />
                        {formikError.password && touched.password ? (
                            <p className="text-red-600">{formikError.password}</p>
                        ) : null}
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirm password"
                        />
                        {formikError.confirmPassword && touched.confirmPassword ? (
                            <p className="text-red-600">{formikError.confirmPassword}</p>
                        ) : null}
                    </FormControl>
                </Grid>
                <FormControl fullWidth>
                    <Grid item xs={12}>
                        <LoadingButton
                            loadingIndicator="Loading..."
                            type="submit"
                            loading={isSubmitting}
                            color="primary"
                            className={"bg-indigo-600 hover:bg-indigo-800 mt-2 text-white ml-2"}
                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                </FormControl>
            </Grid>
        </form>
    )
}
