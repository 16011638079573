import { QueryParamsType } from "src/types/query-params";
import { useSwrRequest } from "../useSwrRequest";
import qs from "qs";
import { VariantOption } from "src/types";
import useRequest from "../useRequest";


export const useVariantOptions = ({
  page = 1,
  limit = 10,
  searchTerm,
  variant
}: {
  page: number;
  limit: number;
  searchTerm?: string;
  variant?: string;

}) => {
  let query = {
    page: page,
    limit,
    searchTerm
  };
  if (!!searchTerm) query["searchTerm"] = searchTerm;
  if (!!variant) query["variant"] = variant;

  const url = `/variant-options?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);
  return {
    variantOptions: (data as Array<VariantOption>) || [],
    mutate,
    isLoading,
    error,
    ...rest,
  };
};
