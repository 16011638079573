import SideBarModal from "src/components/modals/SideBarModal";
import { IModals } from "src/types";
import _, { orderBy } from 'lodash'
import { useOrderDetails } from "src/hooks/orders/useOrderDetails";
import Spinner from "src/generic-ui/Spinner";
import { Box, Button } from "@mui/material";
import CustomTab from "src/generic-ui/Tab";
import ComingSoon from "src/components/ComingSoon";
import OrderAmountInfo from "src/components/order/details/OrderAmountInfo";
import OrderBasicInfo from "src/components/order/details/tabs/OrderBasicInfo";
import OrderItems from "src/components/order/details/order-items/OrderItems";
import UpdateAddressInfo from "src/components/order/details/tabs/update-address/UpdateAddressInfo";
import { useEffect, useRef, useState } from "react";
import ChangeOrderStatus from "src/components/order/edit/ChangeOrderStatus";
import ChangePaymentStatus from "src/components/order/edit/ChangePaymentStatus";
import { logger } from "src/services";
import { FormikProvider, useFormik } from "formik";
import { parsedOrderItems } from "src/transformer/order";
import OrderLifeCycle from "src/components/order/details/order-items/OrderLifeCycle";
import NewlyAddedItems from "src/components/order/details/order-items/NewlyAddedItems";
import OrderInvoiceDetails from "src/components/order/invoice";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import withPermission from "src/components/permission/withPermission";
import { permissions } from "src/constants/permissions";
import ActionBar from "src/generic-ui/ActionBar";
import PaymentLogs from "src/components/order/details/tabs/PaymentLogs";
import UpdateCoupon from "src/components/order/details/tabs/UpdateCoupon";

function OrderDetails() {
    const { code } = useParams()
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const { data, isLoading, mutate } = useOrderDetails(code);
    const invoiceRef = useRef(null)
    const formik = useFormik({
        initialValues: {
            products: [],
            newOrderItems: [],
        },
        onSubmit: async (values, helper) => {
            logger.log('submit values', values);
        }
    })
    const modifiedOrderItem = data?.orderItems.map((item) => parsedOrderItems(item));

    useEffect(() => {
        formik.setFieldValue('products', data?.orderItems)
    }, [data])

    const handlePrintInvoice = useReactToPrint({
        content: () => invoiceRef.current,
        documentTitle: "Invoice print",
    })

    if (isLoading) return <Spinner />;

    const tabItems = [
        {
            index: 0,
            tabTitle: "Order Info",
            tabContent: <OrderBasicInfo orderDetails={data} />
        },
        {
            index: 1,
            tabTitle: "Update Address Info",
            tabContent: <UpdateAddressInfo shippingAddress={data?.address} orderId={data?.id} status={{
                paymentStatus: data?.paymentStatus,
                orderStatus: data?.orderStatus,
            }} />
        },
        {
            index: 2,
            tabTitle: "Payment logs",
            tabContent: <PaymentLogs orderId={data?.id} />
        },
        {
            index: 3,
            tabTitle: "Update Coupon",
            tabContent: <UpdateCoupon orderId={data?.id} mutate={mutate} status={{
                paymentStatus: data?.paymentStatus,
                orderStatus: data?.orderStatus,
            }} />
        }
    ];

    const initialLifeCycles = [
        {
            status: "PENDING",
            show: true,
            state: "upcoming",
        },
        {
            status: "CONFIRMED",
            show: true,
            state: "upcoming",
        },
        {
            status: "PROCESSING",
            show: true,
            state: "upcoming",
        },
        {
            status: "OUT_FOR_DELIVERY",
            show: true,
            state: "upcoming",

        },
        {
            status: "DELIVERED",
            show: true,
            state: "upcoming",
        },
        {
            status: "CANCELLED",
            show: false,
        }
    ]

    const onToggleModal = () => setModalVisible(prev => !prev);

    const onCloseModal = () => {
        onToggleModal();
    };

    return (
        <>
            <ActionBar title={`Order details (#${code})`} />
            <div className="my-5">
                <div className="grid grid-cols-3 gap-2">
                    <div className="col-span-2">
                        <CustomTab items={tabItems} />
                    </div>
                    <div className="col-span-1">
                        <div className="mt-12">
                            <OrderAmountInfo orderDetails={data} />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="flex py-3">
                <div className="w-60">
                    <ChangeOrderStatus mutate={mutate} id={data?.id} currentStatus={data?.orderStatus} />
                </div>

                <div className="ml-2 w-60">
                    <ChangePaymentStatus mutate={mutate} orderId={data?.id} currentStatus={data?.paymentStatus} />
                </div>
                <div className="ml-auto">
                    <Button
                        className="bg-indigo-500 hover:bg-indigo-700 text-white ml-2"
                        onClick={e => {
                            onToggleModal();
                        }}
                    >Preview Invoice</Button>
                </div>
            </div>
            <FormikProvider value={formik}>
                <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 grid-cols-2 gap-4">
                    <div className="col-span-2">
                        {data?.paymentStatus === "PAID" ? null :
                            <>
                                <div className="mt-5">
                                    <NewlyAddedItems existingOrderItems={data.orderItems} orderStatus={data?.orderStatus} orderId={data?.id} mutate={mutate} />
                                </div>
                            </>
                        }
                        <OrderItems orderId={data?.id} items={data?.orderItems} mutate={mutate} orderStatus={data?.orderStatus} paymentStatus={data?.paymentStatus} />
                    </div>
                    <div className="col-span-1">
                        <div className="mb-5">
                            <p className="mt-2 mb-5 text-base font-semibold ml-4 2xl:ml-0 xl:ml-0 xl:lh-0">Order Status History</p>
                            <OrderLifeCycle initialLifeCycles={initialLifeCycles} timelines={data?.orderLifeCycles} currentStatus={data?.orderStatus} />
                        </div>
                    </div>
                </div>
            </FormikProvider>
            {isModalVisible && (
                <SideBarModal
                    isVisible={isModalVisible}
                    onClose={onCloseModal}
                    left={40}
                    allowBackdrop={false}
                    title=""
                >
                    <Box p="0.5rem">
                        <div className="flex">
                            <Button onClick={handlePrintInvoice} className="bg-indigo-500 hover:bg-indigo-700 text-white ml-auto">
                                Print invoice
                            </Button>
                        </div>
                        <OrderInvoiceDetails code={data?.code} invoiceRef={invoiceRef} />
                    </Box>
                </SideBarModal>
            )}
        </>

    );
}

export default withPermission(OrderDetails, permissions['order'].permissionSet.order.details);