import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const theme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#000',
                    color: "#000",
                    height: 3,
                },
            },
        },
    },
    colors: {
        gradients: {
            blue1: '',
            blue2: '',
            blue3: '',
            blue4: '',
            blue5: '',
            orange1: '',
            orange2: '',
            orange3: '',
            purple1: '',
            purple3: '',
            pink1: '',
            pink2: '',
            green1: '',
            green2: '',
            black1: '',
            black2: ''
        },
        shadows: {
            success: '',
            error: '',
            primary: '',
            warning: '',
            info: ''
        },
        alpha: {
            white: {
                5: '',
                10: '',
                30: '',
                50: '',
                70: '',
                100: ''
            },
            trueWhite: {
                5: '',
                10: '',
                30: '',
                50: '',
                70: '',
                100: ''
            },
            black: {
                5: '',
                10: '',
                30: '',
                50: '',
                70: '',
                100: ''
            }
        },
        secondary: {
            lighter: '',
            light: '',
            main: '',
            dark: ''
        },
        primary: {
            lighter: '',
            light: '',
            main: '',
            dark: ''
        },
        success: {
            lighter: '',
            light: '',
            main: '',
            dark: ''
        },
        warning: {
            lighter: '',
            light: '',
            main: '',
            dark: ''
        },
        error: {
            lighter: '',
            light: '',
            main: '',
            dark: ''
        },
        info: {
            lighter: '',
            light: '',
            main: '',
            dark: ''
        }
    },
    general: {
        reactFrameworkColor: undefined,
        borderRadiusSm: '',
        borderRadius: '',
        borderRadiusLg: '',
        borderRadiusXl: ''
    },
    sidebar: {
        background: undefined,
        boxShadow: undefined,
        width: '',
        textColor: undefined,
        dividerBg: undefined,
        menuItemColor: undefined,
        menuItemColorActive: undefined,
        menuItemBg: undefined,
        menuItemBgActive: undefined,
        menuItemIconColor: undefined,
        menuItemIconColorActive: undefined,
        menuItemHeadingColor: undefined
    },
    header: {
        height: '',
        background: undefined,
        boxShadow: undefined,
        textColor: undefined
    }
});

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className='py-3 px-1.5'>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface IItem {
    index: number;
    tabTitle: string;
    tabContent: React.ReactNode;
}

interface ICustomTab {
    items: IItem[];
}

function CustomTab({ items }: ICustomTab) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <ThemeProvider theme={theme}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{
                            style: {
                                borderRadius: "0px",
                                background: "transparent",
                                borderBottom: "2px solid #6770eb"
                            }
                        }}
                    >

                        {items.map((item: IItem) => (
                            <Tab label={item.tabTitle} {...a11yProps(item.index)} />
                        ))}
                    </Tabs>
                </Box>
            </ThemeProvider>

            {items.map((item: IItem) => {
                const TabContent = item.tabContent;
                return (
                    <TabPanel value={value} index={item.index}>
                        {TabContent}
                    </TabPanel>
                )
            })}
        </Box>
    );
}

export default CustomTab;