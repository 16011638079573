import { QueryParamsType } from "src/types/query-params";
import { useSwrRequest } from "../useSwrRequest";
import qs from "qs";
import useRequest from "../useRequest";
import { ICity } from "src/types";

export const useCity = (query: Record<string, QueryParamsType>) => {
  const url = `/cities?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);
  return {
    cities: (data as Array<ICity>) || [],
    mutate,
    isLoading,
    error,
    ...rest,
  };
};
