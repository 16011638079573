import { useEffect, useMemo, useState } from "react";
import {
  ACTIVE_DROPDOWN,
  DEBOUNCE_DELAY,
  DEFAULT_PAGE_SIZE,
  FEATURED_DROPDOWN,
  NEW_ARRIVAL_DROPDOWN,
  STOCK_DROPDOWN,
} from "src/constants/globalConstants";
import { useProductLists } from "src/hooks/products/useProductLists";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { useDebounce } from "src/hooks/useDebounce";
export const useProductFilter = () => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [selectedBrandId, setBrandId] = useState<string>("");
  const [selectedCategoryId, setCategoryId] = useState<string>("");
  const [selectedDepartMentId, setDepartMentId] = useState<string>("");
  const [selectedFeatureStatus, setFeatureStatus] = useState<string>(
    FEATURED_DROPDOWN[0].value,
  );
  const [selectedNewArrivalStatus, setNewArrivalStatus] = useState<string>(
    NEW_ARRIVAL_DROPDOWN[0].value,
  );

  const [selectedStockOutStatus, setStockOutStatus] = useState<string>(
    STOCK_DROPDOWN[0].value,
  );

  const [selectedActiveStatus, setActiveStatus] = useState<string>(
    ACTIVE_DROPDOWN[0].value,
  );
  const [selectedSubCategoryId, setSubCategoryId] = useState<string>("");
  const debouncedSearchTerm = useDebounce({
    searchQuery: searchTerm,
    delay: DEBOUNCE_DELAY,
  });
  const isComponentMounted = useComponentDidMount();

  const {
    brands,
    isLoading: isProductLoading,
    products,
    categories,
    subCategories,
    departments,
    meta,
    error,
    ...rest
  } = useProductLists({
    page: page,
    limit: pageSize,
    searchTerm: debouncedSearchTerm,
    category: selectedCategoryId,
    brand: selectedBrandId,
    subCategory: selectedSubCategoryId,
    department: selectedDepartMentId,
    activeStatus: selectedActiveStatus,
    featuredStatus: selectedFeatureStatus,
    newArrivalStatus: selectedNewArrivalStatus,
    stockOutStatus: selectedStockOutStatus,
  });

  useEffect(() => {
    isComponentMounted && page !== 1 && setPage(1);
  }, [
    selectedCategoryId,
    selectedBrandId,
    selectedDepartMentId,
    selectedSubCategoryId,
    debouncedSearchTerm,
    selectedActiveStatus,
    selectedFeatureStatus,
    selectedStockOutStatus,
    selectedNewArrivalStatus,
  ]);

  const isLoading = useMemo(() => {
    return isProductLoading;
  }, [isProductLoading]);

  const total = useMemo(() => {
    return meta?.total || 0;
  }, [meta]);

  const showResetAllButton = useMemo(() => {
    return (
      !!selectedCategoryId ||
      !!selectedBrandId ||
      !!selectedDepartMentId ||
      !!selectedSubCategoryId ||
      !!debouncedSearchTerm ||
      selectedActiveStatus !== "all" ||
      selectedFeatureStatus !== "all" ||
      selectedStockOutStatus !== "all" ||
      selectedNewArrivalStatus !== "all"
    );
  }, [
    selectedCategoryId,
    selectedBrandId,
    selectedDepartMentId,
    selectedSubCategoryId,
    debouncedSearchTerm,
    selectedStockOutStatus,
    selectedNewArrivalStatus,
  ]);

  const onReset = () => {
    setCategoryId("");
    setDepartMentId("");
    setSubCategoryId("");
    setSearchTerm("");
    setBrandId("");
    setNewArrivalStatus(NEW_ARRIVAL_DROPDOWN[0].value);
    setStockOutStatus(STOCK_DROPDOWN[0].value);
    setActiveStatus(ACTIVE_DROPDOWN[0].value);
    setFeatureStatus(FEATURED_DROPDOWN[0].value);
  };
  return {
    brands,
    isLoading,
    products,
    categories,
    subCategories,
    departments,
    meta,
    error,
    showResetAllButton,
    onReset,
    total,
    setPageSize,
    selectedBrandId,
    setBrandId,
    setDepartMentId,
    selectedDepartMentId,
    selectedCategoryId,
    setCategoryId,
    selectedSubCategoryId,
    setSubCategoryId,

    selectedActiveStatus,
    setActiveStatus,

    selectedNewArrivalStatus,
    setNewArrivalStatus,
    selectedStockOutStatus,
    setStockOutStatus,
    setFeatureStatus,
    selectedFeatureStatus,
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    pageSize,
    ...rest,
  };
};
