import { ORDER_DETAILS } from "src/constants/apis";
import { swrConfig } from "src/helpers/configs/swrConfig";
import { IOrderItem } from "src/types";
import useRequest from "../useRequest";

export const useOrderDetails = (code: string) => {
  const url = ORDER_DETAILS.replace(":code", code);
  const { data, ...rest } = useRequest(url, url);
  return {
    data: data as IOrderItem,
    ...rest,
  };
};
