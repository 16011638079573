import axiosInstance from "src/helpers/axios/axiosInstance";
import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";
import { IRoleRequestPayload } from "src/types/acl";

class Role {
  private readonly ROLE_URL = "roles";
  async createRole(data: IRoleRequestPayload) {
    return await apiPost({
      apiPath: this.ROLE_URL,
      data,
    });
  }

  async updateRole(id: string, data: IRoleRequestPayload) {
    const url = `${this.ROLE_URL}/${id}`;
    return await apiPatch({
      apiPath: url,
      data,
    });
  }

  async deleteRole(id: string) {
    const url = `${this.ROLE_URL}/${id}`;
    return await apiDelete({
      apiPath: url,
    });
  }

  async addPermissions(id: string, data: { permissions: string[] }) {
    const url = `${this.ROLE_URL}/${id}/add-permissions`;
    return await apiPost({
      apiPath: url,
      data,
    });
  }

  async removePermissions(id: string, data: { permissions: string[] }) {
    const url = `${this.ROLE_URL}/${id}/remove-permissions`;
    try {
      const response = await axiosInstance().delete(url, { data });
      return Promise.resolve(response.data)
    }
    catch(error) {
      return Promise.reject(error)
    }
  }
}

export const roleService = new Role();
