import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { RemoveCircle } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

interface IPopover {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: (value: boolean) => void;
  children: React.ReactNode;
  title?: string;
  width?: number;
  height?:number;
}

export default function Popover({ open, setOpen, onClose, children, title, width, height }: IPopover) {
  const handleClose = () => setOpen(false);
  style["width"] = width;
  style["height"] = height;

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="rounded-md">
          <div className="flex">
            <p className="mt-1 font-bold uppercase">{title}</p>
            <IconButton aria-label="delete" size="small" className='ml-auto' onClick={handleClose}>
              <HighlightOffIcon fontSize="small" />
            </IconButton>
          </div>
          {children}
          <div className="mt-8" />
        </Box>
      </Modal>
    </div>
  );
}