export const getEnvironmentName = (): string => {
	return process.env.REACT_APP_ENVIRONMENT_TYPE || 'development';
};

export const getBaseUrl = (): string => {
	return process.env.REACT_APP_API_BASE_URL || '';
};

export const getSiteLogoUrl = (): string => {
	return process.env.REACT_APP_SITE_LOGO || '';
}

export const getBrandId = (): string => {
	return process.env.REACT_APP_BRAND_ID || '';
}


export const ENV = {
	brandId: process.env.REACT_APP_BRAND_ID
}