import {
    Box,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    Popover,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { notifyError, notifySuccess } from "src/generic-ui";
import CustomDropDown from "src/generic-ui/CustomDropDown";
import CustomPagination from "src/generic-ui/CustomPagination/CustomPagination";
import { usePermissionFilter } from "src/hooks/acl/permission/usePermissionFilter";
import { logger } from "src/services";
import { roleService } from "src/services/acl";
import { IPermission, IPermissionType } from "src/types/acl";

interface ISelectPermission {
    roleId?: string;
    onClick?: (product: any) => void;
    selectedPermissions?: string[];
    config?: {
        mutateAvailablePermissions: any;
    };
}

export default function SearchPermissions({
    roleId,
    onClick,
    selectedPermissions,
    config
}: ISelectPermission) {
    const {
        isLoading,
        permissions,
        meta,
        error,
        permissionTypes,
        selectedPermissionType,
        setPermissionType,
        showResetAllButton,
        onReset,
        total,
        setPageSize,
        searchTerm,
        setSearchTerm,
        page,
        setPage,
        pageSize,
        mutate,
    } = usePermissionFilter();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
        null,
    );

    const [operation, setOperation] = useState<string>("")
    const { handleBlur, handleChange, values, handleSubmit, submitForm, setFieldValue } =
        useFormik({
            initialValues: {
                permissions: []
            },
            onSubmit: async (values, helper) => {
                if (operation === "assign") {
                    onAddPermission({ values, helper, roleId })
                }
                else if (operation === "unassign") {
                    onRemovePermission({ values, helper, roleId })
                }
            },
        });

    const onRemovePermission = async ({ values, helper, roleId }) => {
        try {
            const response = await roleService.removePermissions(roleId, values);
            logger.log(response);
            config.mutateAvailablePermissions();
            notifySuccess("Permission removed successfully");
            helper.resetForm();
        } catch (error) {
            logger.error(error);
            notifyError(error.message);
        }
    }

    const onAddPermission = async ({ values, helper, roleId }) => {
        logger.log("submit form: ", values)
        try {
            const response = await roleService.addPermissions(roleId, values);
            logger.log(response);
            config.mutateAvailablePermissions();
            notifySuccess("Permission added successfully");
            helper.resetForm();
        } catch (error) {
            logger.error(error);
            notifyError(error.message);
        }
    }


    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOk = () => {
        setAnchorEl(null);
        submitForm();
    }

    const handleSearchChange = e => {
        setSearchTerm(e.target.value);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const modifiedPermissions = permissions.filter((permission: any) => permission.title !== '*');

    const modifiedPermissionType = permissionTypes.filter((permissionType: any) => permissionType.title !== '*');

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
                sx={{
                    background: "white",
                    position: "sticky",
                    top: 0,
                    bottom: 20,
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                    zIndex: 5,
                }}
            >
                <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                    <Grid item xs={12}>
                        <CustomDropDown
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            label="Permission type"
                            margin="dense"
                            placeholder="Select permission type"
                            onChange={value => setPermissionType(value)}
                            value={selectedPermissionType}
                            name="permissionType"
                        >
                            {modifiedPermissionType?.map((permissionType: IPermissionType, index: number) => {
                                return (
                                    <MenuItem value={permissionType.id} key={index}>
                                        {permissionType.title}
                                    </MenuItem>
                                );
                            })}
                        </CustomDropDown>
                    </Grid>
                </Grid>

                <TextField
                    fullWidth
                    label="Permissions"
                    name="searchTerm"
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                {!isLoading && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "1rem",
                        }}
                    >
                        {showResetAllButton ? (
                            <Button
                                className="bg-red-400 hover:bg-red-800 text-white"
                                onClick={e => onReset()}
                            >
                                Reset All
                            </Button>
                        ) : (
                            <div></div>
                        )}

                        <CustomPagination
                            onPageChange={(nexPageNumber: number) => {
                                setPage(nexPageNumber);
                            }}
                            totalCount={total}
                            pageSize={pageSize}
                            currentPage={page}
                        />
                    </Box>
                )}
            </Box>

            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : !modifiedPermissions.length ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    No Permissions Found
                </Box>
            ) : (
                <Box className="overscroll-auto " height={"auto"}>
                    {modifiedPermissions.map((permission: IPermission, key: number) => {

                        const isSelected = !!selectedPermissions?.filter(s => s === permission.id)
                            .length;

                        return (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                                className="py-3 border rounded-md mb-3 px-2"
                                key={key}
                            >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Box>
                                        <Typography className="pl-1 font-bold">{permission?.title}</Typography>
                                    </Box>
                                </Box>
                                {!isSelected ? (
                                    <Button
                                        onClick={e => {
                                            !isSelected && onClick(permission);
                                            handleClick(e)
                                            setFieldValue('permissions', [permission?.id]);
                                            setOperation("assign")
                                        }}
                                        className="bg-indigo-600 hover:bg-indigo-700 text-white ml-2 h-8"
                                    >
                                        Assign
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={e => {
                                            !isSelected && onClick(permission);
                                            handleClick(e)
                                            setFieldValue('permissions', [permission?.id]);
                                            setOperation("unassign")
                                        }}
                                        className="bg-red-600 hover:bg-red-700 text-white ml-2 h-8"
                                    >
                                        Unassign
                                    </Button>
                                )}
                            </Box>
                        );
                    })}
                </Box>
            )}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Typography sx={{ paddingY: "1rem", paddingX: "1rem" }}>
                    {operation === "assign" ? <>Do you want to add the permission for this role?</> : <>Do you want to remove the permission for this role?</>}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleOk}>Yes</Button>
                </Box>
            </Popover>

        </Box>
    );
}
