import * as Yup from "yup";

export const CitySchema = Yup.object({
  title: Yup.string().required("Title is required"),
  isActive: Yup.boolean().optional(),
});

export const AreaSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  city: Yup.string().required("City is required"),
  isActive: Yup.boolean().optional(),
});

export const WarehouseSchema = Yup.object({
  name: Yup.string().required("Title is required"),
  emails: Yup.string().required("emails are required"),
  city: Yup.string().required("City is required"),
  isActive: Yup.boolean().optional(),
});

export type AreaSchemaType = Yup.InferType<typeof AreaSchema>;
export type CitySchemaType = Yup.InferType<typeof CitySchema>;
export type WarehouseSchemaType = Yup.InferType<typeof WarehouseSchema>;
