import React from 'react'
import { ImagesEntity } from 'src/types'
interface IProductImages {
    images: ImagesEntity[]
}
interface IImageBox {
    content: ImagesEntity;
}
function ImageBox({ content }: IImageBox) {
    return (
        <div className='relative'>
            <img src={content?.link} alt="" className='h-50 w-40 rounded-md' />
            <p className='bg-yellow-500 w-20 text-center capitalize font-semibold absolute top-0 rounded-tl'>{content.isThumb ? "thumbnail": null}</p>
        </div>
    )
}
export default function ProductImages({ images }: IProductImages) {
    return (
        <div className='grid grid-cols-3 my-5 2xl:grid-cols-6 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-4 '>
            {images.map((image: ImagesEntity, index: number) => {
                return (<div key={index} className=''>
                    <ImageBox content={image} />
                </div>)
            })}
        </div>
    )
}
