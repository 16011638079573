import React from "react";
import Spinner from "src/generic-ui/Spinner";
import { useOrderCountByPaymentStatus } from "src/hooks/dashboard/useOrderCountByPaymentStatus";
import Chart from "react-apexcharts";
import { QueryParamsType } from "src/types";
import dateUtils from "src/helpers/utility/DateUtils";
import chartOptions from "src/helpers/utility/chartOption";

export default function PaymentStatusCount({ dates }) {
  const query: Record<string, QueryParamsType> = {};
  dates?.validForm ? (query["startDate"] = dates?.validForm) : null;
  dates?.validTill ? (query["endDate"] = dates?.validTill) : null;
  const { data, isLoading } = useOrderCountByPaymentStatus(query);

  if (isLoading) return <Spinner />;
  const series: any = Object.values(data);
  const labels: any = Object.keys(data);

  const chartData = {
    options: {
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
      },
      labels,
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "65%",
          },
        },
      },
    },
    series,
  };

  return (
    <div className="border shadow-lg p-5 rounded-lg h-65">
      <p className="text-base font-semibold">Total order (payment status)</p>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="donut"
      />
    </div>
  );
}
