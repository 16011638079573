import { Autocomplete, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import Spinner from 'src/generic-ui/Spinner';
import { useArea } from 'src/hooks/address/useArea';
const PAGE_SIZE = 10;

interface IArea {
    cityId: string;
    oldCityId?: string;
}
export default function Area({ cityId, oldCityId }: IArea) {
    const { values, handleChange, handleBlur } = useFormikContext<any>();

    const queryArea: {
        limit: number;
        page: number;
        city?: string;
    } = {
        limit: PAGE_SIZE,
        page: 1,
        city: cityId
    };
    const { isLoading: isLoadingArea, areas, mutate: mutateArea } = useArea({ ...queryArea });
    const modifiedArea = areas.filter((area) => area.isActive === true).map((activeArea) => {
        return {
            label: activeArea.title,
            value: activeArea.id
        }
    });

    const defaultAreaOption = modifiedArea.find(
        (option) => values.shippingAddress.area === option.value
    );

    const [selectedArea, setSelectedArea] = useState<Record<string, any>>(defaultAreaOption);

    if(isLoadingArea) return null;

    return (
        <>
            <Autocomplete
                id="combo-box-demo"
                fullWidth
                className=""
                defaultValue={cityId !== oldCityId ? "" : defaultAreaOption}
                value={selectedArea}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                options={modifiedArea}
                renderInput={(params) => <TextField {...params} />}
                onChange={(event: any, newValue: Record<string, any>) => {
                    setSelectedArea(newValue)
                    handleChange('shippingAddress.area')(newValue.value);
                }}
                onBlur={handleBlur}
            />
        </>
    )
}
