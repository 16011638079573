import styled from "@emotion/styled";
import {
  alpha,
  Box,
  Button,
  List,
  ListItem,
  ListSubheader,
  IconButton,
} from "@mui/material";
import React, { Fragment, useMemo, useState } from "react";
import {
  Link,
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTheme } from "@mui/material";
import { logger } from "src/services";

const SubMenuWrapper = styled(Box)(
  ({ theme }: any) => `
      .MuiList-root {
  
        .MuiListItem-root {
          padding: 1px 0;
  
          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(3.2)};
  
            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(10)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
      
          .MuiButton-root {
            display: flex;
            color: ${theme.colors.alpha.trueWhite[70]};
            background-color: transparent;
            width: 100%;
            justify-content: flex-start;
            padding: ${theme.spacing(1.2, 3)};
  
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              transition: ${theme.transitions.create(["color"])};
  
              .MuiSvgIcon-root {
                font-size: inherit;
                transition: none;
              }
            }
  
            .MuiButton-startIcon {
              color: ${theme.colors.alpha.trueWhite[30]};
              font-size: ${theme.typography.pxToRem(20)};
              margin-right: ${theme.spacing(1)};
            }
            
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[50]};
              margin-left: auto;
              opacity: .8;
              font-size: ${theme.typography.pxToRem(20)};
            }
  
            &.active,
            &:hover {
              background-color: ${alpha(
                theme.colors.alpha.trueWhite[100],
                0.06
              )};
              color: ${theme.colors.alpha.trueWhite[100]};
  
              .MuiButton-startIcon,
              .MuiButton-endIcon {
                color: ${theme.colors.alpha.trueWhite[100]};
              }
            }
          }
  
          &.Mui-children {
            flex-direction: column;
  
            .MuiBadge-root {
              position: absolute;
              right: ${theme.spacing(7)};
            }
          }
  
          .MuiCollapse-root {
            width: 100%;
  
            .MuiList-root {
              padding: ${theme.spacing(1, 0)};
            }
  
            .MuiListItem-root {
              padding: 1px 0;
  
              .MuiButton-root {
                padding: ${theme.spacing(0.8, 3)};
  
                .MuiBadge-root {
                  right: ${theme.spacing(3.2)};
                }
  
                &:before {
                  content: ' ';
                  background: ${theme.colors.alpha.trueWhite[100]};
                  opacity: 0;
                  transition: ${theme.transitions.create([
                    "transform",
                    "opacity",
                  ])};
                  width: 6px;
                  height: 6px;
                  transform: scale(0);
                  transform-origin: center;
                  border-radius: 20px;
                  margin-right: ${theme.spacing(1.8)};
                }
  
                &.active,
                &:hover {
  
                  &:before {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
  `
);

export default function SideNavItems({
  navTitle,
  path,
  closeSidebar,
  icon,
  children,
}: any) {
  const Icon = icon;
  const groupPath = path;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const [isExpanded, setExpand] = useState<boolean>(false);

  const onToggle = () => setExpand((prev) => !prev);

  const IconComponent = useMemo(() => {
    return !isExpanded ? ExpandMoreIcon : ExpandLessIcon;
  }, [isExpanded]);

  return (
    <List
      component="div"
      subheader={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "&:hover": {
              color: theme.colors.alpha.trueWhite[100],
              backgroundColor: alpha(theme.colors.alpha.trueWhite[100], 0.06),
              borderRadius: "1rem",
            },
          }}
          onClick={() => onToggle()}
        >
          <ListSubheader component="div" disableSticky>
            {navTitle}
          </ListSubheader>
          {!!children?.length && (
            <IconButton>
              <IconComponent sx={{ color: "gray" }} />
            </IconButton>
          )}
        </Box>
      }
    >
      {isExpanded && children?.length > 0 ? (
        <SubMenuWrapper>
          <List component="div">
            {children?.map((subItem, index) => {
              {
              }
              const finalPath = `/${groupPath}/${subItem.path}`;
              const Icon = subItem.icon;
              const selectedStyle =
                pathname !== finalPath
                  ? {}
                  : {
                      color: theme.colors.alpha.trueWhite[100],
                      backgroundColor: alpha(
                        theme.colors.alpha.trueWhite[100],
                        0.06
                      ),
                      borderRadius: "1rem",
                    };
              if (subItem.showNavItem) {
                return (
                  <Fragment key={index}>
                    <ListItem
                      component="div"
                      sx={{
                        ...selectedStyle,
                      }}
                    >
                      <Button
                        onClick={() => {
                          navigate(finalPath);
                        }}
                        startIcon={Icon}
                      >
                        {subItem.navTitle}
                      </Button>
                    </ListItem>
                  </Fragment>
                );
              }
            })}
          </List>
        </SubMenuWrapper>
      ) : null}
    </List>
  );
}
