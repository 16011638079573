import qs from "qs";
import { useMemo } from "react";
import { PRODUCT_LISTS } from "src/constants/apis";
import utils from "src/helpers/Utils";
import { logger } from "src/services";
import { ICampaign, IPrice, IProduct } from "src/types";
import { QueryParamsType } from "src/types/query-params";
import { useBrands } from "../catalogs/useBrands";
import { useCategories } from "../catalogs/useCategories";
import { useDepartments } from "../catalogs/useDepartments";
import { useSubCategories } from "../catalogs/useSubCategories";
import useRequest from "../useRequest";
const DEFAULT_QUERY: Record<string, QueryParamsType> = {
  limit: 100,
  page: 1,
};

export const useProductLists = ({
  page = 1,
  limit = 20,
  searchTerm,
  loadData = true,
  brand,
  department,
  category,
  subCategory,
  featuredStatus,
  activeStatus,
  newArrivalStatus,
  stockOutStatus,
}: {
  page: number;
  limit: number;
  searchTerm?: string;
  loadData?: boolean;
  brand?: string;
  department?: string;
  category?: string;
  subCategory?: string;
  activeStatus?: string;
  featuredStatus?: string;
  newArrivalStatus?: string;
  stockOutStatus?: string;
}) => {
  let query = {
    page,
    limit,
  };

  const brandQuery = {
    ...DEFAULT_QUERY,
  };
  const departmentQuery = {
    ...DEFAULT_QUERY,
  };

  const categoryQuery = {
    ...DEFAULT_QUERY,
  };
  const subCategoryQuery = {
    ...DEFAULT_QUERY,
  };
  const { brands: brandsResponse, ...rest2 } = useBrands(brandQuery);
  const { departments: departmentResponse, ...rest3 } =
    useDepartments(departmentQuery);

  if (!!department) categoryQuery["department"] = department;
  const { categories: categoriesResponse, ...rest4 } =
    useCategories(categoryQuery);

  if (!!category) subCategoryQuery["category"] = category;
  const { subCategories: subCategoriesResponse, ...rest5 } =
    useSubCategories(subCategoryQuery);

  if (!!searchTerm) query["searchTerm"] = searchTerm;
  if (!!brand) query["brand"] = brand;
  if (!!category) query["category"] = category;
  if (!!department) query["department"] = department;
  if (!!subCategory) query["subCategory"] = subCategory;
  if (!!activeStatus && activeStatus !== "all")
    query["isActive"] = activeStatus === "true" ? true : false;

  if (!!featuredStatus && featuredStatus !== "all")
    query["isFeatured"] = featuredStatus === "true" ? true : false;

  if (!!newArrivalStatus && newArrivalStatus !== "all")
    query["isNewArrival"] = newArrivalStatus === "true" ? true : false;

  if (!!stockOutStatus && stockOutStatus !== "all")
    query["isForceStockout"] = stockOutStatus === "true" ? true : false;
  const url = `${PRODUCT_LISTS}?${qs.stringify(query)}`;

  const {
    data: productsResponse,
    mutate,
    error,
    meta,
    ...rest
  } = useRequest(loadData ? url : null, url, {
    revalidateOnFocus: true,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
    refreshWhenOffline: true,
    refreshWhenHidden: true,
  });

  const departments = useMemo(() => {
    return departmentResponse;
  }, [departmentResponse]);

  const products = useMemo(() => {
    return (productsResponse as IProduct[]) || [];
  }, [productsResponse]);

  const categories = useMemo(() => {
    return categoriesResponse;
  }, [categoriesResponse]);

  const subCategories = useMemo(() => {
    return subCategoriesResponse;
  }, [subCategoriesResponse]);

  const brands = useMemo(() => {
    return brandsResponse;
  }, [brandsResponse]);

  return {
    products,
    mutate,
    isLoading:
      rest.isLoading ||
      rest2.isLoading ||
      rest3.isLoading ||
      rest4.isLoading ||
      rest5.isLoading,
    error,
    brands,
    departments,
    categories,
    subCategories,
    meta,
    ...rest,
  };
};
