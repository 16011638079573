import { QueryParamsType } from "src/types/query-params";
import qs from "qs";
import useRequest from "src/hooks/useRequest";
import { IPermission } from "src/types/acl";
import { useMemo } from "react";
import { usePermissionType } from "../permission-type/usePermissionType";

export const usePermission = ({
  limit = 10,
  page = 1,
  searchTerm = "",
  permissionType

}: {
  limit: number;
  page: number;
  searchTerm?: string;
  permissionType?: string
}) => {

  let query = {
    page,
    limit
  }
  if (!!searchTerm) query["searchTerm"] = searchTerm;
  if (!!permissionType) query["permissionType"] = permissionType;

  const url = `/permissions?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);

  return {
    permissions: (data as Array<IPermission>) || [],
    mutate,
    isLoading: isLoading,
    error: error,
    ...rest,
  };
};
