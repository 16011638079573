import React from 'react'
import CustomTab from 'src/generic-ui/Tab';
import BasicInfo from './BasicInfo';
import ResetPassword from './ResetPassword';

export default function UpdateTabComponent({ id , config }) {
    const { onClose, onRefresh, setOpen } = config;
    const tabItems = [
        {
            index: 0,
            tabTitle: "Update basic info",
            tabContent: <BasicInfo selectedId={id} config={{ onClose, onRefresh }} />
        },
        {
            index: 1,
            tabTitle: "Reset password",
            tabContent: <ResetPassword userId={id} config={{ setOpen }}/>
        },
    ];
    return (
        <>
            <CustomTab items={tabItems} />
        </>
    )
}
