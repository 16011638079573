import axios from "axios";
import { FILES } from "src/constants/apis";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { apiPost } from "src/helpers/axios/config";
import { getBaseUrl } from "src/helpers/configs/envConfig";
import { authService } from "./auth.service";

class FileService {
  async uploadProductImages(body: any): Promise<any> {
    const { accessToken } = authService.getUserInfo();
    try {
      const response = await axios({
        method: "POST",
        url: getBaseUrl() + "/" +FILES,
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "multipart/form-data",
        },
        data: body,
      });

      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const fileService = new FileService();
