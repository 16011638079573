import React from 'react'

interface IDescAndSpec {
    description: string;
    specification: string;
}
export default function DescAndSpec({ description, specification }: IDescAndSpec) {
    return (
        <>
            <div>
                <p className='text-sm font-semibold'>Description</p>
                <div
                    className="prose prose-sm text-gray-500"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </div>
            <div className='mt-2'>
                <p className='text-sm font-semibold'>Specification</p>
                <div
                    className="prose prose-sm text-gray-500"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </div>
        </>
    )
}
