import * as Yup from "yup";

export const CatalogSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  icon: Yup.string().optional(),
  image: Yup.string().optional(),
  description: Yup.string().optional(),
  isFeatured: Yup.boolean().optional(),
  parentId: Yup.string().optional(),
  isActive: Yup.boolean().optional(),
});

export type CatalogSchemaType = Yup.InferType<typeof CatalogSchema>;
