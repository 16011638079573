import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, IconButton, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import SideBarModal from "src/components/modals/SideBarModal";
import SuspenseLoader from "src/components/SuspenseLoader";
import { notifyError, notifySuccess } from "src/generic-ui";
import { useSubscriptionDetails } from "src/hooks/subscription/useSubscriptionDetails";
import { SubscriptionSchema, SubscriptionSchemaType } from "src/schemas";
import { logger, subscriptionService } from "src/services";
import { IModals } from "src/types";

interface ISidebar extends IModals {
  selectedId?: string;
  onRefresh?: () => void;
}
export default function Sidebar({
  isVisible,
  onClose,
  selectedId,
  onRefresh,
}: ISidebar) {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const { isLoading, data, mutate, error } = useSubscriptionDetails(selectedId);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors: formikError,
    touched,
  } = useFormik({
    initialValues: {
      email: data?.email || "",
    },
    validationSchema: SubscriptionSchema,
    enableReinitialize: true,
    onSubmit(values) {
      const onSave = !!selectedId ? onEditSubmit : onSaveSubmit;
      onSave(values);
    },
  });

  if (isLoading) {
    return <SuspenseLoader />;
  }

  const onSaveSubmit = async (values: SubscriptionSchemaType) => {
    try {
      setSubmitting(true);
      await subscriptionService.createSubscription({ ...values });
      notifySuccess("subscription created successfully")
      onRefresh?.();
      onClose();
    } catch (error) {
      logger.error(error);
      notifyError(error.message)
    } finally {
      setSubmitting(false);
    }
  };

  const onEditSubmit = async (values: SubscriptionSchemaType) => {
    try {
      setSubmitting(true);
      await subscriptionService.updateSubscription({ ...values, id: selectedId });
      notifySuccess("subscription updated successfully");
      onRefresh?.();
      onClose();
    } catch (error) {
      logger.error(error);
      notifyError(error.message)
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async () => {
    try {
      setSubmitting(true);
      await subscriptionService.deleteSubscription(selectedId);
      notifySuccess("subscription deleted successfully");
      onRefresh?.();
      onClose();
    } catch (error) {
      logger.error(error);
      notifyError(error.message)
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <SideBarModal
        isVisible={isVisible}
        onClose={onClose}
        title={!selectedId ? "Add New" : "Edit Item"}
        renderEndIcon={
          !!selectedId && (
            <IconButton onClick={onDelete} disabled={isSubmitting}>
              <DeleteIcon className="text-red-400" />
            </IconButton>
          )
        }
      >
        <Box paddingX={2}>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <form onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email"
                name="email"
                type="text"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {formikError.email && touched.email ? (
                <p className="text-red-600">{formikError.email}</p>
              ) : null}

              <LoadingButton
                loadingIndicator="Loading..."
                type="submit"
                loading={isSubmitting}
                color="primary"
                className={"bg-indigo-600 hover:bg-indigo-800 mt-2 text-white"}
              >
                Submit
              </LoadingButton>
            </form>
          </Grid>
        </Box>
      </SideBarModal>
    </>
  );
}
