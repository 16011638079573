export const DEBOUNCE_DELAY: number = 600;
export const TOAST_NOTIFICATION_DELAY: number = 5000;
export const DOTS = -1;
export const DEFAULT_PAGE_SIZE = 10;

export const FEATURED_DROPDOWN = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Featured",
    value: "true",
  },

  {
    label: "Not Featured",
    value: "false",
  },
];
export const ACTIVE_DROPDOWN = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "true",
  },

  {
    label: "Not Active",
    value: "false",
  },
];

export const NEW_ARRIVAL_DROPDOWN = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "New",
    value: "true",
  },

  {
    label: "Not New",
    value: "false",
  },
];

export const STOCK_DROPDOWN = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Out of Stock",
    value: "true",
  },

  {
    label: "In Stock",
    value: "false",
  },
];
