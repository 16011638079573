export default function Catalogs({ productDetails }) {
    return (
        <div>
            <div className='flex my-4'>
                <span className="font-semibold ">Brand name</span>
                <span className="ml-3">{productDetails?.brand?.title}</span>
            </div>
            <div className='flex my-4'>
                <span className="font-semibold">Department name</span>
                <span className="ml-3">{productDetails?.department?.title ? productDetails?.department?.title: "-"}</span>
            </div>
            <div className='flex my-4'>
                <span className="font-semibold">Category name</span>
                <span className="ml-3">{productDetails?.category?.title ? productDetails?.category?.title: "-"}</span>
            </div>
            <div className='flex my-4'>
                <span className="font-semibold">Category name</span>
                <span className="ml-3">{productDetails?.subCategory?.title ? productDetails?.subCategory?.title: "-"}</span>
            </div>
        </div>
    )
}
