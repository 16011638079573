import { ISendEmailRequestBody, ISendSmsRequestBody } from "src/types";

export const parsedSendEmailRequestPayload = (metadata: ISendEmailRequestBody) => {
  const { sendToAll, emails, message, subject } = metadata;  
  const payload = {
    sendToAll,
    message,
    subject,
  };
  if(!sendToAll) payload['emails'] = emails;
  return payload;
};

export const parsedSendSmsRequestPayload = (metadata: ISendSmsRequestBody) => {
    const { sendToAll, phoneNumbers, message } = metadata;
    const payload = {
      sendToAll,
      message,
    };
    if(!sendToAll) payload['phoneNumbers'] = phoneNumbers;
    return payload;
  };
