import { Autocomplete, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useBrands } from "src/hooks/catalogs/useBrands";
import { productService } from "src/services/product.service";
import { QueryParamsType } from "src/types/query-params";

export default function Brands() {
  const productPersistedFormData = productService.getPesistedProductFormData();

  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<any>();
  const query: Record<string, QueryParamsType> = {
    limit: 40,
    page: 1,
  };
  const { brands, isLoading, mutate } = useBrands(query);
  const [selectedBrands, setSelectedBrands] = useState<Record<string, string>>(
    values.brand ? values.brand : {},
  );

  useEffect(() => {
    mutate();
  }, [mutate]);

  if (isLoading) return null;

  const modifiedBrands = brands
    .filter(brand => brand.isActive === true)
    .map(activeBrands => {
      return {
        label: activeBrands.title,
        value: activeBrands.id,
      };
    });

  const defaultOption = modifiedBrands.find(
    option => option.value === selectedBrands,
  );

  return (
    <>
      <Autocomplete
        defaultValue={defaultOption}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        id="combo-box-demo"
        fullWidth
        className="mt-4"
        options={modifiedBrands}
        renderInput={params => {
          return <TextField {...params} label="select brands" />;
        }}
        onChange={(event: any, newValue: Record<string, any>) => {
          handleChange("brand")(newValue.value);
          setSelectedBrands(newValue);
        }}
        onBlur={handleBlur}
      />
      {errors.brand && touched.brand ? (
        <p className="text-red-600">{errors.brand}</p>
      ) : null}
    </>
  );
}
