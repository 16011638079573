import { useEffect, useMemo, useState } from "react";
import {
  DEBOUNCE_DELAY

} from "src/constants/globalConstants";
import { useComponentDidMount } from "src/hooks/useComponentDidMount";
import { useDebounce } from "src/hooks/useDebounce";
import { usePermissionLists } from "./usePermissionLists";

export const usePermissionFilter = () => {
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedPermissionType, setPermissionType] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const query = {
    page: page,
    limit: pageSize,
    permissionType: selectedPermissionType
  }
  const debouncedSearchTerm = useDebounce({
    searchQuery: searchTerm,
    delay: DEBOUNCE_DELAY,
  });
  const isComponentMounted = useComponentDidMount();

  const {
    isLoading: isPermissionLoading,
    mutatePermissionList,
    meta,
    error,
    permissionTypes,
    ...rest
  } = usePermissionLists({
    page,
    limit: pageSize,
    searchTerm: debouncedSearchTerm,
    permissionType: selectedPermissionType
  });

  useEffect(() => {
    isComponentMounted && page !== 1 && setPage(1);
  }, [debouncedSearchTerm]);

  const isLoading = useMemo(() => {
    return isPermissionLoading;
  }, [isPermissionLoading]);

  const mutate = useMemo(() => {
    return mutatePermissionList;
  }, [mutatePermissionList]);

  const total = useMemo(() => {
    return meta?.total || 0;
  }, [meta]);

  const showResetAllButton = useMemo(() => {
    return !!selectedPermissionType || !!debouncedSearchTerm;
  }, [selectedPermissionType, debouncedSearchTerm]);

  const onReset = () => {
    setSearchTerm("");
    setPermissionType("");
  };
  return {
    isLoading,
    meta,
    error,
    showResetAllButton,
    permissionTypes,
    selectedPermissionType,
    setPermissionType,
    onReset,
    total,
    setPageSize,
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    pageSize,
    ...rest,
    mutate,
  };
};
