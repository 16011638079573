import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  DEBOUNCE_DELAY,
  DEFAULT_PAGE_SIZE,
} from "src/constants/globalConstants";
import { DataTable } from "src/generic-ui/DataTable";
import Switch from "src/generic-ui/Switch";
import { usePermission } from "src/hooks/acl/permission/usePermission";
import { useDebounce } from "src/hooks/useDebounce";
import { logger } from "src/services";
import { permissionService } from "src/services/acl";
import { IArea } from "src/types";
import Sidebar from "./Sidebar";
import withPermission from "src/components/permission/withPermission";
import { permissions } from "src/constants/permissions";
import ActionBar from "src/generic-ui/ActionBar";
import FilterListIcon from "@mui/icons-material/FilterList";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FilterSidebar from "./FilterSidebar";

function Permission() {
  const [isSidebarVisible, setVisible] = useState<boolean>(false);
  const [isFilterModalVisible, setFilterModal] = useState<boolean>(false);
  const [permissionType, setPermissionType] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [selectedId, setSelected] = useState<string | null>(null);
  const debouncedSearchTerm = useDebounce({
    searchQuery: searchTerm,
    delay: DEBOUNCE_DELAY,
  });

  const query: {
    limit: number;
    page: number;
    searchTerm?: string;
    permissionType?: string;
  } = {
    limit: pageSize,
    page: page + 1,
    searchTerm: debouncedSearchTerm,
    permissionType,
  };

  const { isLoading, permissions, mutate, error, meta } = usePermission(query);
  const onToggleSideBar = () => setVisible((prev) => !prev);

  const [rowCountState, setRowCountState] = useState(meta?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.total !== undefined ? meta?.total : prevRowCountState
    );
  }, [meta, setRowCountState]);

  const onClose = () => {
    onToggleSideBar();
    setSelected(null);
  };

  const handleOnCellClick = ({ row }: { row: IArea }) => {
    setSelected(row.id);
    onToggleSideBar();
  };

  const onRefresh = () => {
    mutate();
  };

  const updateElement = async ({
    keyName,
    id,
    value,
  }: {
    keyName: string;
    id: string;
    value: any;
  }) => {
    try {
      await permissionService.updatePermission(id, { [keyName]: value });
    } catch (error) {
      logger.error(error);
    } finally {
      onRefresh();
    }
  };

  const columns: any = [
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      renderCell: ({ row }: { row: IArea }) => (
        <Switch
          isChecked={!!row.isActive}
          showPopOver={true}
          onClick={(isChecked: boolean) =>
            updateElement({ keyName: "isActive", value: isChecked, id: row.id })
          }
        />
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: IArea }) => (
        <IconButton
          onClick={() => {
            handleOnCellClick({ row });
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  if (error) {
    return <div>Something Went Wrong</div>;
  }

  const modifiedPermissions = permissions.filter(
    (permission: any) => permission.title !== "*"
  );

  const onToggleFilter = () => setFilterModal((prev) => !prev);
  const onCloseFilterModal = () => {
    onToggleFilter();
  };

  const onReset = () => {
    setPermissionType("");
    setSearchTerm("");
  };

  const showResetAllButton = useMemo(() => {
    return !!debouncedSearchTerm || !!permissionType;
  }, [debouncedSearchTerm, permissionType]);

  const filterConfigs = {
    permissionType,
    setPermissionType,
    onReset,
    showResetAllButton,
  };

  return (
    <>
      <Helmet>
        <title>AAAF LTD - Permission</title>
      </Helmet>
      <ActionBar title="Permissions">
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <input
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className=" block  rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
          />
          <Tooltip arrow title="create">
            <IconButton
              className="bg-indigo-500 hover:bg-indigo-600 text-white ml-2 p-1.5"
              onClick={() => onToggleSideBar()}
            >
              <AddCircleIcon fontSize="medium" className="text-white" />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="filter options">
            <IconButton
              className="bg-indigo-500 hover:bg-indigo-600 text-white ml-2 p-1.5"
              onClick={onToggleFilter}
            >
              {showResetAllButton && (
                <div
                  style={{
                    position: "absolute",
                    top: "-0.2rem",
                    right: "-0.2rem",
                    borderRadius: "50%",
                    background: "red",
                    width: "0.7rem",
                    height: "0.7rem",
                  }}
                />
              )}
              <FilterListIcon fontSize="medium" className="text-white" />
            </IconButton>
          </Tooltip>

          {showResetAllButton && (
            <Tooltip arrow title="reset filter">
              <IconButton
                className="bg-red-500 hover:bg-red-600 text-white ml-2 p-1.5"
                onClick={() => {
                  onReset();
                }}
              >
                <RestartAltIcon fontSize="medium" className="text-white" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </ActionBar>
      <DataTable
        columns={columns}
        data={modifiedPermissions}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        disableColumnMenu={true}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCountState={rowCountState}
      />
      {isSidebarVisible && (
        <Sidebar
          isVisible={isSidebarVisible}
          onClose={onClose}
          selectedId={selectedId}
          onRefresh={onRefresh}
        />
      )}
      {isFilterModalVisible && (
        <FilterSidebar
          {...filterConfigs}
          isVisible={true}
          onClose={onCloseFilterModal}
        />
      )}
    </>
  );
}

export default withPermission(
  Permission,
  permissions["access-control"].permissionSet.admin
);
