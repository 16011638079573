import { Autocomplete, TextField } from '@mui/material'
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useDepartments } from 'src/hooks/catalogs/useDepartments';
import { QueryParamsType } from 'src/types/query-params';

export default function Department({ departmentConfig }: any) {
    const { values, handleChange, errors, touched, handleBlur } = useFormikContext<any>();
    const { department, setDepartment } = departmentConfig;
    const query: Record<string, QueryParamsType> = {
        limit: 40,
        page: 1
    }
    const [selectedDepartment, setSelectedDepartment] = useState<Record<string, string>>(values.department ? values.department : {})

    useEffect(() => {
        setSelectedDepartment(values.department)
        setDepartment(values.department)
    }, [values.department]);

    const { departments, isLoading, mutate } = useDepartments(query);
    useEffect(() => {
        mutate();
    }, [mutate]);

    if (isLoading) return null;

    const modifiedDepartments = departments.filter((department) => department.isActive === true).map((activeDepartment) => {
        return {
            label: activeDepartment.title,
            value: activeDepartment.id
        }
    });


    const defaultOption = modifiedDepartments.find(option => option.value === selectedDepartment);
    
    return (
        <>
            <Autocomplete
                id="combo-box-demo"
                fullWidth
                className="mt-4"
                defaultValue={defaultOption}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                options={modifiedDepartments}
                renderInput={(params) => <TextField {...params} label="select department" />}
                onChange={(event: any, newValue: Record<string, any>) => {
                    handleChange('department')(newValue.value);
                    setDepartment(newValue.value);
                }}
                onBlur={handleBlur}
            />
            {errors.department && touched.department ? (
                <p className="text-red-600">{errors.department}</p>
            ) : null}
        </>
    )
}
