import { QueryParamsType } from "src/types/query-params";
import qs from "qs";
import useRequest from "src/hooks/useRequest";
import { IPermissionType } from "src/types/acl";

export const useProductRating = (query: Record<string, QueryParamsType>) => {
  const url = `/product-ratings?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);
  return {
    productRatings: (data as any) || [],
    mutate,
    isLoading,
    error,
    ...rest,
  };
};
