import { Image } from "react-grid-gallery"

export const parsedFileObject = (request) => {
    const { link,fileName, id } = request
    return {
        key: id,
        src: link,
        caption: fileName,
        isSelected: false,
    }
}