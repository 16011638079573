import { Checkbox } from "@mui/material";
import { ChangeEvent, useMemo } from "react";
import { logger } from "src/services";
import { IProduct } from "src/types";

interface ICustomColCheckBox {
  product: IProduct;
  selectedProduct: IProduct[];
  onCheckProduct: (isChecked: boolean, product: IProduct) => void;
}

export function CustomColCheckBox({
  product,
  selectedProduct,
  onCheckProduct,
}: ICustomColCheckBox) {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onCheckProduct(e.target.checked, product);
  };
  const isChecked = useMemo(() => {
    return !!selectedProduct.filter(p => p.id === product.id).length;
  }, [product, selectedProduct]);
  return <Checkbox checked={isChecked} size="small" onChange={onChange} />;
}
