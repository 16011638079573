import classnames from "classnames";
import { FC } from "react";
import { DOTS } from "src/constants/globalConstants";
import { usePagination } from "src/hooks/usePagination";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import "./pagination.css";
interface ICustomPagination {
  offset?: number;
  pageSize: number;
  totalCount: number;
  currentPage: number;
  onPageChange: (nexPageNumber: number) => void;
}
const CustomPagination: FC<ICustomPagination> = ({
  currentPage,
  pageSize,
  offset,
  totalCount,
  onPageChange,
}) => {
  const maxPage = Math.ceil(totalCount / pageSize);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: 1,
    pageSize,
  });

  if (totalCount === 0) return null;
  const leftIconStyle =
    currentPage === 1
      ? {
          backgroundColor: "transparent",
        }
      : {};
  return (
    <div className="table-pagination ">
      <ul
        className={classnames("pagination-container", {
          ["pagination-bar"]: "pagination-bar",
        })}
      >
        {currentPage !== 1 && (
          <li
            className={"pagination-item"}
            onClick={(e: any) => onPageChange(currentPage - 1)}
          >
            <ChevronLeftIcon fontSize="small" />
          </li>
        )}
        {paginationRange?.map((pageNumber, index) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return (
              <li key={index} className="pagination-item dots">
                ...
              </li>
            );
          }

          // Render our Page Pills
          const style =
            pageNumber === currentPage
              ? { backgroundColor: "#5569ff", color: "white" }
              : {};
          return (
            <li
              key={index}
              className={"pagination-item"}
              onClick={() => onPageChange(pageNumber)}
              style={style}
            >
              {pageNumber}
            </li>
          );
        })}
        {/*  Right Navigation arrow */}
        {currentPage !== maxPage && (
          <li
            className={"pagination-item"}
            onClick={(e: any) => onPageChange(currentPage + 1)}
          >
            <ChevronRightIcon fontSize="small" />
          </li>
        )}
      </ul>
    </div>
  );
};

export default CustomPagination;
