import useRequest from "../useRequest";

export const useProductDetailsByCode = (code?: string | null) => {
  const url = `/products/code/${code}`;
  const { data, ...rest } = useRequest(!!code ? url : null, url);
  return {
    data: data as any,
    ...rest,
  };
};
