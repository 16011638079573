import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import SideBarModal from "src/components/modals/SideBarModal";
import SuspenseLoader from "src/components/SuspenseLoader";
import { notifyError, notifySuccess } from "src/generic-ui";
import { useRoleDetails } from "src/hooks/acl/role/useRoleDetails";
import { RoleSchema, RoleSchemaType } from "src/schemas/aclSchema";
import { logger } from "src/services";
import { roleService } from "src/services/acl";
import { IModals } from "src/types";

interface ISidebar extends IModals {
  selectedId?: string;
  onRefresh?: () => void;
}
export default function Sidebar({
  isVisible,
  onClose,
  selectedId,
  onRefresh,
}: ISidebar) {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const { isLoading, data } = useRoleDetails(selectedId);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors: formikError,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      title: data?.title || "",
      isActive: data?.isActive || false,
    },
    validationSchema: RoleSchema,
    enableReinitialize: true,
    onSubmit(values) {
      const onSave = !!selectedId ? onEditSubmit : onSaveSubmit;
      onSave(values);
    },
  });

  if (isLoading) {
    return <SuspenseLoader />;
  }

  const onSaveSubmit = async (values: RoleSchemaType) => {
    try {
      setSubmitting(true);
      await roleService.createRole({ ...values });
      notifySuccess("role created successfully");
      onRefresh?.();
      onClose();
    } catch (error) {
      logger.error(error);
      notifyError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const onEditSubmit = async (values: RoleSchemaType) => {
    try {
      setSubmitting(true);
      await roleService.updateRole(selectedId, { ...values });
      notifySuccess("role updated successfully")
      onRefresh?.();
      onClose();
    } catch (error) {
      logger.error(error);
      notifyError(error.message)
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async () => {
    try {
      setSubmitting(true);
      await roleService.deleteRole(selectedId);
      notifySuccess("role deleted successfully")
      onRefresh?.();
      onClose();
    } catch (error) {
      logger.error(error);
      notifyError(error.message)
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <SideBarModal
        isVisible={isVisible}
        onClose={onClose}
        title={!selectedId ? "Add New" : "Edit Item"}
        renderEndIcon={
          !!selectedId && (
            <IconButton onClick={onDelete} disabled={isSubmitting}>
              <DeleteIcon className="text-red-400" />
            </IconButton>
          )
        }
      >
        <Box paddingX={2}>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <form onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Title"
                name="title"
                type="text"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {formikError.title && touched.title ? (
                <p className="text-red-600">{formikError.title}</p>
              ) : null}

              <LoadingButton
                loadingIndicator="Loading..."
                type="submit"
                loading={isSubmitting}
                color="primary"
                className={"bg-indigo-600 hover:bg-indigo-800 mt-2 text-white"}
              >
                Submit
              </LoadingButton>
            </form>
          </Grid>
        </Box>
      </SideBarModal>
    </>
  );
}
