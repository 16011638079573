import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { Fragment, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';

import { useNotification } from 'src/hooks/orders/useNotification';
import { INotification, QueryParamsType } from 'src/types';
import NotificationItem from './NotificationItem';
import { Link } from 'react-router-dom';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false)

  const query: Record<string, QueryParamsType> = {
    limit: 5,
    page: 1,
  };

  const { notifications, isLoading } = useNotification(query);
  if (isLoading) return null;

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
          // badgeContent={1}
          // anchorOrigin={{
          //   vertical: 'top',
          //   horizontal: 'right'
          // }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Notifications</Typography>
          <Link to="/others/notifications" onClick={() => handleClose()} className="ml-auto">
            <Typography variant="h5" className='underline'>view all</Typography>
          </Link>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          {notifications?.length > 0 ? notifications.map((notification: INotification, index: number) => {
            return (
              <Fragment key={index}>
                <NotificationItem item={notification} config={{ handleClose }} />
              </Fragment>
            )
          }): <>
            <div className='flex justify-center w-56'>
                <p className='text-center py-5'>no notifcation found</p>
            </div>
          </>}
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
