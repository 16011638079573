import { Button, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  DEBOUNCE_DELAY,
  DEFAULT_PAGE_SIZE,
} from "src/constants/globalConstants";
import { DataTable } from "src/generic-ui/DataTable";
import { useDebounce } from "src/hooks/useDebounce";
import { INotification } from "src/types";
import { QueryParamsType } from "src/types/query-params";
import ActionBar from "src/generic-ui/ActionBar";
import { useNotification } from "src/hooks/orders/useNotification";
import dateUtils from "src/helpers/utility/DateUtils";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet-async";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

function Notifications() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const debouncedSearchTerm = useDebounce({
    searchQuery: searchTerm,
    delay: DEBOUNCE_DELAY,
  });
  const query: Record<string, QueryParamsType> = {
    limit: pageSize,
    page: page + 1,
  };
  if (!!debouncedSearchTerm) query["searchTerm"] = debouncedSearchTerm;

  const { notifications, isLoading, mutate, error, meta } =
    useNotification(query);

  const [rowCountState, setRowCountState] = useState(meta?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.total !== undefined ? meta?.total : prevRowCountState
    );
  }, [meta, setRowCountState]);

  const columns: any = [
    {
      field: "text",
      headerName: "Text",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
      renderCell: ({ row }: { row: INotification }) => (
        <>{dateUtils.formatDateTime(row?.createdAt, "MMM D, YYYY hh:mm a")}</>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <Button
          className="bg-indigo-500 hover:bg-indigo-600 text-white ml-2 p-1.5 px-3"
          onClick={() => {
            navigate(row.navigateTo);
          }}
        >
          view order
        </Button>
      ),
    },
  ];
  if (error) {
    return <div>Something Went Wrong</div>;
  }

  const showResetAllButton = useMemo(() => {
    return !!debouncedSearchTerm;
  }, [debouncedSearchTerm]);

  const onReset = () => {
    setSearchTerm("");
  };

  return (
    <>
      <Helmet>
        <title>AAAF LTD - notifications</title>
      </Helmet>
      <ActionBar title="Notifications">
        <div className="flex">
          <input
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className=" block rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
          {showResetAllButton && (
            <Tooltip arrow title="reset filter">
              <IconButton
                className="bg-red-500 hover:bg-red-600 text-white ml-2 p-1.5"
                onClick={() => {
                  onReset();
                }}
              >
                <RestartAltIcon fontSize="medium" className="text-white" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </ActionBar>
      <DataTable
        columns={columns}
        data={notifications}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        disableColumnMenu={true}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCountState={rowCountState}
      />
    </>
  );
}

export default Notifications;
