import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useAuth } from 'src/contexts/AuthContext';
import { notifyError, notifySuccess } from 'src/generic-ui';
import ActionBar from 'src/generic-ui/ActionBar';
import Spinner from 'src/generic-ui/Spinner';
import { useUserDetails } from 'src/hooks/user/useUserDetails';
import { UserSchema } from 'src/schemas/userSchema';
import { logger, userService } from 'src/services';

function AccountSettings() {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const { user } = useAuth();
  const { data, isLoading } = useUserDetails(user?.id);

  if (isLoading) return <Spinner />;

  const initialValues = {
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
  }

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors: formikError,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: UserSchema,
    enableReinitialize: true,
    async onSubmit(values) {
      try {
        setSubmitting(true);
        await userService.updateUser(data?.id, values);
        notifySuccess("User info updated successfully");
      } catch (error) {
        logger.error(error);
        notifyError(error.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <ActionBar title="Account settings" />
        <form onSubmit={handleSubmit} className='mt-3'>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                label="first name"
                name="firstName"
                type="text"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {formikError.firstName && touched.firstName ? (
                <p className="text-red-600">{formikError.firstName}</p>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                label="last name"
                name="lastName"
                type="text"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {formikError.lastName && touched.lastName ? (
                <p className="text-red-600">{formikError.lastName}</p>
              ) : null}
            </Grid>
          </Grid>

          <LoadingButton
            loadingIndicator="Loading..."
            type="submit"
            loading={isSubmitting}
            color="primary"
            className={"bg-indigo-600 hover:bg-indigo-800 mt-2 text-white"}
          >
            Submit
          </LoadingButton>
        </form>
    </>
  )
}

export default AccountSettings