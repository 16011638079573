import { Helmet } from "react-helmet-async";
import SuspenseLoader from "src/components/SuspenseLoader";
import { useOrderDetails } from "src/hooks/orders/useOrderDetails";
import Content from "./Content";

export default function OrderInvoiceDetails({
  code,
  invoiceRef,
}: {
  code: string;
  invoiceRef?: any;
}) {
  const { data, isLoading, error } = useOrderDetails(code);
  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (error) {
    return (
      <div>
        {" "}
        No Item Found with this order id. Please visit{" "}
        <a href={"/orders"} className="text-red-400">
          Order
        </a>
      </div>
    );
  }

  return (
    <form className="invoice-form">
      <Helmet>
        <title>AAAF LTD - invoice</title>
      </Helmet>
      <Content data={data} invoiceRef={invoiceRef} />
    </form>
  );
}
