import { Box, Checkbox, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel';
import React, { Dispatch, SetStateAction } from 'react'
import _without from "lodash/without";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface IOption {
    label: string;
    value: string;
}

interface IConfig {
    selectedOptions: IOption[];
    setSelectedOptions: Dispatch<SetStateAction<IOption[]>>
}

interface IMuiMultiSelectChipBox {
    title?: string;
    config: IConfig;
    data: any;
}


export default function MuiMultiSelectChipBox({ title, config, data }: IMuiMultiSelectChipBox) {
    const { selectedOptions, setSelectedOptions } = config;

    const handleOptionSelect = (event: SelectChangeEvent<typeof selectedOptions>) => {
        const { value }: any = event.target;
        setSelectedOptions(value);
    };

    const handleDelete = (e: React.MouseEvent, value: IOption) => {
        e.preventDefault();
        setSelectedOptions((current: IOption[]) => _without(current, value));
    };

    const unique = data.filter((item) => !selectedOptions?.some((el) => el.value === item.value));

    return (
        <FormControl className='w-96 mx-auto'>
            <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>
             <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={selectedOptions && selectedOptions?.length > 0 ? selectedOptions : []}
                onChange={handleOptionSelect}
                input={<OutlinedInput id="select-multiple-chip" label={title} />}
                renderValue={(selected) => {
                    return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value.value} label={value.label}
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    }
                                    onDelete={(e) => handleDelete(e, value)}
                                />
                            ))}
                        </Box>
                    )
                }}
                MenuProps={MenuProps}
            >
                {unique.length > 0 ? unique.map((el: any) => {
                    return (
                        <MenuItem key={el.id} value={el}>
                            {el.label}
                        </MenuItem>
                    )
                }): <div className='text-center'>no option</div>}
            </Select>
        </FormControl>
    )
}
