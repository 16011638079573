import { QueryParamsType } from "src/types/query-params";
import qs from "qs";
import useRequest from "src/hooks/useRequest";

export const useBanner = (query: Record<string, QueryParamsType>) => {
  const url = `/banners?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);

  return {
    banners: (data as any) || [],
    mutate,
    isLoading: isLoading,
    error: error,
    ...rest,
  };
};
