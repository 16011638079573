import { IArea } from "./area";
import { ICity } from "./city";
import { ICoupon } from "./coupon";
import { IProduct, Variant, VariantOption } from "./product";
import { IMeta } from "./response";

export interface IOrderCheckout {
  address?: string;
  cart?: string;
  paymentMethod: string;
  shippingAddress: ShippingAddress;
  products?: ProductsEntity[] | null;
  couponCode: string;
}
export interface ShippingAddress {
  id?: string;
  customerName: string;
  phoneNumber: string;
  fullAddress: string;
  customerEmail: string;
  city: string;
  area: string;
}
export interface ProductsEntity {
  product: string;
  variantOptions?: (string | undefined)[];
  quantity: number | undefined;
}

export interface IOrder {
  id: string;
  createdAt: string;
  updatedAt?: null;
  mrp: number;
  mrpVat: number;
  discount: number;
  couponDiscount: number;
  quantity: number;
  product: IProduct;
  orderItemVariants?: OrderItemVariantsEntity[];
  productId: string;
  orderId: string;
}

export interface OrderItemVariantsEntity {
  id: string;
  createdAt: string;
  updatedAt?: null;
  variant: Variant;
  variantOption: VariantOption;
  orderItemId: string;
  variantId: string;
  variantOptionId: string;
}

export interface IOrderCartSummary {
  cart?: string;
  couponCode?: string;
  products?: ProductsEntity[] | null;
}

export interface IOrderSummaryDetails {
  deliveryCharge: number;
  subTotal: number;
  vat: number;
  discount: number;
  couponDiscount: number;
  total: number;
}

/// Order for admin

export interface IOrderItem {
  id: string;
  isActive: boolean;
  createdAt: string;
  updatedAt?: any;
  code: string;
  orderStatus: string;
  paymentStatus: string;
  total: number;
  subTotal: number;
  discount: number;
  couponDiscount: number;
  vat: number;
  deliveryCharge: number;
  address: Address;
  user: User;
  orderItems: OrderItem[];
  coupon: ICoupon;
  addressId: string;
  couponId: string;
  userId: string;
  orderLifeCycles: IOrderLifeCycle[] | null | [];
  dueAmount: number;
  paidAmount: number
}

export interface Meta {
  total: number;
  page: number;
  limit: number;
  skip: number;
}

export interface Address {
  id: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt?: any;
  customerName: string;
  customerEmail: string;
  phoneNumber: string;
  fullAddress: string;
  city: ICity;
  area: IArea;
  cityId: string;
  areaId: string;
  userId: string;
}

export interface User {
  id: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt?: any;
  firstName: string;
  lastName: string;
  fullName?: any;
  avatar?: any;
  phoneNumber: string;
  username?: any;
  email: string;
}

export interface OrderItem {
  id: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt?: any;
  mrp: number;
  mrpVat: number;
  discount: number;
  couponDiscount: number;
  quantity: number;
  product: IProduct;
  productId: string;
  orderId: string;
}

export interface IOrderLifeCycle {
  id: string;
  isActive: boolean;
  createdAt: string;
  updatedAt?: null;
  orderStatus: string;
  comments?: null;
  orderId: string;
}


export const ORDER_STATUS = [
  "PENDING",
  "CONFIRMED",
  "PROCESSING",
  "OUT_FOR_DELIVERY",
  "DELIVERED",
  "CANCELLED",
  // "RETURNED",
  // "REFUNDED",
];

export enum ENUM_ORDER_PAYMENT_STATUS {
  PENDING = 'PENDING',
  PAID = 'PAID',
  FAILED = 'FAILED',
}

export interface IOrderUpdate {
  paymentStatus?: string;
  paymentMethod?: string;
  shippingAddress?: ShippingAddress;
  products?: (IOrderItem)[] | null;
}

export interface IOrderItem {
  product: string;
  productVariantOptions?: (string)[] | null;
  quantity?: number | null;
  isDeleted?: boolean | null;
}

export interface IOrderCartSummary {
	cart?: string;
	couponCode?: string;
	products?: ProductsEntity[] | null;
}

export interface INotification {
  id: string;
  isActive: boolean;
  createdAt: string;
  updatedAt?: null;
  text: string;
  navigateTo: string;
}
