import { Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { getPathMapToTitle } from "src/router";
import HomeIcon from '@mui/icons-material/Home';
export default function BreadCrumbs() {
  const { pathname } = useLocation();
  const pathNames = pathname.split("/").filter(x => x);

  const pathMapToTitle = useMemo(() => {
    return getPathMapToTitle();
  }, [getPathMapToTitle]);
  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      <Link to="/dashboard"><HomeIcon className="h-4 w-4 mb-0.5"/></Link>
      {pathNames.map((name, index: number) => {
        const routerTo = `/${pathNames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathNames.length - 1;
        return isLast || !pathMapToTitle?.[routerTo] ? (
          <Typography key={index}>
            {pathMapToTitle?.[routerTo] ||
              `${name[0].toUpperCase()}${name.slice(1)}`}
          </Typography>
        ) : (
          <Link key={index} to={routerTo}>
            {pathMapToTitle?.[routerTo]}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
}
