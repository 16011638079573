export default function GalleryEffect() {
    const demo = [];
    for (let i = 0; i < 12; i++) {
        demo.push(1)   
    }
    return (
        <>
            <div className="grid grid-cols-4 gap-1 mx-2">
                {demo.map((el, index) => {
                    return (<div key={index} className="animate-pulse flex space-x-4">
                        <div className=" bg-gray-300 h-[200px] w-[320px]" />
                    </div>)
                })}
            </div>
        </>
    )
}
