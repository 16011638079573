import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";
import { ICustomerRequestPayload, IUser, IUserRequestPayload } from "src/types";

class UserService {
  private readonly USER_URL = "users";
  async createUser(data: IUserRequestPayload) {
    return await apiPost({
      apiPath: this.USER_URL,
      data,
    });
  }

  async updateUser(id: string, data: IUserRequestPayload) {
    const url = `${this.USER_URL}/${id}`;
    return await apiPatch({
      apiPath: url,
      data,
    });
  }

  async deleteUser(id: string) {
    const url = `${this.USER_URL}/${id}`;
    return await apiDelete({
      apiPath: url,
    });
  }

  async createCustomer(data: ICustomerRequestPayload) {
    return await apiPost({
      apiPath: `${this.USER_URL}/customer`,
      data,
    });
  }

  
}

export const userService = new UserService();
