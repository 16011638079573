export interface ICouponCheckRequest {
  code: string;
}

export interface ICoupon {
  id: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt?: any;
  code: string;
  discountType: string;
  maxUsageLimit: number;
  perDayMaxUsageLimit: number;
  perUserMaxUsageLimit: number;
  perUserPerDayMaxUsageLimit: number;
  discount: number;
  discountPercentage: number;
  minOrderAmount: number;
  maxDiscountAmount: number;
  validFrom: string;
  validTill: string;
  usageCount: number;
}

export enum ENUM_COUPON_DISCOUNT_TYPE {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}

export const DISABLED_STATUSES = ["published", "canceled", "closed"];
export const ALLOWED_STATUSES_PUBLISHED = ["closed"];
export const ALLOWED_STATUSES_CANCELED = [];
export const ALLOWED_STATUSES_CLOSED = [];
export const ALLOWED_STATUSES_DRAFTED = ["published", "canceled"];
export const ALLOWED_STATUSES_CAMPAIGN = {
  drafted: ALLOWED_STATUSES_DRAFTED,
  published: ALLOWED_STATUSES_PUBLISHED,
  closed: ALLOWED_STATUSES_CLOSED,
  canceled: ALLOWED_STATUSES_CANCELED,
};
export const STATUS = [
  {
    value: "drafted",
    label: "Drafted",
  },
  {
    value: "closed",
    label: "Closed",
  },
  {
    value: "published",
    label: "Published",
  },
  {
    value: "canceled",
    label: "Cancel",
  },
];

export const STATUS_FOR_DRAFT = [
  {
    value: "drafted",
    label: "Drafted",
  },
  {
    value: "canceled",
    label: "Cancel",
  },
];

export const STATUS_FOR_PUBLISHED = [
  {
    value: "closed",
    label: "Close",
  },
];

export const STATUS_FOR_CANCELED = [
  {
    value: "closed",
    label: "Close",
  },
];

export const RIGHT_BUTTON_SHOW_STATUS = ["drafted", "published"];
export const RIGHT_BUTTON_NAME_FOR_STATUS = {
  drafted: "Publish",
  published: "Close",
};

export interface ICouponCheckRequest {
	code: string;
};