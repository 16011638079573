import { useContext, useEffect, useMemo, useState } from "react";
import { Gallery } from "react-grid-gallery";
import DeleteIcon from '@mui/icons-material/Delete';
import classNames from "classnames";
import { parsedFileObject } from "src/transformer/file";
import { useDispatch, useSelector } from "react-redux";
import { setGallaryImages } from "src/features";
import { IconButton } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileUpload from '@mui/icons-material/FileUpload';
import { fileService } from "src/services/file.service";
import AlertNotification from "src/generic-ui/AlertNotification";
import { GalleryContext } from "src/contexts/GalleryContext";

export default function ImageFiles({ files, imageFileConfig }: any) {
    const { isOpenFromModal, setOpen: setModalOpen, setFieldValue } = useContext(GalleryContext)
    const { folder, mutate } = imageFileConfig;
    const [open, setOpen] = useState<boolean>(false);
    const [imageBoxes, setImageBoxes] = useState([]);
    const dispatch = useDispatch();
    const isSingleSelected = false;

    const parsedFiles = useMemo(() => {
        return files?.map(file => {
            return parsedFileObject(file)
        })
    }, [files]);

    const selectedImages: any = useSelector<any>((state) => state.selectedGalleryImages);

    useEffect(() => {
        dispatch(setGallaryImages(parsedFiles));
    }, [parsedFiles])

    const hasSelected = selectedImages.some((image) => image.isSelected);

    const handleSelect = (index: number) => {
        const nextImages = selectedImages.map((image, i) =>
            i === index ? { ...image, isSelected: !image.isSelected } : image
        );
        dispatch(setGallaryImages(nextImages));
        const selectedItems = nextImages.filter((img) => img.isSelected === true).map((image, index) => {
            const obj = { id: (index + 1 - 1) + 1, link: image.src, isThumb: false }
            return obj;
        })
        if (setFieldValue) {
            setFieldValue('images', selectedItems)
        }

    };

    const handleSelectAllClick = () => {
        const nextImages = selectedImages.map((image) => ({
            ...image,
            isSelected: !hasSelected,
        }));
        dispatch(setGallaryImages(nextImages));
        const selectedItems = nextImages.filter((img) => img.isSelected === true).map((image, index) => {
            const obj = { id: (index + 1 - 1) + 1, link: image.src, isThumb: false }
            return obj;
        })
        if (setFieldValue) {
            setFieldValue('images', selectedItems)
        }
    };


    const uploadProductImageFiles = async () => {
        try {
            const data = new FormData();
            imageBoxes.forEach((imageBox, i) => {
                data.append(`files`, imageBox.binaryImageData, imageBox.binaryImageData.name);
            });

            data.append('folder', folder)

            const response = await fileService.uploadProductImages(data);
            const modifiedImageFiles = response.data.map((file, index) => {
                return {
                    link: file.link,
                    isThumb: imageBoxes[index].isThumb
                }
            });
            if (response.statusCode === 200) {
                setOpen(true);
                setImageBoxes([]);
                mutate()
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setImageBoxes((prev) => {
                return Array.from(e.target.files)
                    .map((obj: any, index) => {
                        const parsedObject: any = {}
                        parsedObject.link = URL.createObjectURL(e.target.files[index])
                        parsedObject.binaryImageData = e.target.files[index]
                        return parsedObject;
                    })
            })
        }
    };

    return (
        <div>
            <div className="p-t-1 p-b-1">
                <button onClick={handleSelectAllClick} className="bg-indigo-500 ml-1 text-white px-4 py-1 my-4 rounded-md hover:bg-indigo-700 ">
                    {hasSelected ? "Clear selection" : "Select all"}
                </button>

                <IconButton color="primary" aria-label="upload picture" component="label" className="ml-1 mb-0.5 bg-indigo-500 py-[5px] hover:bg-indigo-700 rounded-md">
                    <input hidden accept="image/*" type="file" onChange={imageChange} multiple />
                    <UploadFileIcon className="h-5 w-5 text-white" />
                    <p className="text-sm text-white">Open image explorer</p>
                </IconButton>

                {imageBoxes.length > 0 ? (
                    <button
                        className={classNames("ml-1 text-white px-4 py-1 my-4 rounded-md bg-green-500 hover:bg-green-600")}
                        onClick={uploadProductImageFiles}
                    >
                        <FileUpload className="h-5 w-5" />
                        upload image ({imageBoxes?.length > 0 ? imageBoxes?.length : 0})
                    </button>
                ) : null}

                {isOpenFromModal && hasSelected ? <button className="bg-indigo-500 ml-0.5 text-white px-4 py-1 my-4 rounded-md" onClick={() => {
                    setModalOpen(false)
                }}>
                    <DeleteIcon className="h-5 w-5" />
                    Attach selected image
                </button> : null}


                {hasSelected ? <button className="bg-red-500 ml-0.5 text-white px-4 py-1 my-4 rounded-md">
                    <DeleteIcon className="h-5 w-5" />
                    Delete
                </button> : null}
            </div>

            <div className="my-5">
                {imageBoxes?.length > 0 ? (
                    <>
                        <div className="flex w-[700px] overflow-x-auto">
                            {imageBoxes.map((imageBox, index) => {
                                return (<div className="h-20 w-20" key={index}>
                                    <img src={imageBox.link} alt="" />
                                </div>)
                            })}
                        </div>
                        <button className="bg-red-500 ml-0.5 text-white px-4 py-1 my-4 rounded-md" onClick={() => {setImageBoxes([])}}> clear all</button>
                    </>
                ) : null}
            </div>

            <AlertNotification message="file is uploaded successfully" open={open} setOpen={setOpen} />

            <div className="h-[550px] overflow-y-auto">
                <Gallery
                    images={selectedImages}
                    onSelect={handleSelect}
                    enableImageSelection={isSingleSelected && hasSelected ? false : true}
                    defaultContainerWidth={100}
                    rowHeight={150}
                />
            </div>
        </div>
    );
}