import React, { Dispatch, SetStateAction } from 'react'
import { IModals } from 'src/types';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import SideBarModal from 'src/components/modals/SideBarModal';
import { useVariants } from 'src/hooks/variants/useVariants';
import { useCity } from 'src/hooks/address/useCity';

interface IFilterSidebar extends IModals {
    isVisible: boolean;
    setCity: Dispatch<SetStateAction<string>>;
    city: string;
    showResetAllButton: boolean;
    onReset: () => void;
}

export default function FilterSidebar({
    isVisible,
    onClose,
    setCity,
    city,
    showResetAllButton,
    onReset
}: IFilterSidebar) {
    const { cities , isLoading: isLoadingCities, mutate: mutateCities} = useCity({
        limit: 100,
        page: 1
    })

    const modifiedCities = cities.filter((city) => city.isActive === true).map((city) => {
        return {
            label: city.title,
            value: city.id
        }
    });

    const defaultCity: any = city ? modifiedCities.find(modifiedCity => modifiedCity.value === city) : "";

    return (
        <>
            <SideBarModal
                isVisible={isVisible}
                onClose={onClose}
                left={70}
                allowBackdrop={false}
                title={"Filter Warehouse"}
            >
                <Box sx={{ display: "flex", flexDirection: "column", margin: "1rem" }}>
                    <Box
                        sx={{
                            background: "white",
                            position: "sticky",
                            top: 0,
                            bottom: 20,
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            zIndex: 5,
                        }}
                    >
                        <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    fullWidth
                                    className=""
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    options={modifiedCities}
                                    value={defaultCity}
                                    renderInput={(params) => <TextField label="select city" {...params} />}
                                    onChange={(event: any, newValue: any) => {
                                        setCity(newValue?.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "left",
                                        marginTop: "1rem",
                                    }}
                                >
                                    {showResetAllButton ? (
                                        <Button
                                            className="bg-red-400 hover:bg-red-800 text-white"
                                            onClick={e => onReset()}
                                        >
                                            Reset All
                                        </Button>
                                    ) : (
                                        <div></div>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </SideBarModal>
        </>
    )
}
