import { FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import { logger } from 'src/services';
import { IProduct } from 'src/types';
import OrderItemBasicCard from './OrderItemBasicCard';
import OrderItemCard from './OrderItemCard';

interface IOrderItems {
    orderId: string;
    items: any;
    mutate?: any;
    orderStatus: string;
    paymentStatus: string;
}

export default function OrderItems({ orderId, items, mutate, orderStatus, paymentStatus }: IOrderItems) {
    const formik = useFormik({
        initialValues: {
            products: [],
        },
        onSubmit: async (values, helper) => {
            logger.log('submit values', values);
        }
    });

    const { values, setFieldValue, submitForm } = formik;

    useEffect(() => {
        setFieldValue('products', items)
    }, [items])

    return (
        <>
            <div className="flex">
                <p className='m-4 text-base font-semibold'>Order item ({values.products.length})</p>
            </div>
            {
                orderStatus === "OUT_FOR_DELIVERY" || orderStatus === "DELIVERED" || orderStatus === "CANCELLED" || paymentStatus === "PAID" ?
                    <div className="mx-4">
                        {values.products.map((orderItem: IProduct) => {
                            return (
                                <OrderItemBasicCard orderItem={orderItem} />
                            );
                        })}
                    </div>
                    :
                    <>
                        <FormikProvider value={formik}>
                            <form action="">
                                <ul role="list" className="mx-4">
                                    {values.products.map((orderItem: IProduct) => {
                                        return (
                                            <OrderItemCard orderItem={orderItem} orderId={orderId} mutate={mutate} />
                                        );
                                    })}
                                </ul>
                            </form>
                        </FormikProvider>
                    </>
            }
        </>
    )
}
