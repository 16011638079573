import React, { useState } from "react";
import Brands from "./Brands";
import Categories from "./Categories";
import Department from "./Department";
import SubCategories from "./SubCategories";

export default function Catalogs() {
  const [department, setDepartment] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  return (
    <>
      {/* <Brands /> */}
      <Department departmentConfig={{ department, setDepartment }} />
      {department ? (
        <Categories
          queryConfig={{ department }}
          categoryConfig={{ category, setCategory }}
        />
      ) : null}
      {/* {category ? <SubCategories queryConfig={{ category }} /> : null} */}
    </>
  );
}
