import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Checkbox } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CustomVariantOptionDrowdown({ title, data, config }: any) {
    const { selectedOptions, setSelectedOptions } = config
    const handleOptionSelect = (event: SelectChangeEvent<typeof data>) => {
        const { value } = event.target;
        setSelectedOptions((prev) => {
            const newState = value.map(el => {
                el.isDeleted = false
                return el;
            });
            return newState;
        });
    };

    const handleDelete = (e: React.MouseEvent, value: any) => {
        e.preventDefault();
        setSelectedOptions((prev) => {
            const data = prev.find(el => el.id === value.id) ? value : null;
            if (data) {
                data.isDeleted = !data.isDeleted;
                return [...prev]
            }
        });
    };

    const modifiedSelectedOptions = selectedOptions.filter((selectedOption) => !selectedOption.isDeleted)


    return (
        <div>
            <FormControl className='w-96 mx-auto'>
                <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={modifiedSelectedOptions}
                    onChange={handleOptionSelect}
                    input={<OutlinedInput id="select-multiple-chip" label={title} />}
                    renderValue={(selected) => {
                        return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value.id} label={value.title}
                                        deleteIcon={
                                            <CancelIcon
                                                onMouseDown={(event) => event.stopPropagation()}
                                            />
                                        }
                                        onDelete={(e) => handleDelete(e, value)}
                                    />
                                ))}
                            </Box>
                        )
                    }}
                    MenuProps={MenuProps}
                >
                    {data.map((el) => {
                        return (
                            <MenuItem key={el.id} value={el}>
                                <Checkbox checked={selectedOptions.find(selected => selected.id === el.id && !selected.isDeleted)} />
                                {el.title}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
    );
}
