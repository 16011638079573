import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react'
import GalleryLayout from 'src/components/gallery/GalleryLayout';
import withPermission from 'src/components/permission/withPermission';
import { permissions } from 'src/constants/permissions';
import { notifyError, notifySuccess } from 'src/generic-ui';
import ActionBar from 'src/generic-ui/ActionBar';
import Popover from 'src/generic-ui/Popover';
import { bannerService, logger } from 'src/services';

function CreateBanner() {
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [openGallery, setOpenGallery] = useState<boolean>(false);
    const [selectedImages, setImages] = useState<{
        images: any[];
    }>({
        images: [],
    });

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors: formikError,
        touched,
        setValues,
    } = useFormik({
        initialValues: {
            title: "",
            image: "",
            description: "",
            isActive: false
        },
        enableReinitialize: true,
        async onSubmit(values, action) {
            try {
                setSubmitting(true);
                await bannerService.createBanner({ ...values });
                notifySuccess("Banner created successfully");
                action.resetForm()
            } catch (error) {
                logger.error(error);
                notifyError(error.message);
            } finally {
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        if (openGallery === false && !!selectedImages?.images?.length) {
          setValues({
            ...values,
            image: selectedImages?.images[0]?.link || "",
          });
        }
      }, [selectedImages, openGallery]);

    const onSelectCategoryImage = () => {
        setOpenGallery(true);
    };

    const onSelectedImages = (key: string, value: any) => {        
        setImages({
            ...selectedImages,
            [key]: value,
        });
    };

    return (
        <>
        <ActionBar title="Create banner" />
            <Grid item xs={12} md={6} lg={6} xl={6}>
                <form onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Title"
                        name="title"
                        type="text"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {formikError.title && touched.title ? (
                        <p className="text-red-600">{formikError.title}</p>
                    ) : null}
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Description"
                        name="description"
                        type="text"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {formikError.description && touched.description ? (
                        <p className="text-red-600">{formikError.description}</p>
                    ) : null}

                    <TextField
                        margin="normal"
                        fullWidth
                        label="Image"
                        name="image"
                        type="text"
                        onClick={onSelectCategoryImage}
                        value={values.image}
                        onBlur={handleBlur}
                    />

                    <div className="pb-10">
                        <p className="text-xs py-2">image preview</p>
                        {values.image ? <a href={values.image} target={values.image ? "_blank" : ""}><img src={values.image} className="h-50 w-70" /> </a>: <div className="border-2 border-dashed px-8 py-8 text-center">
                            No image for preview</div>}
                    </div>

                    <LoadingButton
                        loadingIndicator="Loading..."
                        type="submit"
                        loading={isSubmitting}
                        color="primary"
                        className={"bg-indigo-600 hover:bg-indigo-800 mt-2 text-white"}
                    >
                        Submit
                    </LoadingButton>
                </form>
                <Popover
                    title=""
                    width={1000}
                    height={800}
                    open={openGallery}
                    setOpen={setOpenGallery}
                    onClose={() => {
                        setOpenGallery(false);
                    }}
                >
                    <GalleryLayout
                        isOpenFromModal
                        setFieldValue={onSelectedImages}
                        modalConfig={{ open: openGallery, setOpen: setOpenGallery }}
                    />
                </Popover>
            </Grid>
        </>
    )
}

export default withPermission(CreateBanner, permissions['cms'].permissionSet.banner.create);