import { useEffect, useState } from "react";
import SideBarModal from "src/components/modals/SideBarModal";
import {
    Box,
} from "@mui/material";
import { useFormik } from "formik";
import { logger, productService } from "src/services";
import { notifyError, notifySuccess } from "src/generic-ui";
import { DropResult } from 'react-beautiful-dnd';
import { reorder } from "src/helpers/utility/dnd";
import DraggableList from "src/components/Draggable";
import { parsedReorderItemRequestPayload } from "src/transformer";
import { LoadingButton } from "@mui/lab";
import classNames from "classnames";

export default function ReorderSidebar({
    data,
    onRefresh,
    isVisible,
    onClose,
}: any) {
    const modifiedProductImages = data?.images?.filter((image) => image.isActive === true).sort((a, b) => a.orderPriority - b.orderPriority).map((image) => {
        return {
            id: image.id,
            imageLink: image.link,
            orderPrority: image.orderPriority
        }
    }) || [];

    const [items, setItems] = useState(modifiedProductImages)
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors: formikError,
        touched,
        setValues,
        setFieldValue
    } = useFormik({
        initialValues: {
            items: []
        },
        async onSubmit(values) {
            try {
                setSubmitting(true);
                const parsedValue: any = values?.items?.map((item, index) => {
                    return parsedReorderItemRequestPayload(item, index + 1)
                })
                await productService.reorderProductImage({
                    items: parsedValue
                });
                onRefresh();
                onClose();
                notifySuccess("product images reordered successfully")
            } catch (error) {
                logger.error(error);
                notifyError(error.message)
            } finally {
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        setFieldValue('items', items)
    }, []);

    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return;
        const newItems = reorder(items, source.index, destination.index);
        setItems(newItems);
        setFieldValue('items', newItems)
    };

    return (
        <>
            <SideBarModal
                isVisible={isVisible}
                onClose={onClose}
                title="Re order product images"
            >
                <Box paddingX={2}>
                    <form onSubmit={handleSubmit}>
                        <DraggableList items={items} onDragEnd={onDragEnd} imageClassName="h-30 w-20" />
                        <LoadingButton
                            loadingIndicator="Loading..."
                            type="submit"
                            loading={isSubmitting}
                            color="primary"
                            className={classNames(
                                "bg-indigo-500 hover:bg-indigo-600 mt-2 text-white",
                            )}
                        >
                            Submit
                        </LoadingButton>
                    </form>
                </Box>
            </SideBarModal>

        </>
    );
}
