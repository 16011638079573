import * as React from "react";
import {
  Switch as MUISwitch,
  Popover,
  Typography,
  Box,
  Button,
} from "@mui/material";

interface ISwitch {
  isChecked: boolean;
  onClick?: (isChecked: boolean) => {};
  showPopOver?: boolean;
  isDisabled?: boolean;
}
export default function Switch({
  isChecked,
  onClick,
  showPopOver = false,
  isDisabled = false,
}: ISwitch) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const [checked, setChecked] = React.useState(isChecked);

  React.useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    showPopOver
      ? setAnchorEl(event.currentTarget)
      : onClick?.(event.target.checked);
    !showPopOver && setChecked(event.target.checked);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOk = () => {
    setAnchorEl(null);
    onClick?.(!isChecked);
    setChecked(!isChecked);
  };

  return (
    <>
      <MUISwitch
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
        disabled={isDisabled}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ paddingY: "1rem", paddingX: "1rem" }}>
          Do you want to change this status?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleOk}>Yes</Button>
        </Box>
      </Popover>
    </>
  );
}
