import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { authService, logger } from "src/services";
import { Helmet } from "react-helmet-async";
import { notifyError, notifySuccess } from "src/generic-ui";
import SendIcon from "@mui/icons-material/Send";

const theme = createTheme();

export default function ForgotPassword() {
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    onSubmit({
      email: data.get("email") as string,
      password: data.get("password") as string,
    });
  };

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const response = await authService.resetPasswordRequest({ email });
      navigate(`/reset-password?email=${response.email}&str=${response.hash}`, {
        replace: true,
      });
      notifySuccess("reset request sent to email");
    } catch (error) {
      notifyError(error.message);
      logger.error(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>AAAF LTD - reset password request</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <SendIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Send Password request to email
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="bg-black hover:bg-gray-700 transition hover:duration-1000"
              >
                Send
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
