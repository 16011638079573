import * as Yup from "yup";

export const UserSchema = Yup.object({
  firstName: Yup.string().required("first name is required"),
  lastName: Yup.string().required("last name is required"),
  password: Yup.string().required("password must be required").optional(),
  isActive: Yup.boolean().optional(),
  roles: Yup.array().min(1),
});

export const ResetPasswordSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const CustomerSchema = Yup.object({
  firstName: Yup.string().required("first name is required"),
  lastName: Yup.string().required("last name is required"),
  phoneNumber: Yup.string().required("phone number is required").max(11),
});

export type UserSchemaType = Yup.InferType<typeof UserSchema>;
export type CustomerSchemaType = Yup.InferType<typeof CustomerSchema>;
export type ResetPasswordSchemaType = Yup.InferType<typeof ResetPasswordSchema>;
