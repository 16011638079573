import useRequest from "src/hooks/useRequest";
import { IUserDetails, QueryParamsType } from "src/types";
import { IPermission } from "src/types/acl";
import { useRole } from "../acl/role/useRole";

export const useUserDetails = (id?: string) => {
  const url = `/users/${id}`;
  const { data, mutate, ...rest } = useRequest(!!id ? url : null, url);

  const query: Record<string, QueryParamsType> = {
    limit: 100,
    page: 1,
    isActive: true
  };


  const roleQuery: Record<string, QueryParamsType> = {
    limit: 100,
    page: 1,
  };

  const { roles, ...rest2 } = useRole(roleQuery);

  return {
    data: data as IUserDetails,
    isLoading: rest.isLoading || rest2.isLoading,
    error: rest.error || rest2.error,
    mutate,
    roles,
  };
};
