import { QueryParamsType } from "src/types/query-params";
import qs from "qs";
import useRequest from "src/hooks/useRequest";
import { IPermissionType } from "src/types/acl";

export const usePermissionType = (query: Record<string, QueryParamsType>) => {
  const url = `/permission-types?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);
  return {
    permissionTypes: (data as Array<IPermissionType>) || [],
    mutate,
    isLoading,
    error,
    ...rest,
  };
};
