import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";
import utils from "src/helpers/Utils";
import { SubscriptionSchemaType } from "src/schemas";

class SubscriptionService {
  private readonly newsLetterSubscriptionUrl = "news-letter-subscriptions";
  async createSubscription(subscription: SubscriptionSchemaType) {
    const data: { [key: string]: any } = {};
    Object.keys(subscription).forEach(key => {
      if (!utils.checkIsNullOrUndefined(subscription[key])) data[key] = subscription[key];
    });

    return await apiPost({
      apiPath: this.newsLetterSubscriptionUrl,
      data,
    });
  }

  async updateSubscription({ id, ...city }: SubscriptionSchemaType & { id: string }) {
    const data: { [key: string]: any } = {};
    Object.keys(city).forEach(key => {
      if (!utils.checkIsNullOrUndefined(city[key])) data[key] = city[key];
    });

    return await apiPatch({
      apiPath: `${this.newsLetterSubscriptionUrl}/${id}`,
      data,
    });
  }

  async deleteSubscription(id: string) {
    return await apiDelete({ apiPath: `${this.newsLetterSubscriptionUrl}/${id}` });
  }
}
export const subscriptionService = new SubscriptionService();
