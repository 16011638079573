import { Autocomplete, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useDepartments } from "src/hooks/catalogs/useDepartments";
import { useSpecialCategories } from "src/hooks/catalogs/useSpecialCategories";
import { logger } from "src/services";
import { QueryParamsType } from "src/types/query-params";

export default function SpecialCategories({ specialConfig }: any) {
  const { values, handleChange, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<any>();
  const { setSpecialCategories } = specialConfig;
  const query: Record<string, QueryParamsType> = {
    limit: 40,
    page: 1,
  };

  const { specialCategories, isLoading, mutate } = useSpecialCategories(query);
  useEffect(() => {
    mutate();
  }, [mutate]);

  if (isLoading) return null;

  const modifiedCategories = specialCategories
    .filter(specialCategory => specialCategory.isActive === true)
    .map(activeCategory => {
      return {
        label: activeCategory.title,
        value: activeCategory.id,
      };
    });

  return (
    <>
      <Autocomplete
        id="combo-box-demo"
        fullWidth
        className="mt-4"
        multiple={true}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        options={modifiedCategories}
        renderInput={params => (
          <TextField {...params} label="select special category" />
        )}
        onChange={(event: any, newValue: Record<string, any>) => {
          setFieldValue("specialCategories", newValue);
        }}
        onBlur={handleBlur}
      />
    </>
  );
}
