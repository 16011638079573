import { replaceSpecialCharacter } from "src/helpers/utility/convert-string";
import dateUtils from "src/helpers/utility/DateUtils";
import { IProduct, OrderItem } from "src/types";

export const parsedProductsForOrderItem = (metadata: IProduct) => {
  const { title, mrp, id } = metadata;
  const obj = {
    title,
    mrp,
    quantity: 1,
    id,
    product: { ...metadata },
  };
  return obj;
};

export const parsedProductsForNewAddedOrderItem = (metadata: IProduct) => {
  const { title, mrp, id } = metadata;
  const obj = {
    title,
    mrp,
    quantity: 1,
    id,
    product: { ...metadata },
    productVariantOptions: [],
  };
  return obj;
};

export const parsedProductsForAddedOrderItem = (metadata: IProduct) => {
  const { title, mrp, id, selectedVariants } = metadata;  
  const obj = {
    title,
    mrp,
    quantity: 1,
    id,
    product: { ...metadata },
    productVariantOptions: Object.keys(selectedVariants).map(key => selectedVariants[key]),
  };
  return obj;
};

export const parsedNewlyAddedProductForUpdate = (metadata: any) => {
  const { id, productVariantOptions, quantity } = metadata;
  const obj = {
    product: id,
    productVariantOptions,
    quantity,
  };
  return obj;
};

export const parsedNewlyAddedProduct = (metadata: any) => {
  const { id, quantity, } = metadata;
  const obj = {
    product: id,
    variantOptions: Object.values(metadata.product.selectedVariants),
    quantity,
  };
  return obj;
};

export const parsedOrderItems = (metadata: any) => {
  const { product, mrp, quantity, id } = metadata;
  const obj = {
    title: product.title,
    mrp,
    quantity,
    id,
    product,
  };
  return obj;
};

export const parsedOrderLifeCycle = (metadata: any) => {
  const { orderStatus, createdAt, comments, show, state } = metadata;
  const obj = {
    title: orderStatus
      ? replaceSpecialCharacter(orderStatus, " ")
      : replaceSpecialCharacter(metadata?.status, " "),
    status: orderStatus,
    createdAt: createdAt
      ? dateUtils.formatDateTime(createdAt, "MMM D, YYYY hh:mm a")
      : "-",
    comments,
    show,
    state,
  };
  return obj;
};

export const parsedCreateOrderRequestBody = (metadata: any) => {
  const { user, couponCode, products, shippingAddress, paymentMethod } = metadata;
  const obj = {
    user,
    couponCode,
    products,
    shippingAddress,
    paymentMethod
  };
  couponCode ? obj['couponCode'] = couponCode : null;
  return obj;
}