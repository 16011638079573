import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import "nprogress/nprogress.css";
import App from "src/App";
import { SidebarProvider } from "src/contexts/SidebarContext";
import * as serviceWorker from "src/constants/serviceWorker";
import "./index.css";
import { StyledEngineProvider } from "@mui/styled-engine";
import { AuthProvider } from "./contexts/AuthContext";

ReactDOM.render(
  <HelmetProvider>
    <AuthProvider>
      <SidebarProvider>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </BrowserRouter>
      </SidebarProvider>
    </AuthProvider>
  </HelmetProvider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
