import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import SidebarLayout from "src/layouts/SidebarLayout";
import SuspenseLoader from "./components/SuspenseLoader";
import SignInPage from "./pages/authentication/SignIn";
import { authService } from "./services";
import TableChartTwoToneIcon from "@mui/icons-material/TableChartTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ComingSoon from "./components/ComingSoon";
import CreateProduct from "./pages/product/CreateProduct";
import PaymentIcon from "@mui/icons-material/Payment";
import ProductList from "./pages/product/product-list/ProductList";
import ClassIcon from "@mui/icons-material/Class";
import Gallery from "./pages/gallery";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import CollectionsIcon from "@mui/icons-material/Collections";
import Crop54Icon from "@mui/icons-material/Crop54";
import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import PermissionType from "./pages/access-manaement/permission-type/PermissionType";
import Permission from "./pages/access-manaement/permission/Permission";
import Role from "./pages/access-manaement/role/Role";
import Users from "./pages/user/Users";
import CreateOrder from "./pages/order/create";
import { permissions } from "./constants/permissions";
import Dashboard from "./pages/home/dashboard/Dashboard";
import Report from "./pages/home/Report";
import RatingReview from "./pages/others/rating-review";
import Feedback from "./pages/others/feedback";
import ProductDetails from "./pages/product/details";
import Warehouse from "./pages/location/warehouse";
import OrderDetails from "./pages/order/details";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import AccountSettings from "./pages/profile/AccountSettings";
import MyProfile from "./pages/profile/MyProfile";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import CampaignIcon from "@mui/icons-material/Campaign";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import FeedbackIcon from "@mui/icons-material/Feedback";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CreateReviewRating from "./pages/others/rating-review/CreateReviewRating";
import Banner from "./pages/cms";
import CreateBanner from "./pages/cms/CreateBanner";
import CreateWarehouse from "./pages/location/warehouse/CreateWarehouse";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Notifications from "src/pages/order/notifications";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import Subscriptions from "./pages/marketing/subscriptions";
import SendEmail from "./pages/marketing/SendEmail";
import Customers from "./pages/user/customer/Customers";
import SendSms from "./pages/marketing/SendSms";
import SmsIcon from "@mui/icons-material/Sms";
import EmailIcon from "@mui/icons-material/Email";
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const NotFound = Loader(lazy(() => import("src/pages/Notfound")));
const NotAllowed = Loader(lazy(() => import("src/pages/NotAllowed")));

const Orders = Loader(lazy(() => import("src/pages/order/list")));
const Transactions = Loader(lazy(() => import("src/pages/transaction/list")));

const CategoryLists = Loader(
  lazy(() => import("src/pages/catalogs/categories"))
);
const AddEditCategory = Loader(
  lazy(() => import("src/pages/catalogs/categories/AddEdit"))
);
const Variants = Loader(
  lazy(() => import("src/pages/catalogs/variants/Variants"))
);
const City = Loader(lazy(() => import("src/pages/location/City/City")));
const Area = Loader(lazy(() => import("src/pages/location/Area/Area")));
const PaymentMethod = Loader(
  lazy(() => import("src/pages/order/PaymentMethods/PaymentMethod"))
);
const Brands = Loader(lazy(() => import("src/pages/catalogs/Brands/index")));
const SpecialCategory = Loader(
  lazy(() => import("src/pages/catalogs/SpecialCategory/SpecialCategory"))
);
const DeliveryCharge = Loader(
  lazy(() => import("src/pages/order/deliveryCharge"))
);
const Coupon = Loader(lazy(() => import("src/pages/offers/coupon")));
const VariantOptions = Loader(
  lazy(() => import("src/pages/catalogs/variantOpts/VariantsOpts"))
);
const Campaign = Loader(lazy(() => import("src/pages/offers/campagin")));
const Stock = Loader(lazy(() => import("src/pages/product/stock")));
const Price = Loader(lazy(() => import("src/pages/product/price")));
const Testimonial = Loader(lazy(() => import("src/pages/others/Testimonial")));

interface IRouterObject extends RouteObject {
  navTitle?: string;
  showNabBlock?: boolean;
  children?: IRouterObject[];
  showNavItem?: boolean;
  icon?: any;
  permission?: any;
}

export const ModifiedComingSoonForPage = () => {
  return (
    <>
      <ComingSoon classNames="h-[70vh]">
        <Box textAlign="center" mb={3}>
          <Container maxWidth="xs">
            <Typography variant="h1" sx={{ mt: 4, mb: 2 }}>
              Coming Soon
            </Typography>
            <Typography
              variant="h3"
              color="text.secondary"
              fontWeight="normal"
              sx={{ mb: 4 }}
            >
              We're working on implementing the last features before our launch!
            </Typography>
          </Container>
        </Box>
      </ComingSoon>
    </>
  );
};

const routes: IRouterObject[] = [
  {
    path: "",
    children: [
      {
        path: "",
        showNavItem: false,
        element: (
          <Navigate
            to={!!authService.isLoggedIn() ? "dashboard" : "login"}
            replace
          />
        ),
      },
      {
        path: "/login",
        showNavItem: false,
        element: <SignInPage />,
      },
      {
        path: "/forgot-password",
        showNavItem: false,
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        showNavItem: false,
        element: <ResetPassword />,
      },
    ],
  },
  {
    navTitle: "Home",
    path: "dashboard",
    element: <SidebarLayout />,
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["home"].allPermissions
    ),
    children: [
      {
        path: "",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["home"].permissionSet.dashboard.view
        ),
        navTitle: "Dashboard",
        element: <Dashboard />,
        icon: <DashboardIcon />,
      },
      // {
      //   path: "reports",
      //   showNavItem: !!authService.checkPermissionForNavItem(
      //     permissions["home"].permissionSet.report.view
      //   ),
      //   // showNavItem: false,
      //   navTitle: "Reports",
      //   element: <Report />,
      //   icon: <AnalyticsIcon />,
      // },
    ],
  },
  {
    navTitle: "Product",
    path: "products",
    element: <SidebarLayout />,
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["product"].allPermissions
    ),
    children: [
      {
        path: "",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["product"].permissionSet.product.view
        ),
        navTitle: "All products",
        element: <ProductList />,
        icon: <TableChartTwoToneIcon />,
      },
      {
        path: "create",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["product"].permissionSet.product.create
        ),
        navTitle: "Create product",
        element: <CreateProduct />,
        icon: <AddCircleOutlineIcon />,
      },
      {
        path: "details/:code",
        showNavItem: false,
        navTitle: "Product details",
        element: <ProductDetails />,
        icon: <AddCircleOutlineIcon />,
      },
      {
        path: "edit/:id",
        showNavItem: false,
        navTitle: "Product edit",
        element: <></>,
        icon: <AddCircleOutlineIcon />,
      },
      {
        path: "inventory",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["product"].permissionSet.inventory.view
        ),
        navTitle: "Inventory",
        element: <Stock />,
        icon: <TableChartTwoToneIcon />,
      },
      {
        path: "price",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["product"].permissionSet.price.view
        ),
        navTitle: "Price",
        element: <Price />,
        icon: <TableChartTwoToneIcon />,
      },
    ],
  },
  {
    path: "catalogs",
    navTitle: "Catalogs",
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["catalog"].allPermissions
    ),
    element: <SidebarLayout />,
    children: [
      {
        path: "categories",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["catalog"].permissionSet.category.view
        ),
        navTitle: "Categories",
        icon: <TableChartTwoToneIcon />,
        children: [
          {
            path: "",
            navTitle: "Categories",
            element: <CategoryLists />,
          },
          {
            path: "new",
            element: <AddEditCategory />,
          },
          {
            path: ":id",
            element: <AddEditCategory />,
          },
        ],
      },
      {
        path: "variants",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["catalog"].permissionSet.variant.view
        ),
        navTitle: "Variants",
        icon: <TableChartTwoToneIcon />,
        element: <Variants />,
      },
      {
        path: "variants/options",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["catalog"].permissionSet["variant-option"].view
        ),
        navTitle: "Variant Options",
        icon: <TableChartTwoToneIcon />,
        element: <VariantOptions />,
      },
      // {
      //   path: "brands",
      //   navTitle: "Brands",
      //   icon: <BrandingWatermarkIcon />,
      //   showNavItem: !!authService.checkPermissionForNavItem(
      //     permissions["catalog"].permissionSet.brand.view
      //   ),
      //   element: <Brands />,
      // },
      // {
      //   path: "special-category",
      //   navTitle: "Special Category",
      //   icon: <ClassIcon />,
      //   showNavItem: !!authService.checkPermissionForNavItem(
      //     permissions["catalog"].permissionSet["special-category"].view
      //   ),
      //   element: <SpecialCategory />,
      // },
    ],
  },
  {
    path: "offer",
    navTitle: "Offers",
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["offer"].allPermissions
    ),
    element: <SidebarLayout />,
    children: [
      {
        path: "campaign",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["offer"].permissionSet.campaign.view
        ),
        navTitle: "Campaign",
        element: <Campaign />,
        icon: <CampaignIcon />,
      },
      // {
      //   path: "coupons",
      //   icon: <ConfirmationNumberIcon />,
      //   navTitle: "Coupons",
      //   showNavItem: !!authService.checkPermissionForNavItem(
      //     permissions["offer"].permissionSet.coupon.view
      //   ),
      //   element: <Coupon />,
      // },
    ],
  },
  {
    path: "profile",
    navTitle: "Profile",
    showNabBlock: false,
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="details" replace />,
      },
      {
        path: "details",
        navTitle: "My profile",
        showNavItem: false,
        icon: <AccountCircleTwoToneIcon />,
        element: <MyProfile />,
      },
      {
        path: "settings",
        navTitle: "Account settings",
        showNavItem: false,
        icon: <SettingsIcon />,
        element: <AccountSettings />,
      },
    ],
  },
  {
    path: "orders",
    navTitle: "Order",
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["order"].allPermissions
    ),
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        navTitle: "All orders",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["order"].permissionSet.order.view
        ),
        icon: <TableChartTwoToneIcon />,
        element: <Orders />,
      },
      // {
      //   path: "create",
      //   navTitle: "Create order",
      //   showNavItem: !!authService.checkPermissionForNavItem(
      //     permissions["order"].permissionSet.order.create
      //   ),
      //   icon: <AddCircleOutlineIcon />,
      //   element: <CreateOrder />,
      // },
      {
        path: "details/:code",
        navTitle: "Order details",
        showNavItem: false,
        icon: <TableChartTwoToneIcon />,
        element: <OrderDetails />,
      },
      {
        path: "payment-methods",
        navTitle: "Payment Methods",
        icon: <PaymentIcon />,
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["order"].permissionSet["payment-method"].view
        ),
        element: <PaymentMethod />,
      },
      {
        path: "delivery-charge",
        navTitle: "Delivery Charge",
        icon: <Crop54Icon />,
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["order"].permissionSet["delivery-charge"].view
        ),
        element: <DeliveryCharge />,
      },
    ],
  },

  {
    path: "transactions",
    navTitle: "Transaction",
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["transaction"].allPermissions
    ),
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        navTitle: "All Transaction",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["transaction"].permissionSet.transaction.view
        ),
        icon: <TableChartTwoToneIcon />,
        element: <Transactions />,
      },
      {
        path: "details/:code",
        navTitle: "Transaction Details",
        showNavItem: false,
        icon: <TableChartTwoToneIcon />,
        element: <OrderDetails />,
      },
    ],
  },
  {
    path: "location",
    navTitle: "Location",
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["location"].allPermissions
    ),
    element: <SidebarLayout />,
    children: [
      {
        path: "city",
        navTitle: "City",
        icon: <LocationCityIcon />,
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["location"].permissionSet.city.view
        ),
        element: <City />,
      },
      {
        path: "area",
        navTitle: "Area",
        icon: <Crop54Icon />,
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["location"].permissionSet.area.view
        ),
        element: <Area />,
      },
      {
        path: "warehouse",
        navTitle: "Warehouse",
        icon: <Crop54Icon />,
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["location"].permissionSet.warehouse.view
        ),
        element: <Warehouse />,
      },
      {
        path: "warehouse/create",
        icon: <AddCircleOutlineIcon />,
        navTitle: "Create warehouse",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["location"].permissionSet.warehouse.create
        ),
        element: <CreateWarehouse />,
      },
    ],
  },
  {
    path: "cms",
    navTitle: "Cms",
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["cms"].allPermissions
    ),
    element: <SidebarLayout />,
    children: [
      {
        path: "banners",
        icon: <CollectionsIcon />,
        navTitle: "Banners",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["cms"].permissionSet.banner.view
        ),
        element: <Banner />,
      },
      {
        path: "banners/create",
        icon: <AddCircleOutlineIcon />,
        navTitle: "Create banner",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["cms"].permissionSet.banner.create
        ),
        element: <CreateBanner />,
      },
    ],
  },
  {
    path: "others",
    navTitle: "Others",
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["others"].allPermissions
    ),
    element: <SidebarLayout />,
    children: [
      {
        path: "testimonials",
        icon: <ClassIcon />,
        navTitle: "Testimonial",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["others"].permissionSet.testimonial.view
        ),
        element: <Testimonial />,
      },
      {
        path: "rating-and-reviews",
        icon: <ThumbsUpDownIcon />,
        navTitle: "Rating & Reviews",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["others"].permissionSet["rating-review"].view
        ),
        element: <RatingReview />,
      },
      {
        path: "rating-and-reviews/create",
        icon: <AddCircleOutlineIcon />,
        navTitle: "Create rating & review",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["others"].permissionSet["rating-review"].create
        ),
        element: <CreateReviewRating />,
      },
      {
        path: "feedbacks",
        icon: <FeedbackIcon />,
        navTitle: "Feedbacks",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["others"].permissionSet.feedback.view
        ),
        element: <Feedback />,
      },
      {
        path: "notifications",
        navTitle: "Notifications",
        showNavItem: false,
        icon: <NotificationsActiveIcon />,
        element: <Notifications />,
      },
    ],
  },
  {
    path: "access-control",
    navTitle: "Access Control",
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["access-control"].allPermissions
    ),
    element: <SidebarLayout />,
    children: [
      {
        path: "permission-types",
        navTitle: "Permission Type",
        icon: <ClassIcon />,
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["access-control"].permissionSet.admin
        ),
        element: <PermissionType />,
      },
      {
        path: "permissions",
        navTitle: "Permission",
        icon: <ClassIcon />,
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["access-control"].permissionSet.admin
        ),
        element: <Permission />,
      },
      {
        path: "roles",
        navTitle: "Role",
        icon: <ClassIcon />,
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["access-control"].permissionSet.admin
        ),
        element: <Role />,
      },
    ],
  },
  {
    path: "user",
    navTitle: "User Management",
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["user-management"].allPermissions
    ),
    element: <SidebarLayout />,
    children: [
      {
        path: "all-user",
        navTitle: "All users",
        icon: <SupervisedUserCircleIcon />,
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["user-management"].permissionSet.admin
        ),
        element: <Users />,
      },
      {
        path: "customers",
        navTitle: "Customer",
        icon: <PeopleAltIcon />,
        showNavItem: true,
        element: <Customers />,
      },
    ],
  },
  {
    path: "marketing",
    navTitle: "Marketing",
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["marketing"].allPermissions
    ),
    element: <SidebarLayout />,
    children: [
      {
        path: "subscriptions",
        navTitle: "Subscription",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["marketing"].permissionSet.subscription.view
        ),
        icon: <SubscriptionsIcon />,
        element: <Subscriptions />,
      },
      {
        path: "subscriptions/send-email",
        navTitle: "Send email",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["marketing"].permissionSet.email.send
        ),
        icon: <EmailIcon />,
        element: <SendEmail />,
      },
      {
        path: "customer/send-sms",
        navTitle: "Send sms",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["marketing"].permissionSet.sms.send
        ),
        icon: <SmsIcon />,
        element: <SendSms />,
      },
    ],
  },
  {
    path: "gallery",
    navTitle: "Gallery",
    showNabBlock: !!authService.checkPermissionForNavBlock(
      permissions["gallery"].allPermissions
    ),
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        navTitle: "Images",
        showNavItem: !!authService.checkPermissionForNavItem(
          permissions["gallery"].permissionSet.view
        ),
        icon: <CollectionsIcon />,
        element: <Gallery />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/not-allowed",
    element: <NotAllowed />,
  },
];

export const getPathMapToTitle = () => {
  const pathMapToTitle: { [key: string]: string } = {};

  const routerPathMap = (
    parentPath: string = "",
    routesToTraverse: IRouterObject[] = routes
  ) => {
    routesToTraverse.forEach((route: IRouterObject) => {
      const path = !!route?.path
        ? `${parentPath}/${route.path}`
        : `${parentPath}`;
      const title = route.navTitle;

      if (!route?.children && !!title && !path.includes(":")) {
        pathMapToTitle[path] = title;
      }
      if (!!route?.children) {
        routerPathMap(path, route.children);
      }
    });
  };
  routerPathMap();
  return pathMapToTitle;
};

export default routes;
