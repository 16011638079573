import { useFormikContext } from 'formik';
import React, { useEffect } from 'react'
import CustomVariantOptionDrowdown from 'src/pages/product/product-list/edit/CustomVariantOptionDrowdown';
import { useVariantOptions } from 'src/hooks/variant-options/useVariantOptions'
import { QueryParamsType } from 'src/types';

const PAGE_SIZE = 40;

export default function VariantOption({ variantId, title, config }) {
    const { manageVariants, setManageVariants } = config;
    const { values , setFieldValue } = useFormikContext<any>();
    const newData = values.productStocks.filter((stock) => stock.variant === title)[0]?.data.map((el) => el.variantOption);
    const [selectedOptions, setSelectedOptions] = React.useState<any>(newData ? newData : []);

    const query: {
        limit: number;
        page: number;
        variant: string;
    } = {
        limit: PAGE_SIZE,
        page: 1,
        variant: variantId
    };

    useEffect(() => {
        setFieldValue('variants',selectedOptions)
    }, [selectedOptions])

    useEffect(() => {
        let variantObject = {}
        variantObject[title] = []
        setManageVariants((prev) => {
            return { ...prev, ...variantObject }
        })
    }, [title])

    useEffect(() => {
        setManageVariants((prev) => {
            const label = title;
            prev[label] = selectedOptions;
            return {
                ...prev
            }
        });
        const obj = {}
        obj[title] = selectedOptions;
        setFieldValue('manageVariants', {...values.manageVariants,...obj});
    },[title, selectedOptions]);

    const { isLoading, variantOptions, mutate, error, meta } =
    useVariantOptions({...query});

    if(isLoading) return null;

    return (
        <div>
            <CustomVariantOptionDrowdown title={title} data={variantOptions} config={{ selectedOptions, setSelectedOptions}} />
        </div>
    )
}
