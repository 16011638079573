import lodash from "lodash";
class Utils {
  validatePhoneNumber = (input_str: string): boolean => {
    let input = input_str.replace("+1", "").replace(/\D/g, "");
    let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im;
    return re.test(input);
  };

  validateEmail = (email: string) => {
    let input = email.trim();
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(input);
  };

  formatPhoneNumber = (number: string): string => {
    const phoneNumberString: string = number.replace("+1", "").trim();
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    return phoneNumberString;
  };

  validateMagicCode = (code: string): boolean => {
    return code.trim().length === 6;
  };

  stringTruncate = (str: string, n: number): string => {
    return str.length > n ? str.substring(0, n - 1) + " ...." : str;
  };
  isDefined = (object: { [key: string]: any }, property: any = null) => {
    if (property === null) {
      return typeof object !== "undefined";
    }

    return (
      typeof object !== "undefined" &&
      object &&
      typeof object[property] !== "undefined"
    );
  };

  checkIsNullOrUndefined = (value: any) => {
    return value === undefined || value === null;
  };
  deepClone = <T>(value: T): T => {
    return lodash.cloneDeep(value);
  };
}
const utils = new Utils();
export default utils;
