import { QueryParamsType } from "src/types/query-params";
import qs from "qs";
import useRequest from "src/hooks/useRequest";

interface IUseNotification {
  page?: number;
  limit?: number;
  searchTerm?: string;
}
export const useNotification = ({
  page = 1,
  limit = 5,
  searchTerm = "",
}: IUseNotification) => {
  const query: Record<string, QueryParamsType> = {
    page,
    limit,
  };
  if (!!searchTerm) query["searchTerm"] = searchTerm;
  const url = `/notifications?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);

  return {
    notifications: (data as any) || [],
    mutate,
    isLoading: isLoading,
    error: error,
    ...rest,
  };
};
