import classNames from 'classnames';
import React from 'react';
import CustomPagination from './CustomPagination/CustomPagination';

export default function Basic({ tableHeaders, tableRows, id, rowAction, renderTableFooter }: any) {
  const renderTableHeader = tableHeaders.map((header: any, index: number) => {
    return (
      <th
        key={index}
        scope="col"
        className={classNames(
          'capitalize',
          header.title
            ? 'whitespace-nowrap py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6'
            : 'relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6',
          header.hideTitle ? 'opacity-0' : '',
          header.titleClass
        )}
      >
        {header.title}
      </th>
    );
  });

  return (
    <>
      <div className="mt-8 flow-root 2xl:mx-5 xl:mx-5 lg:mx-5 md:mx-5">
        <div className="-my-2 -mx-4 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-visible bg-white border ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300" id={id}>
                <thead className="bg-gray-50">
                  <tr>{renderTableHeader}</tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {tableRows.map((data: any, index: number) => (
                    <React.Fragment key={index}>
                      <tr onClick={rowAction} className="hover:bg-gray-50">
                        {Object.values(tableHeaders).map((header: any, index: number) => {
                          const accessor = header.accessor;
                          return (
                            <React.Fragment key={index}>
                              {accessor ? (
                                header.render ? (
                                  <td
                                    key={index}
                                    className={classNames(
                                      header.title
                                        ? `py-3 pl-6 pr-3 text-left font-normal w-[50px]`
                                        : '',
                                      header['rowClassName'] ? header.rowClassName : ''
                                    )}
                                  >
                                    <header.render data={data[accessor]} />
                                  </td>
                                ) : (
                                  <td
                                    key={index}
                                    className={classNames(
                                      'py-3 pl-6 pr-3 text-left font-normal w-[50px]',
                                      header['rowClassName'] ? header.rowClassName : ''
                                    )}
                                  >
                                    {data[accessor]}
                                  </td>
                                )
                              ) : (
                                <td
                                  key={index}
                                  className={classNames(
                                    header.title
                                      ? `py-3 pl-6 pr-3 text-left font-normal w-[50px]`
                                      : '',
                                    header['rowClassName'] ? header.rowClassName : ''
                                  )}
                                >
                                  <header.render {...data} />
                                </td>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
                <tfoot>
                  <td></td>
                  <td>
                    {renderTableFooter}
                  </td>
                  <td></td>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}