import { ArrowRight } from '@mui/icons-material';
import React from 'react';
import { ProductStocksEntity } from 'src/types'

export default function VariantAndVariantOpt({ productStocks }) {
    return (
        <div className='py-4 grid grid-cols-8'>
            {productStocks?.map((productStock: ProductStocksEntity, index: number) => {
                return (
                    <div key={index} className='py-2'>
                        <p className='text-sm font-semibold mb-2'>{productStock?.variant}</p>
                        <ul className='p-0'>
                            {productStock?.data.map((variant: any, index: number) => {
                                return (
                                    <React.Fragment key={index}>
                                        <li >
                                            <div className='flex'>
                                                <ArrowRight />
                                                {variant?.variantOption?.title}
                                            </div>
                                        </li>
                                    </React.Fragment>
                                )
                            })}
                        </ul>
                    </div>)
            })}
        </div>
    )
}
