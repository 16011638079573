import React from 'react'
import Chart from 'react-apexcharts'
import Spinner from 'src/generic-ui/Spinner'
import chartOptions from 'src/helpers/utility/chartOption'
import { useOrderCountByStatus } from 'src/hooks/dashboard/useOrderCountByStatus'
import { QueryParamsType } from 'src/types'

export default function OrderStatusCount({ dates }) {
  const query: Record<string, QueryParamsType> = {}
  dates?.validForm ? query['startDate'] = dates?.validForm : null
  dates?.validTill ? query['endDate'] = dates?.validTill : null;
  const { data, isLoading } = useOrderCountByStatus(query);

  if (isLoading) return <Spinner />;
  const series: any = Object.values(data);
  const labels: any = Object.keys(data)
  const chartData = {
    options: {
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
      },
      labels: labels,
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '65%'
          }

        }
      }
    },
    series: series,
  }


  return (
    <div className='border shadow-lg p-5 rounded-lg 2xl:h-65'>
      <p className='text-base font-semibold'>Total order (order status)</p>
      <Chart options={chartData.options} series={chartData.series} type="donut" />
    </div>
  )
}
