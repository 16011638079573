import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useVariants } from "src/hooks/variants/useVariants";
import { QueryParamsType } from "src/types";
import Variant from "./Variant";
import _ from "lodash";
import { useFormikContext } from "formik";

export default function Variants() {

  const query: Record<string, QueryParamsType> = {
    limit: 40,
    page: 1,
  };

  const { isLoading, variants, mutate, error, meta } = useVariants(query);

  const { setFieldValue, values } = useFormikContext<any>();
  const data = _.differenceWith(values.productStocks, variants!, _.isEqual);
  const defaultSelectedVariants = data.map((el) => {
    return {
      label: el.variant,
      value: el.data[0].variant.id
    }
  })
  const [selectedVariants, setSelectedVariants] = useState(
    defaultSelectedVariants ? defaultSelectedVariants : [],
  );

  const [manageVariants, setManageVariants] = useState({});

  useEffect(() => {
    const obj = {}
    data.map((el) => {
      obj[el.variant] = [...el.data.map((e) => e.variantOption)]
    })
    setManageVariants({ ...obj })
  }, [])


  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;

    const variant = variants.filter((variant) => value === variant.id).map((val) => {
      return {
        label: val.title,
        value: val.id,
      }
    });
    setSelectedVariants(prev => [...prev, ...variant]);
  };

  useEffect(() => {
    setFieldValue("variantLabels", selectedVariants);
  }, [selectedVariants]);

  useEffect(() => {
    setFieldValue("manageVariants", manageVariants);
  }, [manageVariants]);


  if (isLoading) return null;

  const modifiedVariants = variants
    .filter(variant => variant.isActive === true)
    .map(activeVariant => {
      return {
        label: activeVariant.title,
        value: activeVariant.id,
      };
    });

  const filtered = _.xorBy(modifiedVariants, selectedVariants, "value");

  return (
    <>
      {selectedVariants.length > 0 &&
        selectedVariants.map((variant, index) => {
          return (
            <div key={index} className="border mt-2 py-4 mx-3">
              <Variant
                variant={variant}
                config={{
                  setSelectedVariants,
                  selectedVariants,
                  manageVariants,
                  setManageVariants,
                }}
              />
            </div>
          );
        })}

      {filtered.length > 0 && (
        <FormControl className="w-96 mx-auto my-4">
          <InputLabel id="demo-simple-select-label">select variants</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select variant"
            onChange={handleChange}
          >
            {filtered.map(item => {
              return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>;
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
}
