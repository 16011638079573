import { Autocomplete, TextField } from '@mui/material'
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useSubCategories } from 'src/hooks/catalogs/useSubCategories';
import { QueryParamsType } from 'src/types/query-params';

export default function SubCategories({ queryConfig }) {
    const { values, handleChange, handleBlur, errors, touched } = useFormikContext<any>();
    const { category } = queryConfig;

    const query: Record<string, QueryParamsType> = {
        limit: 40,
        page: 1
    }
    query['category'] = category;
    const { subCategories, isLoading, mutate } = useSubCategories(query);

    const [selectedSubCategory, setSelectedSubCategory] = useState<Record<string, string>>(values.subCategory ? values.subCategory : {})

    useEffect(() => {
        mutate();
    }, [mutate]);

    if (isLoading) return null;


    const modifiedSubCategories = subCategories.filter((subCategory) => subCategory.isActive === true).map((activeSubCategory) => {
        return {
            label: activeSubCategory.title,
            value: activeSubCategory.id
        }
    });

    const defaultOption = modifiedSubCategories.find(option => option.value === selectedSubCategory);

    return (
        <>
            <Autocomplete
                defaultValue={defaultOption}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                id="combo-box-demo"
                fullWidth
                className="mt-4"
                options={modifiedSubCategories}
                renderInput={(params) => <TextField {...params} label="select sub catagories" />}
                onChange={(event: any, newValue: Record<string, any>) => {
                    handleChange('subCategory')(newValue.value);
                    setSelectedSubCategory(newValue.value)
                }}
            />
            {errors.subCategory && touched.subCategory ? (
                <p className="text-red-600">{errors.subCategory}</p>
            ) : null}
        </>
    )
}
