import { useState, useEffect } from "react";
import SideBarModal from "src/components/modals/SideBarModal";
import { IModals, IProduct } from "src/types";
import {
    Box,
    Grid,
    IconButton,
    TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import GalleryLayout from "src/components/gallery/GalleryLayout";
import Popover from "src/generic-ui/Popover";
import SuspenseLoader from "src/components/SuspenseLoader";
import { logger, productRatingService } from "src/services";
import DeleteIcon from "@mui/icons-material/Delete";
import { useProductRatingDetails } from "src/hooks/product-rating/useProductRatingDetails";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import SearchProducts from "src/components/SearchProducts/SearchProducts";
import EmptyState from "src/generic-ui/EmptyStates";
import { notifyError, notifySuccess } from "src/generic-ui";

interface ISidebar extends IModals {
    selectedId?: string;
    onRefresh?: () => void;
}
export default function Sidebar({
    isVisible,
    onClose,
    selectedId,
    onRefresh,
}: ISidebar) {
    const [rating, setRating] = useState<number | null>(null);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedProduct, setSelectedProducts] = useState<any>([]);
    const handleRatingChange = (value: number) => {
        if (rating === value) {
            setRating(null);
        } else {
            setRating(value);
        }
    };
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [openGallery, setOpenGallery] = useState<boolean>(false);
    const [selectedImages, setImages] = useState<{
        images: any[];
    }>({
        images: [],
    });

    const { isLoading, data, mutate, error } = useProductRatingDetails(selectedId);

    const onToggleModal = () => setModalVisible(prev => !prev);

    const onCloseModal = () => {
        onToggleModal();
    };

    const onSelectedImages = (key: string, value: any) => {
        setImages({
            ...selectedImages,
            [key]: value,
        });
    };

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors: formikError,
        touched,
        setFieldValue,
    } = useFormik({
        initialValues: {
            product: "",
            comment: data?.comment || "",
            rating: data?.rating || "",
        },
        enableReinitialize: true,
        onSubmit(values) {
            onEditSubmit(values)
        },
    });


    useEffect(() => {
        setRating(values.rating);
        return () => {
            setRating(null)
        }
    }, [values.rating]);

    useEffect(() => {
        setFieldValue('product', selectedProduct[0]?.id);
    }, [selectedProduct]);


    useEffect(() => {
        setSelectedProducts([{ ...data?.product }])
        return () => {
            setSelectedProducts([])
        }
    }, [data?.product])

    if (isLoading) {
        return <SuspenseLoader />;
    }

    const onSelectProduct = (product: IProduct) => {
        setSelectedProducts([{ ...product }]);
    };

    const onEditSubmit = async (values: any) => {
        try {
            setSubmitting(true);
            await productRatingService.updateProductRating({ ...values, id: selectedId });
            onRefresh?.();
            onClose();
            notifySuccess('review & rating updated successfully');
        } catch (error) {
            logger.error(error);
            notifyError(error.message);
        } finally {
            setSubmitting(false);
        }
    };

    const onDelete = async () => {
        try {
            setSubmitting(true);
            await productRatingService.deleteProductRating(selectedId);

            onRefresh?.();
            onClose();
        } catch (error) {
            logger.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <SideBarModal
                isVisible={isVisible}
                onClose={onClose}
                title={!selectedId ? "Add New" : "Edit Item"}
                renderEndIcon={
                    !!selectedId && (
                        <>
                            <IconButton onClick={onDelete} disabled={isSubmitting}>
                                <DeleteIcon className="text-red-400" />
                            </IconButton>
                        </>
                    )
                }
            >
                <Box paddingX={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <form onSubmit={handleSubmit}>
                            <div className="flex items-center">
                                {[1, 2, 3, 4, 5].map(value => (
                                    <button
                                        type="button"
                                        key={value}
                                        className={`pr-2 hover:text-yellow-500 focus:outline-none ${rating && value <= rating ? 'text-yellow-500' : 'text-gray-400'
                                            }`}
                                        onClick={() => {
                                            handleRatingChange(value)
                                            setFieldValue('rating', value)
                                        }}
                                    >
                                        {rating && value <= rating ? (
                                            <StarIcon className="h-5 w-5" />
                                        ) : (
                                            <StarBorderIcon className="h-5 w-5" />
                                        )}
                                    </button>
                                ))}
                                {rating && <div className="ml-2">{rating} star</div>}
                            </div>

                            <TextField
                                rows={5}
                                name="comment"
                                multiline
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="w-full mt-3"
                                value={values.comment}
                            >
                            </TextField>

                            {selectedProduct?.length > 0 ? selectedProduct.map((product: IProduct, index: number) => {
                                const thumbImageLink = product?.images?.find(image => image?.isThumb === true).link;
                                return (
                                    <div className="border rounded-md mt-3" key={index}>
                                        <div className="flex p-3">
                                            <img src={thumbImageLink} alt="" className="w-30 h-20" />
                                            <div className="mx-4">
                                                <div className="flex w-full">
                                                    <p className="font-semibold capitalize my-1">{product?.title}</p>
                                                </div>
                                                <p className="my-1">৳{product?.newPrice}</p>
                                                <p className="my-1 flex">
                                                    {product?.stats ? <>
                                                        <StarIcon className="h-5 w-5 mb-0.5 text-yellow-500" /> {product?.stats?.totalRating}
                                                    </> : null}
                                                </p>
                                            </div>
                                            <div className="flex w-full">
                                                <button className="ml-auto" onClick={() => setSelectedProducts([])}> <DeleteIcon className="h-5 w-5" /></button>

                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <EmptyState mainTitle={"No product found"} tagline="add new product" buttonTitle="add product" className="mt-3 py-5 border-2 border-dashed rounded-md" onClick={(e) => setModalVisible(true)} showButton/>}

                            {isModalVisible && (
                                <SideBarModal
                                    isVisible={isModalVisible}
                                    onClose={onCloseModal}
                                    allowBackdrop={false}
                                    left={60}
                                    title="Add Product"
                                >
                                    <Box p="0.5rem">
                                        <SearchProducts
                                            onClick={onSelectProduct}
                                            selectedProducts={selectedProduct?.map(p => p.id)}
                                        />
                                    </Box>
                                </SideBarModal>
                            )}
                            <LoadingButton
                                loadingIndicator="Loading..."
                                type="submit"
                                loading={isSubmitting}
                                color="primary"
                                className={"bg-indigo-600 hover:bg-indigo-800 mt-3 text-white"}
                            >
                                Submit
                            </LoadingButton>
                        </form>
                    </Grid>
                </Box>
            </SideBarModal>
            <Popover
                title=""
                width={1000}
                height={800}
                open={openGallery}
                setOpen={setOpenGallery}
                onClose={() => {
                    setOpenGallery(false);
                }}
            >
                <GalleryLayout
                    isOpenFromModal
                    setFieldValue={onSelectedImages}
                    modalConfig={{ open: openGallery, setOpen: setOpenGallery }}
                />
            </Popover>
        </>
    );
}
