import { Avatar, Box, Grid, Stack } from '@mui/material'
import React from 'react'
import { deepPurple } from '@mui/material/colors';
import { useAuth } from 'src/contexts/AuthContext';
import { useUserDetails } from 'src/hooks/user/useUserDetails';
import Spinner from 'src/generic-ui/Spinner';
import dateUtils from 'src/helpers/utility/DateUtils';
import { IRole } from 'src/types/acl';
import ActionBar from 'src/generic-ui/ActionBar';

function MyProfile() {
  const { user } = useAuth();
  const { data, isLoading } = useUserDetails(user?.id)

  if (isLoading) return <Spinner />

  return (
    <>
      <ActionBar title="Profile" />

      <div className="bg-white px-4 py-5 sm:px-6 border rounded-md my-5 mt-3">
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <img
              className="h-10 w-10 rounded-full"
              src="/static/images/avatars/profile.webp"
              alt=""
            />
          </div>
          <div className="min-w-0 flex-1">
            <p className="text-sm font-semibold text-gray-900">
              {data?.firstName + " " + data?.lastName}
            </p>
            <p className="text-sm text-gray-500">
              <span className='mr-2'>created at</span>{dateUtils.formatDateTime(data?.createdAt.toString())}
            </p>
          </div>
          <div className="flex flex-shrink-0 self-center">

          </div>
        </div>
        <div className='my-5'>
          <p className='my-2'>
            <span className='mr-2 font-semibold'>Email address</span>
            <span>{data?.email} </span>
          </p>
          <p className='flex my-2'>
            <span className='mr-2 font-semibold'>Acting roles</span>
            <span>
              {data.roles.map((role: IRole, index: number) => {
                return (
                  <div key={index} className="border rounded-md px-2 py-1 mr-2">
                    {role?.title === '*' ? "Super admin" : role?.title}
                  </div>
                )
              })}
            </span>
          </p>
        </div>
      </div>
    </>
  )
}

export default MyProfile