import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  DEBOUNCE_DELAY,
  DEFAULT_PAGE_SIZE,
} from "src/constants/globalConstants";
import { DataTable } from "src/generic-ui/DataTable";
import { useDebounce } from "src/hooks/useDebounce";
import { useUsers } from "src/hooks/user/useUsers";
import { QueryParamsType } from "src/types/query-params";
import Sidebar from "./Sidebar";
import { IUserDetails } from "src/types";
import { IRole } from "src/types/acl";
import { logger, userService } from "src/services";
import Switch from "src/generic-ui/Switch";
import { detectSuperAdmin } from "src/helpers/utility/special-function";
import withPermission from "src/components/permission/withPermission";
import { permissions } from "src/constants/permissions";
import ActionBar from "src/generic-ui/ActionBar";
import FilterSidebar from "./FilterSidebar";
import FilterListIcon from "@mui/icons-material/FilterList";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

function Users() {
  const [isSidebarVisible, setVisible] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [isFilterModalVisible, setFilterModal] = useState<boolean>(false);
  const [selectedId, setSelected] = useState<string | null>(null);
  const debouncedSearchTerm = useDebounce({
    searchQuery: searchTerm,
    delay: DEBOUNCE_DELAY,
  });
  const query: Record<string, QueryParamsType> = {
    limit: pageSize,
    page: page + 1,
    roles: JSON.stringify(selectedOptions.map((option) => option.value)),
  };
  if (!!debouncedSearchTerm) query["searchTerm"] = debouncedSearchTerm;

  const { isLoading, users, mutate, error, meta } = useUsers(query);
  const onToggleSideBar = () => setVisible((prev) => !prev);

  const [rowCountState, setRowCountState] = useState(meta?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.total !== undefined ? meta?.total : prevRowCountState
    );
  }, [meta, setRowCountState]);

  const onClose = () => {
    onToggleSideBar();
    setSelected(null);
  };

  const handleOnCellClick = ({ row }: { row: IUserDetails }) => {
    setSelected(row.id);
    onToggleSideBar();
  };

  const onRefresh = () => {
    mutate();
  };

  const onToggleFilter = () => setFilterModal((prev) => !prev);
  const onCloseFilterModal = () => {
    onToggleFilter();
  };

  const updateElement = async ({
    keyName,
    id,
    value,
  }: {
    keyName: string;
    id: string;
    value: any;
  }) => {
    try {
      await userService.updateUser(id, { [keyName]: value });
    } catch (error) {
      logger.error(error);
    } finally {
      onRefresh();
    }
  };

  const columns: any = [
    {
      field: "firstName",
      headerName: "Full name",
      flex: 1,
      renderCell: ({ row }: { row: IUserDetails }) => (
        <>
          {row.firstName || row.lastName
            ? row.firstName + " " + row.lastName
            : "-"}
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      renderCell: ({ row }: { row: IUserDetails }) => (
        <>{row.email ? row.email : "-"}</>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      flex: 1,
      renderCell: ({ row }: { row: IUserDetails }) => (
        <>{row.phoneNumber ? row.phoneNumber : "-"}</>
      ),
    },
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      renderCell: ({ row }: { row: IUserDetails }) => {
        const isSuperAdmin = detectSuperAdmin(row);

        return (
          <>
            {isSuperAdmin ? null : (
              <Switch
                isChecked={!!row.isActive}
                showPopOver={true}
                onClick={(isChecked: boolean) =>
                  updateElement({
                    keyName: "isActive",
                    value: isChecked,
                    id: row.id,
                  })
                }
              />
            )}
          </>
        );
      },
    },
    {
      field: "role",
      headerName: "Roles",
      flex: 2,
      renderCell: ({ row }: { row: IUserDetails }) => (
        <>
          {row.roles.map((role: IRole, index: number) => {
            return (
              <div key={index} className="border rounded-md px-2 py-1 mr-2">
                {role?.title}
              </div>
            );
          })}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: IUserDetails }) => {
        const isSuperAdmin = detectSuperAdmin(row);
        return (
          <>
            {isSuperAdmin ? (
              <></>
            ) : (
              <IconButton
                onClick={() => {
                  handleOnCellClick({ row });
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        );
      },
    },
  ];
  if (error) {
    return <div>Something Went Wrong</div>;
  }

  const onReset = () => {
    setSearchTerm("");
    setSelectedOptions([]);
  };

  const showResetAllButton = useMemo(() => {
    return !!debouncedSearchTerm || !!selectedOptions.length;
  }, [debouncedSearchTerm, selectedOptions]);

  const modifiedUsers = users.filter(
    (user) => !user.roles.some((role) => role.title === "*")
  );
  const filterConfigs = {
    onReset,
    showResetAllButton,
    selectedOptions,
    setSelectedOptions,
  };

  return (
    <>
      <Helmet>
        <title>AAAF LTD - User</title>
      </Helmet>
      <ActionBar title="Users">
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <input
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className=" block  rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
          />
          <Tooltip arrow title="create">
            <IconButton
              className="bg-indigo-500 hover:bg-indigo-600 text-white ml-2 p-1.5"
              onClick={() => onToggleSideBar()}
            >
              <AddCircleIcon fontSize="medium" className="text-white" />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="filter options">
            <IconButton
              className="bg-indigo-500 hover:bg-indigo-600 text-white ml-2 p-1.5"
              onClick={onToggleFilter}
            >
              {showResetAllButton && (
                <div
                  style={{
                    position: "absolute",
                    top: "-0.2rem",
                    right: "-0.2rem",
                    borderRadius: "50%",
                    background: "red",
                    width: "0.7rem",
                    height: "0.7rem",
                  }}
                />
              )}
              <FilterListIcon fontSize="medium" className="text-white" />
            </IconButton>
          </Tooltip>

          {showResetAllButton && (
            <Tooltip arrow title="reset filter">
              <IconButton
                className="bg-red-500 hover:bg-red-600 text-white ml-2 p-1.5"
                onClick={() => {
                  onReset();
                }}
              >
                <RestartAltIcon fontSize="medium" className="text-white" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </ActionBar>
      <DataTable
        columns={columns}
        data={modifiedUsers}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        disableColumnMenu={true}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCountState={rowCountState}
      />
      {isSidebarVisible && (
        <Sidebar
          isVisible={isSidebarVisible}
          onClose={onClose}
          selectedId={selectedId}
          onRefresh={onRefresh}
        />
      )}
      {isFilterModalVisible && (
        <FilterSidebar
          {...filterConfigs}
          isVisible={true}
          onClose={onCloseFilterModal}
        />
      )}
    </>
  );
}

export default withPermission(
  Users,
  permissions["user-management"].permissionSet.admin
);
