import { DataGrid } from "@mui/x-data-grid";;
import { tokens } from "src/theme";
import { Box, Typography, useTheme } from "@mui/material";
import { styled, } from '@mui/material/styles';
import "./index.css";
const PAGE_SIZE = 5;

const SERVER_OPTIONS = {
  useCursorPagination: true,
};
interface IDataTable {
  columns: any;
  data: any[];
  onCellClick?: Function;
  isLoading?: boolean;
  page?: number;
  pageSize?: number;
  disableColumnFilter?: boolean;
  rowCountState?: number;
  onPageChange?: (newPage: number) => void;
  onPageSizeChange?: (newPageSize: number) => void;
  disableColumnMenu?: boolean;
}

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .Mui-selected': {
    backgroundColor: '#f4f6f7 !important',
  },
}));

export const DataTable = ({
  columns,
  data,
  onCellClick,
  isLoading,
  page,
  rowCountState,
  pageSize,
  onPageChange,
  onPageSizeChange,
  disableColumnFilter = false,
  disableColumnMenu = false,
}: IDataTable) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  return (
      <Box m="40px 0 0 0" height="75vh">
        <CustomDataGrid
          // className="datatable"
          rows={data}
          columns={columns}
          onCellClick={e => {
            onCellClick?.(e);
          }}
          rowCount={rowCountState}
          loading={isLoading}
          rowsPerPageOptions={[10, 20, 30, 50]}
          pagination
          page={page}
          disableColumnFilter={disableColumnFilter}
          disableColumnMenu={disableColumnMenu}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={newPage => onPageChange(newPage)}
          onPageSizeChange={newPageSize => onPageSizeChange?.(newPageSize)} 
          sx={{
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#f4f6f7"
              // color: "red"
            },
           
          }}
        />
      </Box>
  );
};
