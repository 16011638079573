import { Box, Button, Popover, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { logger } from "src/services";
import _ from "lodash";
import { orderService } from "src/services/order.service";
import { useFormikContext } from "formik";
import { notifySuccess } from "src/generic-ui";
import {
  notifyError,
} from "src/generic-ui/ToastNotification";

interface IVariants {
  productStock: any;
  productStocks?: any;
  selectedItemVariantOptions?: any;
  isNewItem?: boolean;
  config?: any;
  submitForm?: any;
  productInfo?: any;
  mutate?: any;
}

export default function Variants({
  productStock,
  productStocks,
  selectedItemVariantOptions,
  isNewItem,
  config,
  productInfo,
  mutate
}: IVariants) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const { selectedVariants, setVariants, setProductStocks } = config;
  const [selectedProductVariantOpt, setSelectedProductVariantOpt] =
    useState("");
  const { values, setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    let variantObject = {};
    variantObject[productStock.variant] = "";
    setVariants(prev => {
      return { ...prev, ...variantObject };
    });
  }, [productStock.variant]);

  useEffect(() => {
    if (isNewItem) {
      setVariants(prev => {
        const label = productStock.variant;
        prev[label] = selectedProductVariantOpt;
        return {
          ...prev,
        };
      });
    } else {
      setVariants(prev => {
        const label = productStock.variant;
        prev[label] = selectedProductVariantOpt;
        return {
          ...prev,
        };
      });
    }
  }, [productStock, selectedProductVariantOpt]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    variantData,
  ) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setVariants({})
  };

  const handleOk = async (event: React.MouseEvent<HTMLButtonElement>) => {
    // call service
    const { productId, orderId, quantity } = productInfo;
    const selectedProductVariantsOpt = productStocks
      .map(values => values.data)
      .flat()
      .filter(val => val.isSelected === true)
      .map(val => val.id);
    const body = {
      updatedOrderItems: [
        {
          product: productId,
          quantity,
          productVariantOptions: selectedProductVariantsOpt,
        },
      ],
    };
    try {
      const res = await orderService.updateOrderModify(orderId, body);
      logger.log(res);
      notifySuccess("variant updated successfully");
    } catch (error) {
      logger.error(error);
      notifyError("variant update failed");
    }
    event.preventDefault();
    setAnchorEl(null);
  };

  const onHandleClick = (e, variantData = null) => {
    if (!isNewItem) {
      handleClick(e, variantData);
    } else {
      setSelectedProductVariantOpt(variantData.id);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="my-2 flex">
      <p className="mt-1 mr-2">{productStock.variant}</p>
      <div className="flex">
        {isNewItem ? (
          <>
            {productStock?.data?.map((variantData, index: number) => {
              const isSelected = variantData?.id === selectedProductVariantOpt;
              return (
                <div key={index}>
                  <button
                    type="button"
                    onClick={e => {
                      onHandleClick(e, variantData);
                    }}
                    className={classNames(
                      "border px-2 py-1 rounded-md mr-2",
                      isSelected ? "bg-indigo-500 text-white" : "",
                    )}
                  >
                    {variantData?.variantOption?.title}
                  </button>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {productStock?.data?.map((variantData, index: number) => {
              return (
                <div key={index}>
                  <button
                    type="button"
                    onClick={e => {
                      const filteredProductStock = productStocks?.find(
                        stock => stock.variant === productStock.variant,
                      );

                      const index = filteredProductStock.data.findIndex(
                        obj => obj.id === variantData.id,
                      );

                      filteredProductStock.data[index].isSelected = true;

                      filteredProductStock.data.forEach(obj => {
                        if (obj.id !== variantData.id) {
                          obj.isSelected = false;
                        }
                      });
                      onHandleClick(e);
                    }}
                    className={classNames(
                      "border px-2 py-1 rounded-md mr-2",
                      variantData.isSelected ? "bg-indigo-500 text-white" : "",
                    )}
                  >
                    {variantData?.variantOption?.title}
                  </button>
                </div>
              );
            })}
          </>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ paddingY: "1rem", paddingX: "1rem" }}>
            Do you want to change the variant options?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleOk}>Yes</Button>
          </Box>
        </Popover>
      </div>
    </div>
  );
}
