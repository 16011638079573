import EcomBadge from 'src/generic-ui/EcomBadge';
import { statusClassName } from 'src/helpers/utility/status-color-classnames';
import dateUtils from "src/helpers/utility/DateUtils";
import { replaceSpecialCharacter } from 'src/helpers/utility/convert-string';

export default function OrderBasicInfo({ orderDetails }: any) {
    const { address } = orderDetails;
    return (
        <div className='grid grid-cols-2 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 gap-4'>
            <div className="col-span-1">
                <span className='text-indigo-600 text-sm font-semibold'>Order info</span>
                <div className='mt-8'></div>
                <div className='flex my-4'>
                    <span className="font-semibold text-gray-600">Order Code</span>
                    <span className="ml-3">{orderDetails.code}</span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold text-gray-600">Order Status</span>
                    <span className="ml-3">
                        <EcomBadge classNames={statusClassName(orderDetails.orderStatus.toUpperCase())}>
                            {replaceSpecialCharacter(orderDetails.orderStatus.toLowerCase(), ' ')}
                        </EcomBadge>
                    </span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold text-gray-600">Payment Status</span>
                    <span className="ml-3">
                        <EcomBadge classNames={statusClassName(orderDetails.paymentStatus.toUpperCase())}>
                            {replaceSpecialCharacter(orderDetails.paymentStatus.toLowerCase(), ' ')}
                        </EcomBadge>
                    </span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold text-gray-600">Order At</span>
                    <span className='ml-3'>
                        {dateUtils.formatDateTime(orderDetails.createdAt, "MMM D, YYYY hh:mm a")}
                    </span>
                </div>
            </div>
            <div className="col-span-1">
                <span className='text-indigo-600 text-sm font-semibold'>Customer info</span>
                <div className='mt-8'></div>

                <div className='flex my-4'>
                    <span className="font-semibold text-gray-600">Name</span>
                    <span className='ml-3'>{orderDetails?.address?.customerName}</span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold text-gray-600">Phone Number</span>
                    <span className='ml-3'>{orderDetails?.address?.phoneNumber}</span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold text-gray-600">Email </span>
                    <span className='ml-3'>{orderDetails?.address?.customerEmail}</span>
                </div>
            </div>
            <div className="col-span-1">
                <span className='text-indigo-600 text-sm font-semibold'>Shipping info</span>
                <div className='mt-8'></div>

                <div className='flex my-4'>
                    <span className="font-semibold text-gray-600">Phone Number</span>
                    <span className='ml-3'>{orderDetails?.address?.phoneNumber}</span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold text-gray-600">Full address</span>
                    <span className='ml-3'>{address?.fullAddress}</span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold text-gray-600">City</span>
                    <span className='ml-3'>{address?.city?.title}</span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold text-gray-600">Area</span>
                    <span className='ml-3'>{address?.area?.title}</span>
                </div>
            </div>
        </div>
    )
}
