import React, { useState, useEffect } from "react";
import SideBarModal from "src/components/modals/SideBarModal";
import { IModals } from "src/types";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { CatalogSchema, CatalogSchemaType } from "src/schemas";
import { LoadingButton } from "@mui/lab";
import GalleryLayout from "src/components/gallery/GalleryLayout";
import Popover from "src/generic-ui/Popover";
import { useBrandDetails } from "src/hooks/catalogs/useBrandDetails";
import SuspenseLoader from "src/components/SuspenseLoader";
import { bannerService, logger } from "src/services";
import { brandService } from "src/services/brands.service";
import DeleteIcon from "@mui/icons-material/Delete";
import { useBannerDetails } from "src/hooks/cms/useBannerDetails";
import { notifyError, notifySuccess } from "src/generic-ui";

interface ISidebar extends IModals {
  selectedId?: string;
  onRefresh?: () => void;
}
export default function Sidebar({
  isVisible,
  onClose,
  selectedId,
  onRefresh,
}: ISidebar) {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [openGallery, setOpenGallery] = useState<boolean>(false);
  const [selectedImages, setImages] = useState<{
    images: any[];
  }>({
    images: [],
  });

  const { isLoading, data, mutate, error } = useBannerDetails(selectedId);

  useEffect(() => {
    if (openGallery === false && !!selectedImages?.images?.length) {
      setValues({
        ...values,
        image: selectedImages?.images[0]?.link || "",
      });
    }
  }, [selectedImages, openGallery]);

  const onSelectedImages = (key: string, value: any) => {
    setImages({
      ...selectedImages,
      [key]: value,
    });
  };

  const onSelectCategoryImage = () => {
    setOpenGallery(true);
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors: formikError,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      title: data?.title || "",
      image: data?.image || "",
      description: data?.description || "",
    },
    validationSchema: CatalogSchema,
    enableReinitialize: true,
    onSubmit(values) {
      onEditSubmit(values);
    },
  });

  if (isLoading) {
    return <SuspenseLoader />;
  }

  const onEditSubmit = async (values: CatalogSchemaType) => {
    try {
      setSubmitting(true);
      await bannerService.updateBanner({ ...values, id: selectedId });
      onRefresh?.();
      onClose();
      notifySuccess("Banner updated successfully")
    } catch (error) {
      logger.error(error);
      notifyError(error.message)
    } finally {
      setSubmitting(false);
    }
  };

  const onDelete = async () => {
    try {
      setSubmitting(true);
      await bannerService.deleteBanner(selectedId);
      notifySuccess("Banner deleted successfully")
      onRefresh?.();
      onClose();
    } catch (error) {
      logger.error(error);
      notifyError(error.message)
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <SideBarModal
        isVisible={isVisible}
        onClose={onClose}
        title={!selectedId ? "Add New" : "Edit Item"}
        renderEndIcon={
          !!selectedId && (
            <IconButton onClick={onDelete} disabled={isSubmitting}>
              <DeleteIcon className="text-red-400" />
            </IconButton>
          )
        }
      >
        <Box paddingX={2}>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <form onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Title"
                name="title"
                type="text"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {formikError.title && touched.title ? (
                <p className="text-red-600">{formikError.title}</p>
              ) : null}
              <TextField
                margin="normal"
                fullWidth
                label="Description"
                name="description"
                type="text"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {formikError.description && touched.description ? (
                <p className="text-red-600">{formikError.description}</p>
              ) : null}

              <TextField
                margin="normal"
                fullWidth
                label="Image"
                name="image"
                type="text"
                onClick={onSelectCategoryImage}
                value={values.image}
                onBlur={handleBlur}
              />

              <div className="pb-10">
                <p className="text-xs py-2">image preview</p>
                {values.image ? <><a href={values.image} target={values.image ? "_blank" : ""}><img src={values.image} className="h-50 w-70" /></a></> : <div className="border-2 border-dashed px-8 py-8 text-center">
                  No image for preview</div>}
              </div>

              <LoadingButton
                loadingIndicator="Loading..."
                type="submit"
                loading={isSubmitting}
                color="primary"
                className={"bg-indigo-600 hover:bg-indigo-800 mt-2 text-white"}
              >
                Submit
              </LoadingButton>
            </form>
          </Grid>
        </Box>
      </SideBarModal>
      <Popover
        title=""
        width={1000}
        height={800}
        open={openGallery}
        setOpen={setOpenGallery}
        onClose={() => {
          setOpenGallery(false);
        }}
      >
        <GalleryLayout
          isOpenFromModal
          setFieldValue={onSelectedImages}
          modalConfig={{ open: openGallery, setOpen: setOpenGallery }}
        />
      </Popover>
    </>
  );
}
