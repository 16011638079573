import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';

class DateUtils {
  formatDateTime = (date: string, format = "MMM D, YYYY") => {
    return dayjs(date).format(format);
  };

  formatDateAsFromNow = (date: string) => {
    dayjs.extend(relativeTime);
    return dayjs(date).fromNow()
  }
}
const dateUtils = new DateUtils();
export default dateUtils;
