import React, { useEffect, useRef, useState } from "react";
import { FormControl, InputLabel, Select, SelectProps } from "@mui/material";
import ConfirmationModal from "src/components/modals/ConfirmationModal";
import { logger } from "src/services";

interface ICustomDropDown extends Omit<SelectProps, "onChange"> {
  onChange?: (value: string) => void;
  hasConfirmation?: boolean;
}
export default function CustomDropDown({
  onChange: handleOnChange,
  children,
  value: initialValue,
  label,
  hasConfirmation = false,
  ...props
}: ICustomDropDown) {
  const [value, setValue] = useState<typeof initialValue>("");
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const onToggleModal = () => setModalVisible(prev => !prev);
  const selectedValue = useRef<string | null>(null);

  useEffect(() => {
    if (value !== initialValue) {
      setValue(initialValue);
    }
  }, [initialValue, value]);

  const onChange = e => {
    const { name, value } = e.target;

    switch (hasConfirmation) {
      case true:
        selectedValue.current = value;
        onToggleModal();
        return;
      default:
        setChangeValue(value);
    }
  };

  const setChangeValue = async (value: string) => {
    handleOnChange?.(value);

    setValue(value);
  };

  const onCloseModal = () => {
    onToggleModal();
  };

  const onDelete = () => {
    const currentValue = selectedValue?.current;
    setChangeValue(currentValue);
  };
  return (
    <>
      <FormControl fullWidth>
        {!!label && (
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        )}
        <Select label={label} {...props} value={value} onChange={onChange}>
          {children}
        </Select>
      </FormControl>

      {isModalVisible && (
        <ConfirmationModal
          isVisible={isModalVisible}
          onClose={onCloseModal}
          onOkPress={() => {
            onDelete();
          }}
        />
      )}
    </>
  );
}
