import { Pagination } from '@mui/material';
import { useEffect, useState } from 'react'
import GalleryEffect from 'src/generic-ui/shimmer-effect/GalleryEffect';
import { useFiles } from 'src/hooks/file-storage/useFiles';
import ImageFiles from './ImageFiles';

export default function GalleryImagesLayout({ imageConfig }: any) {
    const { folder } = imageConfig;
    const [page, setPage] = useState(1);
    const LIMIT = 20;
    const { files, isLoading, meta, mutate, error } = useFiles({ page, limit: LIMIT, folder });
    const [paginationCount, setPaginationCount] = useState<number>();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        setPaginationCount(Number(Math.ceil(meta?.total / meta?.limit) || 0))
    }, [meta])

    if (isLoading) return <GalleryEffect />;

    return (
        <>
            <ImageFiles files={files} imageFileConfig={{ folder, mutate}} />
            <div className="flex justify-center mb-10 mt-5">
                <Pagination
                    count={paginationCount}
                    page={page}
                    size="small"
                    onChange={handleChange}
                />
            </div>
        </>
    )
}
