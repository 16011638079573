import { Box, Button, Grid, MenuItem } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import SideBarModal from "src/components/modals/SideBarModal";
import {
  ACTIVE_DROPDOWN,
  FEATURED_DROPDOWN,
  NEW_ARRIVAL_DROPDOWN,
  STOCK_DROPDOWN,
} from "src/constants/globalConstants";
import CustomDropDown from "src/generic-ui/CustomDropDown";
import { IModals } from "src/types";

interface IFilterSidebar extends IModals {
  brands: any[];
  categories: any[];
  subCategories: any[];
  departments: any[];
  showResetAllButton: any;
  onReset: () => void;
  setPageSize: Dispatch<SetStateAction<number>>;

  selectedBrandId: string;
  setBrandId: Dispatch<SetStateAction<string>>;

  setDepartMentId: Dispatch<SetStateAction<string>>;

  selectedDepartMentId: string;
  selectedCategoryId: string;

  setCategoryId: Dispatch<SetStateAction<string>>;

  selectedSubCategoryId: string;
  setSubCategoryId: Dispatch<SetStateAction<string>>;

  selectedActiveStatus: string;
  setActiveStatus: Dispatch<SetStateAction<string>>;

  selectedNewArrivalStatus: string;
  setNewArrivalStatus: Dispatch<SetStateAction<string>>;

  selectedStockOutStatus: string;
  setStockOutStatus: Dispatch<SetStateAction<string>>;

  setFeatureStatus: Dispatch<SetStateAction<string>>;

  selectedFeatureStatus: string;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;

  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  pageSize: number;
  [key: string]: any;
}
export default function FilterSidebar({
  isVisible,
  onClose,
  brands,
  isLoading,
  categories,
  subCategories,
  departments,
  showResetAllButton,
  onReset,
  selectedBrandId,
  setBrandId,
  setDepartMentId,
  selectedDepartMentId,
  selectedCategoryId,
  setCategoryId,
  selectedSubCategoryId,
  setSubCategoryId,
  selectedActiveStatus,
  setActiveStatus,
  selectedNewArrivalStatus,
  setNewArrivalStatus,
  selectedStockOutStatus,
  setStockOutStatus,
  setFeatureStatus,
  selectedFeatureStatus,
}: IFilterSidebar) {
  return (
    <SideBarModal
      isVisible={isVisible}
      onClose={onClose}
      left={70}
      allowBackdrop={false}
      title={"Filter Product"}
    >
      <Box sx={{ display: "flex", flexDirection: "column", margin: "1rem" }}>
        <Box
          sx={{
            background: "white",
            position: "sticky",
            top: 0,
            bottom: 20,
            paddingTop: "1rem",
            paddingBottom: "1rem",
            zIndex: 5,
          }}
        >
          <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
            <Grid item xs={6}>
              <CustomDropDown
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                label="Brand"
                margin="dense"
                placeholder="Select Brand"
                onChange={value => setBrandId(value)}
                value={selectedBrandId}
                name="brand"
              >
                {brands.map((brand, index: number) => {
                  return (
                    <MenuItem value={brand.id} key={index}>
                      {brand.title}
                    </MenuItem>
                  );
                })}
              </CustomDropDown>
            </Grid>
            <Grid item xs={6}>
              <CustomDropDown
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                label="Department"
                margin="dense"
                placeholder="Select Department"
                onChange={value => {
                  setDepartMentId(value);
                  setCategoryId("");
                  setSubCategoryId("");
                }}
                value={selectedDepartMentId}
                name="department"
              >
                {departments.map((department, index: number) => {
                  return (
                    <MenuItem value={department.id} key={index}>
                      {department.title}
                    </MenuItem>
                  );
                })}
              </CustomDropDown>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
            <Grid item xs={6}>
              <CustomDropDown
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                label="Category"
                margin="dense"
                placeholder="Select Category"
                onChange={value => {
                  setCategoryId(value);
                  setSubCategoryId("");
                }}
                value={selectedCategoryId}
                name="category"
              >
                {categories.map((category, index: number) => {
                  return (
                    <MenuItem value={category.id} key={index}>
                      {category.title}
                    </MenuItem>
                  );
                })}
              </CustomDropDown>
            </Grid>
            <Grid item xs={6}>
              <CustomDropDown
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                label="Sub Category"
                margin="dense"
                placeholder="Select Sub Category"
                onChange={value => setSubCategoryId(value)}
                value={selectedSubCategoryId}
                name="subCategory"
              >
                {subCategories.map((subCategory, index: number) => {
                  return (
                    <MenuItem value={subCategory.id} key={index}>
                      {subCategory.title}
                    </MenuItem>
                  );
                })}
              </CustomDropDown>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
            <Grid item xs={3}>
              <CustomDropDown
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                label="Featured"
                margin="dense"
                placeholder="Featured"
                onChange={value => {
                  setFeatureStatus(value);
                }}
                value={selectedFeatureStatus}
                name="isFeatured"
              >
                {FEATURED_DROPDOWN.map((status, index: number) => {
                  return (
                    <MenuItem value={status?.value} key={index}>
                      {status?.label}
                    </MenuItem>
                  );
                })}
              </CustomDropDown>
            </Grid>
            <Grid item xs={3}>
              <CustomDropDown
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                label="Active"
                margin="dense"
                placeholder="Active"
                onChange={value => {
                  setActiveStatus(value);
                }}
                value={selectedActiveStatus}
                name="isActive"
              >
                {ACTIVE_DROPDOWN.map((status, index: number) => {
                  return (
                    <MenuItem value={status?.value} key={index}>
                      {status?.label}
                    </MenuItem>
                  );
                })}
              </CustomDropDown>
            </Grid>
            <Grid item xs={3}>
              <CustomDropDown
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                label="Stock Status"
                margin="dense"
                placeholder="Stock Status"
                onChange={value => {
                  setStockOutStatus(value);
                }}
                value={selectedStockOutStatus}
                name="isStockOut"
              >
                {STOCK_DROPDOWN.map((status, index: number) => {
                  return (
                    <MenuItem value={status?.value} key={index}>
                      {status?.label}
                    </MenuItem>
                  );
                })}
              </CustomDropDown>
            </Grid>
            <Grid item xs={3}>
              <CustomDropDown
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                label="New Arrival"
                margin="dense"
                placeholder="New Arrival"
                onChange={value => {
                  setNewArrivalStatus(value);
                }}
                value={selectedNewArrivalStatus}
                name="isNewArrival"
              >
                {NEW_ARRIVAL_DROPDOWN.map((status, index: number) => {
                  return (
                    <MenuItem value={status?.value} key={index}>
                      {status?.label}
                    </MenuItem>
                  );
                })}
              </CustomDropDown>
            </Grid>
          </Grid>

          {!isLoading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginTop: "1rem",
              }}
            >
              {showResetAllButton ? (
                <Button
                  className="bg-red-400 hover:bg-red-800 text-white"
                  onClick={e => onReset()}
                >
                  Reset All
                </Button>
              ) : (
                <div></div>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </SideBarModal>
  );
}
