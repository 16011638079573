import { QueryParamsType } from "src/types/query-params";
import { useSwrRequest } from "../useSwrRequest";
import qs from "qs";
import useRequest from "../useRequest";
import { ISpecialCategory } from "src/types";

export const useSpecialCategories = (
  query: Record<string, QueryParamsType>,
) => {
  const url = `/special-categories?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);
  return {
    specialCategories: (data as Array<ISpecialCategory>) || [],
    mutate,
    isLoading,
    error,
    ...rest,
  };
};
