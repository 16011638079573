import React, { useState } from 'react'
import BasicTable from 'src/generic-ui/BasicTable'
import CustomPagination from 'src/generic-ui/CustomPagination/CustomPagination';
import Spinner from 'src/generic-ui/Spinner';
import { useMostViewdProducts } from 'src/hooks/dashboard/useMostViewedProducts';
import { QueryParamsType } from 'src/types';
const PAGE_LIMIT = 5;
export default function MostViewedProduct() {
    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
    const query: Record<string, QueryParamsType> = {};
    query["limit"] = pageLimit;
    query["page"] = page;
    const { data, isLoading, response } = useMostViewdProducts(query);

    if (isLoading) return <Spinner />;

    const columns = [
        {
            title: "Code",
            accessor: "",
            render: function ({ ...data }: any) {
                return (
                    <>
                        {data?.product?.code}
                    </>
                )
            }
        },
        {
            title: "name",
            accessor: "",
            render: function ({ ...data }: any) {
                return (
                    <>
                        {data?.product?.title}
                    </>
                )
            }
        }, 
        {
            title: "Total viewed",
            accessor: 'totalViewed'
        },
    ]

    const res: any = response;
    const meta = res?.meta;

    return (
        <div className='mt-10'>
            <p className='text-base font-semibold'>Most viewed products</p>
            <BasicTable tableHeaders={columns} tableRows={data} renderTableFooter={
                <div className='flex justify-center my-3'>
                    <CustomPagination
                        onPageChange={(nexPageNumber: number) => {
                            setPage(nexPageNumber);
                        }}
                        totalCount={meta?.total}
                        pageSize={PAGE_LIMIT}
                        currentPage={page}
                    />
                </div>
            } />
        </div>
    )
}
