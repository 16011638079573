import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  DEBOUNCE_DELAY,
  DEFAULT_PAGE_SIZE,
} from "src/constants/globalConstants";
import { DataTable } from "src/generic-ui/DataTable";
import { useDebounce } from "src/hooks/useDebounce";
import { IArea } from "src/types";
import withPermission from "src/components/permission/withPermission";
import { permissions } from "src/constants/permissions";
import { Helmet } from "react-helmet-async";
import ActionBar from "src/generic-ui/ActionBar";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useSubscription } from "src/hooks/subscription/useSubscription";
import dateUtils from "src/helpers/utility/DateUtils";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router";

const ColValue = ({ value = "" }: { value: any }) => {
  return (
    <Box display="flex" borderRadius="4px">
      {value}
    </Box>
  );
};
function Subscriptions() {
  const navigate = useNavigate();
  const [isSidebarVisible, setVisible] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [selectedId, setSelected] = useState<string | null>(null);
  const debouncedSearchTerm = useDebounce({
    searchQuery: searchTerm,
    delay: DEBOUNCE_DELAY,
  });
  const { isLoading, subscriptions, mutate, error, meta } = useSubscription({
    limit: pageSize,
    page: page + 1,
    searchTerm: debouncedSearchTerm,
  });
  const onToggleSideBar = () => setVisible((prev) => !prev);

  const [rowCountState, setRowCountState] = useState(meta?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.total !== undefined ? meta?.total : prevRowCountState
    );
  }, [meta, setRowCountState]);

  const onClose = () => {
    onToggleSideBar();
    setSelected(null);
  };

  const handleOnCellClick = ({ row }: { row: IArea }) => {
    setSelected(row.id);
    onToggleSideBar();
  };

  const onRefresh = () => {
    mutate();
  };

  const columns: any = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <ColValue
          value={dateUtils.formatDateTime(row.createdAt, "MMM D, YYYY hh:mm a")}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <IconButton
          onClick={() => {
            handleOnCellClick({ row });
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  if (error) {
    return <div>Something Went Wrong</div>;
  }

  const onReset = () => {
    setCity("");
    setSearchTerm("");
  };

  const showResetAllButton = useMemo(() => {
    return !!debouncedSearchTerm || !!city;
  }, [debouncedSearchTerm, city]);

  return (
    <>
      <Helmet>
        <title>AAAF LTD - subscription</title>
      </Helmet>

      <ActionBar title="Subscription">
        <div className="flex">
          <input
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className=" block  rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
          />

          <Tooltip arrow title="create">
            <IconButton
              className="bg-indigo-500 hover:bg-indigo-600 text-white ml-2 p-1.5"
              onClick={() => onToggleSideBar()}
            >
              <AddCircleIcon fontSize="medium" className="text-white" />
            </IconButton>
          </Tooltip>

          {showResetAllButton && (
            <Tooltip arrow title="reset filter">
              <IconButton
                className="bg-red-500 hover:bg-red-600 text-white ml-2 p-1.5"
                onClick={() => {
                  onReset();
                }}
              >
                <RestartAltIcon fontSize="medium" className="text-white" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </ActionBar>
      <DataTable
        columns={columns}
        data={subscriptions}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        disableColumnMenu={true}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCountState={rowCountState}
      />
      {isSidebarVisible && (
        <Sidebar
          isVisible={isSidebarVisible}
          onClose={onClose}
          selectedId={selectedId}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
}

export default withPermission(
  Subscriptions,
  permissions["marketing"].permissionSet.subscription.view
);
