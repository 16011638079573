import dateUtils from 'src/helpers/utility/DateUtils'
import StarIcon from '@mui/icons-material/Star';
export default function BasicInfo({ productDetails }) {
    return (
        <div className='grid grid-cols-2 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 gap-4'>
            <div className="col-span-1">
                <div className='mt-8'></div>
                <div className='flex my-4'>
                    <span className="font-semibold">Product Code</span>
                    <span className="ml-3">{productDetails?.code}</span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold">Product name</span>
                    <span className="ml-3">
                        {productDetails?.title}
                    </span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold">lifting price</span>
                    <span className="ml-3">
                        ৳{productDetails?.liftingPrice}
                    </span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold">lifting price vat</span>
                    <span className='ml-3'>
                        ৳{productDetails?.liftingPriceVat}

                    </span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold">Created At</span>
                    <span className='ml-3'>
                        {dateUtils.formatDateTime(productDetails.createdAt, "MMM D, YYYY")}
                    </span>
                </div>
            </div>
            <div className="col-span-1">
                <div className='mt-8'></div>
                <div className='flex my-4'>
                    <span className="font-semibold">Product unit</span>
                    <span className="ml-3">
                        {productDetails?.unit ? productDetails?.unit : "-"}
                    </span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold">stock</span>
                    <span className="ml-3">{productDetails?.stock}pcs</span>
                </div>

                <div className='flex my-4'>
                    <span className="font-semibold">mrp</span>
                    <span className="ml-3">
                        ৳{productDetails?.mrp}
                    </span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold">mrp vat</span>
                    <span className='ml-3'>
                        ৳{productDetails?.mrpVat}
                    </span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold">old price</span>
                    <span className='ml-3'>
                        ৳{productDetails?.oldPrice}
                    </span>
                </div>
            </div>
            <div className="col-span-1">
                <div className='mt-8'></div>
                <div className='flex my-4'>
                    <span className="font-semibold">new price</span>
                    <span className='ml-3'>
                        ৳{productDetails?.newPrice}
                    </span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold">max price</span>
                    <span className='ml-3'>
                        ৳{productDetails?.maxPrice}
                    </span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold">min price</span>
                    <span className='ml-3'>
                        ৳{productDetails?.minPrice}
                    </span>
                </div>
                <div className='flex my-4'>
                    <span className="font-semibold">total rating</span>
                    <span className='ml-3'>
                        <StarIcon className='w-5 h-5 mb-1' />

                        {productDetails?.stats && productDetails?.stats?.totalRating > 0 ? productDetails?.stats?.totalRating : "-"}
                    </span>

                </div>
            </div>
        </div>
    )
}
