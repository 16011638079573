import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "orders",
  initialState: [],
  reducers: {
    setOrders: (state, action) => {
        return [...action.payload]
    }
  },
});

// this is for dispatch
export const { setOrders } = orderSlice.actions;

// this is for configureStore
export default orderSlice.reducer;
