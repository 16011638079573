import React from "react";
import { Box, Modal, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

interface IConfirmationModal {
  isVisible: boolean;
  onClose: () => void;
  title?: string;
  onOkPress: () => void;
  isLoading?: boolean;
}
export default function ConfirmationModal({
  isVisible,
  onClose,
  onOkPress,
  isLoading = false,
  title = "Are you sure you want to proceed?",
}: IConfirmationModal) {
  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          paddingY: 2,
          paddingX: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
        </Box>

        <Box sx={{ justifyContent: "end", display: "flex" }} onClick={onClose}>
          <Button
            className="bg-gray-400 hover:bg-gray-800 mt-2 text-white"
            disabled={isLoading}
          >
            Close
          </Button>
          <LoadingButton
            loadingIndicator="Loading."
            className="bg-red-600 hover:bg-red-800 mt-2 text-white ml-2"
            onClick={onOkPress}
            loading={isLoading}
          >
            Yes
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
