import { createContext, Dispatch, SetStateAction } from 'react';
interface GalleryContext {
  isOpenFromModal: boolean;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  setFieldValue?:any;
};

export const GalleryContext = createContext<GalleryContext>({ isOpenFromModal: false});

