import useRequest from "../useRequest";

export const useFiles = ({
  page = 1,
  limit = 15,
  folder = null
}: {
  page: number;
  limit: number;
  folder?: string;
}) => {
  const url = `/files?limit=${limit}&page=${page}&folder=${folder}`;
  const { data, ...rest } = useRequest(url, url);
  return {
    files: (data as any[]) || [],
    ...rest,
  };
};
