import { QueryParamsType } from "src/types/query-params";
import { useSwrRequest } from "../useSwrRequest";
import qs from "qs";
import useRequest from "../useRequest";

export const useSubCategories = (query: Record<string, QueryParamsType>) => {
  const url = `/sub-categories?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);
  return {
    subCategories: (data as any[]) || [],
    mutate,
    isLoading,
    error,
    ...rest,
  };
};
