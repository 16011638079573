import { Fade, FormControlLabel, IconButton, Switch } from '@mui/material';
import React, { ChangeEvent, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { PhotoCamera } from '@mui/icons-material';
import Popover from 'src/generic-ui/Popover';
import classNames from 'classnames';
import { useFormikContext } from 'formik';


export const AvatarInput = styled('div')`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  img {
    width: 186px;
    height: 186px;
    object-fit: cover;
    border-radius: 50%;
  }
  .circle {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }
  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
      color: #f4ede8;
    }
    &:hover {
      background: blue;
    }
  }
`;


export default function ImageCard({ imageObject, config }: any) {
    const { imageBoxes, setImageBoxes, selectedForThumbnail, setSelectedForThumbnail } = config;
    const [open, setOpen] = useState<boolean>(false);
    const { setFieldValue } = useFormikContext<any>();
    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setImageBoxes((prev) => prev.map(obj => {
                if(obj.id === imageObject.id) {
                    obj.link = URL.createObjectURL(e.target.files[0])
                    obj.binaryImageData = e.target.files[0]
                }
                return obj;
            }))
        }
    };

    return (
        <div className={classNames("border rounded-md px-10 py-5 my-4 cursor-pointer", imageObject.isThumb ? "border-2 border-indigo-600" : null)}>
            <div className="flex">
                <img src={imageObject.link} alt="Avatar Placeholder" className="h-10 w-10 rounded-full cursor-pointer" onClick={() => {
                    setOpen(true)
                }} />
                <FormControlLabel
                    control={
                        <Switch
                            checked={imageObject.isThumb ? true : false} name="manageCustomInventory"
                            onChange={(event) => {
                                const copyImageBoxes = [...imageBoxes];
                                const modifiedImageBoxes = copyImageBoxes.map(imageBox => {
                                    if (imageObject.id === imageBox.id) {
                                        imageBox.isThumb = !imageBox.isThumb;
                                    } else {
                                        imageBox.isThumb = false;
                                    }
                                    return imageBox;
                                });
                                setFieldValue('images', modifiedImageBoxes)
                            }}
                        />
                    }
                    label="set as thumbnail"
                    className="ml-6"
                />

                <IconButton aria-label="delete" size="small" className='ml-auto' onClick={() => {
                    const modifiedStack = imageBoxes.filter((el) => el.id !== imageObject.id)
                    setImageBoxes(modifiedStack)
                }}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </div>

            <Popover
                title=""
                width={600}
                open={open}
                setOpen={setOpen}
                onClose={() => { setOpen(false) }}
            >
                <Fade in={open} timeout={500} className="h-80 w-80 object-center mx-auto">
                    <img
                        src={imageObject.link}
                        alt="asd"
                        className=""
                    />
                </Fade>
            </Popover>
        </div>
    )
}
