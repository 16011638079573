export const foldersTree = [
    {
        label: 'others',
        node: "1"
    },
    {
        label: 'products',
        node: "2"
    },
    {
        label: 'catalogs',
        node: "3"
    },
]