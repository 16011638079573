import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  DEBOUNCE_DELAY,
  DEFAULT_PAGE_SIZE,
} from "src/constants/globalConstants";
import { DataTable } from "src/generic-ui/DataTable";
import { useDebounce } from "src/hooks/useDebounce";
import { useUsers } from "src/hooks/user/useUsers";
import { QueryParamsType } from "src/types/query-params";
import { IUserDetails } from "src/types";
import { IRole } from "src/types/acl";
import { logger, userService } from "src/services";
import Switch from "src/generic-ui/Switch";
import { detectSuperAdmin } from "src/helpers/utility/special-function";
import withPermission from "src/components/permission/withPermission";
import { permissions } from "src/constants/permissions";
import ActionBar from "src/generic-ui/ActionBar";
import { useRole } from "src/hooks/acl/role/useRole";
import { useNavigate } from "react-router";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

function Customers() {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const navigate = useNavigate();
  const {
    roles,
    isLoading: isLoadingRoles,
    mutate: mutateRoles,
  } = useRole({
    limit: 100,
    page: 1,
  });

  const customer = roles?.find(
    (role) => role.title.toLowerCase() === "customer"
  );

  const debouncedSearchTerm = useDebounce({
    searchQuery: searchTerm,
    delay: DEBOUNCE_DELAY,
  });

  const json = JSON.stringify([customer?.id]);

  const query: Record<string, QueryParamsType> = {
    limit: pageSize,
    page: page + 1,
    roles: json,
  };
  if (!!debouncedSearchTerm) query["searchTerm"] = debouncedSearchTerm;

  const { isLoading, users, mutate, error, meta } = useUsers(query);

  const [rowCountState, setRowCountState] = useState(meta?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.total !== undefined ? meta?.total : prevRowCountState
    );
  }, [meta, setRowCountState]);

  const onRefresh = () => {
    mutate();
  };

  const updateElement = async ({
    keyName,
    id,
    value,
  }: {
    keyName: string;
    id: string;
    value: any;
  }) => {
    try {
      await userService.updateUser(id, { [keyName]: value });
    } catch (error) {
      logger.error(error);
    } finally {
      onRefresh();
    }
  };

  const showResetAllButton = useMemo(() => {
    return !!debouncedSearchTerm;
  }, [debouncedSearchTerm]);

  const onReset = () => {
    setSearchTerm("");
  };

  const columns: any = [
    {
      field: "firstName",
      headerName: "Full name",
      flex: 1,
      renderCell: ({ row }: { row: IUserDetails }) => (
        <>
          {row.firstName || row.lastName
            ? row.firstName + " " + row.lastName
            : "-"}
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      renderCell: ({ row }: { row: IUserDetails }) => (
        <>{row.email ? row.email : "-"}</>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      flex: 1,
      renderCell: ({ row }: { row: IUserDetails }) => (
        <>{row.phoneNumber ? row.phoneNumber : "-"}</>
      ),
    },
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      renderCell: ({ row }: { row: IUserDetails }) => {
        const isSuperAdmin = detectSuperAdmin(row);

        return (
          <>
            {isSuperAdmin ? null : (
              <Switch
                isChecked={!!row.isActive}
                showPopOver={true}
                onClick={(isChecked: boolean) =>
                  updateElement({
                    keyName: "isActive",
                    value: isChecked,
                    id: row.id,
                  })
                }
              />
            )}
          </>
        );
      },
    },
    {
      field: "role",
      headerName: "Roles",
      flex: 2,
      renderCell: ({ row }: { row: IUserDetails }) => (
        <>
          {row.roles.map((role: IRole, index: number) => {
            return (
              <div key={index} className="border rounded-md px-2 py-1 mr-2">
                {role?.title}
              </div>
            );
          })}
        </>
      ),
    },
  ];
  if (error) {
    return <div>Something Went Wrong</div>;
  }

  const modifiedUsers = users.filter(
    (user) => !user.roles.some((role) => role.title === "*")
  );

  return (
    <>
      <Helmet>
        <title>AAAF LTD - customers</title>
      </Helmet>
      <ActionBar title="Customer">
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <input
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className=" block  rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
          />

          {showResetAllButton && (
            <Tooltip arrow title="reset filter">
              <IconButton
                className="bg-red-500 hover:bg-red-600 text-white ml-2 p-1.5"
                onClick={() => {
                  onReset();
                }}
              >
                <RestartAltIcon fontSize="medium" className="text-white" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </ActionBar>
      <DataTable
        columns={columns}
        data={modifiedUsers}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        disableColumnMenu={true}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCountState={rowCountState}
      />
    </>
  );
}

export default withPermission(
  Customers,
  permissions["user-management"].permissionSet.admin
);
