import { LoadingButton } from '@mui/lab';
import { Autocomplete, TextField } from '@mui/material';
import classNames from 'classnames';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { notifyError, notifySuccess } from 'src/generic-ui';
import { logger } from 'src/services';
import { orderService } from 'src/services/order.service';

interface IUpdateCoupon {
    orderId: string;
    mutate: any;
    status: {
        paymentStatus: string;
        orderStatus: string;
    }
}
export default function UpdateCoupon({ orderId, mutate, status }: IUpdateCoupon) {
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors: formikError,
        touched,
        setValues,
    } = useFormik({
        initialValues: {
            coupon: {
                couponCode: ""
            }
        },
        async onSubmit(values, action) {
            setSubmitting(true);
            try {
                await orderService.updateOrderModify(orderId, values);
                mutate()
                notifySuccess("New coupon added successfully");
                action.resetForm()
            }
            catch (error) {
                logger.error(error)
                notifyError(error.message)
            }
            finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <>
            {status.paymentStatus === "PAID" || status.orderStatus === "DELIVERED" ? <>
                <div className='p-5 font-semibold'>You can't add coupon code</div>
            </> : <form onSubmit={handleSubmit}>
                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    <TextField
                        className="w-full"
                        type="text"
                        name="coupon.couponCode"
                        onChange={handleChange}
                        value={values.coupon.couponCode}
                        placeholder='coupon code'
                    />
                </div>

                <LoadingButton
                    loadingIndicator="Loading..."
                    type="submit"
                    loading={isSubmitting}
                    color="primary"
                    className={classNames(
                        "bg-indigo-600 hover:bg-indigo-800 mt-2 text-white",
                    )}
                >
                    Submit
                </LoadingButton>
            </form>}
        </>
    )
}
