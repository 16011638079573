import React from 'react'
import BreadCrumbs from './BreadCrumbs'

interface IActionBar {
    title: string;
    children?: React.ReactNode;
    hideBreadcrumbs?: boolean;
}

export default function ActionBar({ title, children, hideBreadcrumbs }: IActionBar) {
    return (
        <>
            <div className='flex my-5'>
                {!hideBreadcrumbs && <BreadCrumbs />}
            </div>
            <div className='flex'>
                <div className='text-2xl'>
                    {title}
                </div>
                <div className='ml-auto'>
                    {children}
                </div>
            </div>
        </>
    )
}
