export const modules={
    toolbar: [
      [{ header: [1,2,3,4,5,6, false] }],
      [{ font: [] }],
      [{ size: []}],
      ["bold", "italic", "underline", "strike", "blockquote"],
      ["code-block"],
      [
        { list: "ordered"},
        { list: "bullet"},
        { indent: "-1"},
        { indent: "+1"},
      ],
      ["link", "image", "video"]
    ]
  }