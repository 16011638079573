import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from "react";
import { authService, logger } from "src/services";
import { IUser } from "src/types";

interface IAuthResponse {
  accessToken: string;
  refreshToken: string;
  user: IUser;
}
export interface IAuthContext {
  user: IUser | null;
  setUser: (user: IUser | null) => void;

  accessToken: string;
  refreshToken: string;
  permissionToken: string;
  signUp: (payload: any) => void;
  login: (email: string, password: string) => void;
  logout: () => void;
  setAuth: (authRes: IAuthResponse) => void;
  isLoggedIn: boolean;
}

const DefaultValues: IAuthContext = {
  user: null,
  setUser: () => null,
  refreshToken: null,
  accessToken: null,
  permissionToken: null,
  signUp: () => null,
  login: () => null,
  logout: () => null,
  setAuth: () => null,
  isLoggedIn: false,
};

const AuthContext = createContext<IAuthContext>(DefaultValues);

export function useAuth() {
  return useContext(AuthContext);
}
interface IProps {
  children: ReactNode;
}

export function AuthProvider({ children }: IProps) {
  const [user, setUser] = useState<IUser | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [permissionToken, setPermissionToken] = useState<string | null>(null);
  useEffect(() => {
    const { refreshToken, accessToken, user, permissionToken } = authService.getUserInfo();
    setUserAuthData({ refreshToken, accessToken, user, permissionToken });
  }, []);

  const setUserAuthData = ({
    accessToken,
    refreshToken,
    user,
    permissionToken
  }: {
    refreshToken: string;
    accessToken: string;
    user: IUser;
    permissionToken: string;
  }) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setUser(user);
    setPermissionToken(permissionToken)
  };

  const login = async (email: string, password: string) => {
    const data = await authService.login({
      email,
      password,
    });
    const { refreshToken, accessToken, user, permissionToken } = data;

    setUserAuthData({ refreshToken, accessToken, user, permissionToken });
  };

  const logout = async () => {
    localStorage.clear();
  };
  const signUp = async () => {};
  const setAuth = async () => {};
  const value = {
    user,
    setUser,
    login,
    logout,
    refreshToken,
    accessToken,
    permissionToken,
    signUp,
    setAuth,
    isLoggedIn: !!user && !!refreshToken && !!accessToken && !!permissionToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
