import { FC, useState, createContext } from 'react';
type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  showSidebar: boolean;
  hideSidebar: () => void;
  toggleXLSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const hideSidebar = () => {
    setShowSidebar(false)
  }

  const toggleXLSidebar = () => {
    setShowSidebar(!showSidebar)
  }

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, closeSidebar, showSidebar, hideSidebar, toggleXLSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
