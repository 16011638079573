import {
  LOGIN,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_VERIFY,
} from "src/constants/apis";
import { apiPost } from "src/helpers/axios/config";
import {
  IAuthResponse,
  IResetPasswordVerifyRequestPayload,
  IUser,
} from "src/types";
import decode from "jwt-decode";

class AuthService {
  storeUserInfo({
    user,
    refreshToken,
    accessToken,
    permissionToken,
  }: {
    refreshToken: string;
    accessToken: string;
    user: IUser;
    permissionToken: string;
  }) {
    const authData = {
      user,
      refreshToken,
      accessToken,
      permissionToken,
    };
    localStorage.setItem("auth", JSON.stringify(authData));
  }

  getUserInfo(): {
    refreshToken: string;
    accessToken: string;
    user: IUser;
    permissionToken: string;
  } {
    const authLocalStorage: string = localStorage.getItem("auth");
    const authData: any = !!authLocalStorage
      ? JSON.parse(authLocalStorage)
      : null;
    return {
      refreshToken: authData?.refreshToken,
      accessToken: authData?.accessToken,
      user: !!authData?.user ? authData?.user : null,
      permissionToken: authData?.permissionToken,
    };
  }

  getUserPermissions() {
    const authLocalStorage: string = localStorage.getItem("auth");
    const authData: any = !!authLocalStorage
      ? JSON.parse(authLocalStorage)
      : null;
    const data: any = authData?.permissionToken
      ? decode(authData?.permissionToken)
      : [];
    return data ? data?.permissions : [];
  }

  checkPermissionForNavBlock(routePermissions: string[]) {
    // const permissions = this.getUserPermissions();
    // return !!permissions?.some(
    //   (value) => routePermissions.indexOf(value) !== -1
    // );

    return true
  }

  checkPermissionForNavItem = (routePermission: string) => {
    // const { permissionToken } = authService.getUserInfo();
    // const decodedData: any = permissionToken ? decode(permissionToken) : {};
    // let hasPermission;
    // if (permissionToken && decodedData) {
    //   if (
    //     decodedData.permissions.find((userPermission) => userPermission === "*")
    //   ) {
    //     hasPermission = true;
    //   } else {
    //     hasPermission = decodedData.permissions.find(
    //       (userPermission) => userPermission === routePermission
    //     )
    //       ? true
    //       : false;
    //   }
    // } else hasPermission = false;

    // return !!hasPermission;
    return true
  };

  isLoggedIn() {
    const authLocalStorage: string = localStorage.getItem("auth");
    const authData: any = !!authLocalStorage
      ? JSON.parse(authLocalStorage)
      : null;
    return (
      !!authData?.refreshToken &&
      !!authData?.accessToken &&
      !!authData?.user &&
      !!authData?.permissionToken
    );
  }

  async login({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<IAuthResponse> {
    try {
      const response: any = await apiPost({
        apiPath: LOGIN,
        data: { email, password },
      });
      const authData = {
        accessToken: response?.token,
        refreshToken: response?.refreshToken,
        user: response?.user,
        permissionToken: response?.permissionToken,
      };
      this.storeUserInfo(authData);
      return Promise.resolve(authData);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async resetPasswordRequest(body: { email: string }) {
    try {
      const response: any = await apiPost({
        apiPath: RESET_PASSWORD_REQUEST,
        data: body,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async resetPasswordVerify(body: IResetPasswordVerifyRequestPayload) {
    try {
      const response: any = await apiPost({
        apiPath: RESET_PASSWORD_VERIFY,
        data: body,
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const authService = new AuthService();
