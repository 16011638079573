import * as Yup from "yup";

const baseSchema = {
  title: Yup.string().required("Title is required"),
  isActive: Yup.boolean().optional(),
};

export const PermissionTypeSchema = Yup.object(baseSchema);
const permissionSchemaObject = {
  ...baseSchema,
  permissionType: Yup.string().required("permission type is required")
}
export const PermissionSchema = Yup.object(permissionSchemaObject);
export const RoleSchema = Yup.object(baseSchema);


export type PermissionTypeSchemaType = Yup.InferType<typeof PermissionTypeSchema>;
export type PermissionSchemaType = Yup.InferType<typeof PermissionSchema>;
export type RoleSchemaType = Yup.InferType<typeof RoleSchema>;
