import { Button, TextField, Autocomplete } from "@mui/material";
import Popover from "src/generic-ui/Popover";
import { useFormik } from "formik";
import { useState } from "react";
import { logger } from "src/services";
import { PAYMENT_STATUS } from "src/types";
import { orderService } from "src/services/order.service";
import ToastNotification, {
  notifyError,
  notifySuccess,
} from "src/generic-ui/ToastNotification";
import { replaceSpecialCharacter } from "src/helpers/utility/convert-string";

export default function ChangePaymentStatus({ mutate, orderId }: any) {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPaymentStatus, setSelectedPaymentStatus] =
    useState<string>("");

  const initialOrderChangeUpdateFormValue: {
    paymentStatus: string;
  } = {
    paymentStatus: "",
  };

  const { handleBlur, handleChange, values, handleSubmit, submitForm } =
    useFormik({
      initialValues: initialOrderChangeUpdateFormValue,
      onSubmit: async (values, helper) => {
        try {
          const response = await orderService.updateOrder(orderId, values);
          logger.log(response);
          mutate();
          notifySuccess("Order payment status updated successfully");
          helper.resetForm();
        } catch (error) {
          logger.error(error);
          notifyError(error.message);
        }
      },
    });

  const modifiedPaymentStatus = PAYMENT_STATUS.map((el) => {
    return {
      label: replaceSpecialCharacter(el, " "),
      value: el,
    };
  });

  return (
    <>
      <form action="">
        <Autocomplete
          id="combo-box-demo"
          placeholder="status"
          fullWidth
          value={values.paymentStatus}
          isOptionEqualToValue={(option, value) => option === value}
          options={PAYMENT_STATUS}
          inputValue={inputValue}
          onInputChange={(_, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="payment status"
              placeholder="select payment status"
            />
          )}
          onChange={(event: any, newValue: string | null) => {
            handleChange("paymentStatus")(newValue);
            setSelectedPaymentStatus(newValue);
            setOpen(true);
          }}
          onBlur={handleBlur}
        />
        <Popover
          title=""
          width={400}
          height={200}
          open={open}
          setOpen={setOpen}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="text-center">
            <p className="text-base font-semibold mb-3">
              Update order payment status
            </p>
            <p className="my-2">Status: {values.paymentStatus} </p>
            <Button
              className="bg-red-500 hover:bg-red-600 text-white"
              onClick={() => setOpen(false)}
            >
              close
            </Button>
            <Button
              className="ml-2 bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={() => {
                submitForm();
                setOpen(false);
                setSelectedPaymentStatus("");
              }}
            >
              submit
            </Button>
          </div>
        </Popover>
      </form>
    </>
  );
}
