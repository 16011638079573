import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";
import utils from "src/helpers/Utils";
import { IReorderRequestPayload } from "src/types";

class BannerService {
  private readonly url = "banners";
  async createBanner(body: any) {
    return apiPost({ apiPath: `${this.url}`, data: body });
  }

  async updateBanner({
    id,
    ...banner
  }: any) {
    const data: { [key: string]: any } = {};
    Object.keys(banner).forEach(key => {
      if (!utils.checkIsNullOrUndefined(banner[key])) data[key] = banner[key];
    });

    return apiPatch({ apiPath: `${this.url}/${id}`, data });
  }

  async deleteBanner(id: string) {
    return apiDelete({ apiPath: `${this.url}/${id}` });
  }

  async reorderBanner(body: IReorderRequestPayload) {
    return apiPost({ apiPath: `${this.url}/re-order`, data: body })
  }
}

export const bannerService = new BannerService();
