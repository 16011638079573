import { Grid } from "@mui/material";
import CustomizedTreeView from "../tree/TreeComponent";
import GalleryImagesLayout from "./GalleryImagesLayout";
import { Dispatch, SetStateAction, useState } from "react";
import { foldersTree } from "src/constants/gallery";
import { GalleryContext } from "src/contexts/GalleryContext";

interface IModalConfig {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

interface IGalleryLayout {
    setFieldValue?: any;
    isOpenFromModal?: boolean;
    modalConfig?: IModalConfig;
}

export default function GalleryLayout({ isOpenFromModal, modalConfig, setFieldValue }: IGalleryLayout) {
    const [page, setPage] = useState<number>(1);
    const [paginationCount, setPaginationCount] = useState<number>();
    const [folder, setFolder] = useState<string>("others");

    const treeConfig = {
        folder,
        setFolder
    }
    
    return (
        <GalleryContext.Provider value={{ isOpenFromModal, open: modalConfig?.open, setOpen: modalConfig?.setOpen, setFieldValue: setFieldValue }}>
            <Grid container spacing={2}>
                <Grid item xs={2} lg={2} xl={2} md={12}>
                    <CustomizedTreeView tree={foldersTree} treeConfig={treeConfig} />
                </Grid>
                <Grid item xs={12} lg={10} xl={10} md={12}>
                    <GalleryImagesLayout
                        imageConfig={{ page, setPage, setPaginationCount, folder, setFolder}}
                    />
                </Grid>
            </Grid>
        </GalleryContext.Provider>
    );
}