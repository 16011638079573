import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";
import { IPermissionRequestPayload } from "src/types/acl";
import { authService } from "../auth.service";

class Permission {
  private readonly PERMISSION_URL = "permissions";
  async createPermission(data: IPermissionRequestPayload) {
    return await apiPost({
      apiPath: this.PERMISSION_URL,
      data,
    });
  }

  async updatePermission(id: string, data: IPermissionRequestPayload) {
    const url = `${this.PERMISSION_URL}/${id}`;
    return await apiPatch({
      apiPath: url,
      data,
    });
  }

  async deletePermission(id: string) {
    const url = `${this.PERMISSION_URL}/${id}`;
    return await apiDelete({
      apiPath: url,
    });
  }

  showNavbarByPermission = () => {
    const data = authService.getUserInfo()
  }
}

export const permissionService = new Permission();
