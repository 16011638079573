import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
} from "@mui/material";
import SideBarModal from "src/components/modals/SideBarModal";
import { logger, userService } from "src/services";
import { IModals } from "src/types";
import _without from "lodash/without";
import UpdateTabComponent from "./edit";
import BasicInfo from "./edit/BasicInfo";
import { notifyError, notifySuccess } from "src/generic-ui";
import { useState } from "react";

interface ISidebar extends IModals {
  selectedId?: string;
  onRefresh?: () => void;
}

export default function Sidebar({
  isVisible,
  onClose,
  selectedId,
  onRefresh,
}: ISidebar) {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const onDelete = async () => {
    try {
      setSubmitting(true);
      await userService.deleteUser(selectedId);
      onRefresh?.();
      notifySuccess("User deleted successfully");
      onClose();
    } catch (error) {
      logger.error(error);
      notifyError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <SideBarModal
        isVisible={isVisible}
        onClose={onClose}
        title={!selectedId ? "Add New" : "Edit Item"}
        renderEndIcon={
          !!selectedId && (
            <IconButton onClick={onDelete} disabled={isSubmitting}>
              <DeleteIcon className="text-red-400" />
            </IconButton>
          )
        }
      >
        <div className="mx-4">
          {!selectedId ? <BasicInfo config={{ onRefresh, onClose }} /> : <UpdateTabComponent id={selectedId} config={{ onRefresh, onClose, setOpen }} />}
        </div>
      </SideBarModal>
    </>
  );
}
