import { Dispatch, SetStateAction } from 'react'
import { IModals } from 'src/types';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import SideBarModal from 'src/components/modals/SideBarModal';
import { usePermissionType } from 'src/hooks/acl/permission-type/usePermissionType';

interface IFilterSidebar extends IModals {
    isVisible: boolean;
    setPermissionType: Dispatch<SetStateAction<string>>;
    permissionType: string;
    showResetAllButton: boolean;
    onReset: () => void;
}

export default function FilterSidebar({
    isVisible,
    onClose,
    setPermissionType,
    permissionType,
    showResetAllButton,
    onReset
}: IFilterSidebar) {
    const { permissionTypes , isLoading, mutate } = usePermissionType({
        limit: 100,
        page: 1
    })

    const modifiedPermissionTypes = permissionTypes.filter((permissionType) => permissionType.isActive === true && permissionType.title !== "*").map((permissionType) => {
        return {
            label: permissionType.title,
            value: permissionType.id
        }
    });

    const defaultPermissionType: any = permissionType ? modifiedPermissionTypes.find(modifiedPermissionType => modifiedPermissionType.value === permissionType) : "";

    return (
        <>
            <SideBarModal
                isVisible={isVisible}
                onClose={onClose}
                left={70}
                allowBackdrop={false}
                title={"Filter Permission"}
            >
                <Box sx={{ display: "flex", flexDirection: "column", margin: "1rem" }}>
                    <Box
                        sx={{
                            background: "white",
                            position: "sticky",
                            top: 0,
                            bottom: 20,
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            zIndex: 5,
                        }}
                    >
                        <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    fullWidth
                                    className=""
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    options={modifiedPermissionTypes}
                                    value={defaultPermissionType}
                                    renderInput={(params) => <TextField label="select permission type" {...params} />}
                                    onChange={(event: any, newValue: any) => {
                                        setPermissionType(newValue?.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "left",
                                        marginTop: "1rem",
                                    }}
                                >
                                    {showResetAllButton ? (
                                        <Button
                                            className="bg-red-400 hover:bg-red-800 text-white"
                                            onClick={e => onReset()}
                                        >
                                            Reset All
                                        </Button>
                                    ) : (
                                        <div></div>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </SideBarModal>
        </>
    )
}
