import { PRODUCTS } from "src/constants/apis";
import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";
import utils from "src/helpers/Utils";
import {
  CatalogSchemaType,
  VariantOptSchemaType,
  VariantSchemaType,
} from "src/schemas";
import { IProduct, IReorderRequestPayload } from "src/types";

class ProductService {
  private readonly variantsUrl = "variants";
  private readonly productUrl = "products";
  private readonly variantOptionUrl = "variant-options";

  async createProducts(body: any): Promise<any> {
    try {
      const response: any = await apiPost({
        apiPath: PRODUCTS,
        data: body,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getPesistedProductFormData() {
    const productForm = JSON.parse(
      localStorage.getItem("create-product-form-info"),
    );
    return productForm;
  }

  async createNewCatalogs({
    parentType,

    parentId,
    ...catalog
  }: CatalogSchemaType & {
    parentType: string;
  }) {
    const parentTypeMap = {
      department: "categories",
      category: "sub-categories",
    };

    const url = parentTypeMap?.[parentType] || "departments";

    const data: { [key: string]: any } = {};
    Object.keys(catalog).forEach(key => {
      if (!utils.checkIsNullOrUndefined(catalog[key])) data[key] = catalog[key];
    });

    if (parentId && parentTypeMap?.[parentType]) data[parentType] = parentId;

    return await apiPost({ apiPath: url, data });
  }

  async updateCatalogs({
    type,

    categoryId,
    parentId,
    ...catalog
  }: CatalogSchemaType & { type: string; categoryId: string }) {
    const parentTypeMap = {
      subCategories: "category",
      category: "department",
    };
    const urls = {
      department: "departments",
      category: "categories",
      subCategory: "sub-categories",
    };

    const url = urls?.[type] || "departments";

    const data: { [key: string]: any } = {};
    Object.keys(catalog).forEach(key => {
      if (!utils.checkIsNullOrUndefined(catalog[key])) data[key] = catalog[key];
    });

    if (!!parentId && parentTypeMap?.[type])
      data[parentTypeMap[type]] = parentId;
    return await apiPatch({ apiPath: `${url}/${categoryId}`, data });
  }

  async onDeleteCatalog({ type, id }: { type: string; id: string }) {
    const urls = {
      department: "departments",
      category: "categories",
      subCategory: "sub-categories",
    };
    const url = urls?.[type];

    return await apiDelete({ apiPath: `${url}/${id}` });
  }

  async createVariant(variant: VariantSchemaType) {
    const data: { [key: string]: any } = {};
    Object.keys(variant).forEach(key => {
      if (!utils.checkIsNullOrUndefined(variant[key])) data[key] = variant[key];
    });

    return apiPost({ apiPath: `${this.variantsUrl}`, data });
  }

  async updateVariant({ id, ...variant }: VariantSchemaType & { id: string }) {
    const data: { [key: string]: any } = {};
    Object.keys(variant).forEach(key => {
      if (!utils.checkIsNullOrUndefined(variant[key])) data[key] = variant[key];
    });

    return apiPatch({ apiPath: `${this.variantsUrl}/${id}`, data });
  }

  async deleteVariant(id: string) {
    return apiDelete({ apiPath: `${this.variantsUrl}/${id}` });
  }

  async createVariantOpt(variantOpt: VariantOptSchemaType) {
    const data: { [key: string]: any } = {};
    Object.keys(variantOpt).forEach(key => {
      if (!utils.checkIsNullOrUndefined(variantOpt[key]))
        data[key] = variantOpt[key];
    });

    return apiPost({ apiPath: `${this.variantOptionUrl}`, data });
  }

  async updateVariantOpt({
    id,
    ...variantOpt
  }: VariantOptSchemaType & { id: string }) {
    const data: { [key: string]: any } = {};
    Object.keys(variantOpt).forEach(key => {
      if (!utils.checkIsNullOrUndefined(variantOpt[key]))
        data[key] = variantOpt[key];
    });

    return apiPatch({ apiPath: `${this.variantOptionUrl}/${id}`, data });
  }

  async deleteVariantOpt(id: string) {
    return apiDelete({ apiPath: `${this.variantOptionUrl}/${id}` });
  }

  async updateProduct(id: string, data) {
    try {
      const response: any = await apiPatch({
        apiPath: `${PRODUCTS}/${id}`,
        data,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async bulkUpdate(selectedProducts: IProduct[], values: any) {
    const data: { [key: string]: any } = {};
    Object.keys(values).forEach(key => {
      if (!!values[key] && key !== "specialCategories") {
        data[key] = values[key];
      }
    });

    if (
      !!values["specialCategories"] &&
      Array.isArray(values["specialCategories"])
    ) {
      const specialCategories = values["specialCategories"]?.map(item => ({
        specialCategory: item?.value,
      }));
      data["specialCategories"] = specialCategories;
    }

    const bulkData = selectedProducts.map(product => ({
      id: product.id,
      ...data,
    }));

    return apiPatch({
      apiPath: `${this.productUrl}/bulk`,
      data: {
        bulk: bulkData,
      },
    });
  }

  async reorderVariant(body: IReorderRequestPayload) {
    return apiPost({ apiPath: `${this.variantsUrl}/re-order`, data: body });
  }

  async reorderVariantOption(body: IReorderRequestPayload) {
    return apiPost({ apiPath: `${this.variantOptionUrl}/re-order`, data: body });
  }

  async reorderProductImage(body: IReorderRequestPayload) {
    return apiPost({ apiPath: `${this.productUrl}/re-order-image`, data: body });
  }
}

export const productService = new ProductService();
