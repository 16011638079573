import { ImagesEntity } from 'src/types';
import DeleteIcon from '@mui/icons-material/Delete';
import Variants from './Variants';
import OrderItemQuantity from './OrderItemQuantity';
import { logger } from 'src/services';
import { Box, Button, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import _ from 'lodash';
import { orderService } from 'src/services/order.service';
import { notifyError, notifySuccess } from 'src/generic-ui/ToastNotification';

interface IOrderItemCard {
    orderItem: any;
    orderId?: string;
    mutate?: any;
}

export default function OrderItemCard({ orderItem, orderId, mutate }: IOrderItemCard) {
    const [selectedVariants, setVariants] = useState({});
    const [selectedProdduct, setSelectedProduct] = useState<string>("")
    const [productStocks, setProductStocks] = useState<any>([])
    const imageLink = orderItem?.product?.images?.filter((image: ImagesEntity) => image.isThumb === true)[0]
        ?.link;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setSelectedProduct(orderItem?.product?.id)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOk = async () => {
        try {
            const body = {
                deletedItems: [selectedProdduct]
            }
            //remove item api
            const response = await orderService.updateOrderModify(orderId, body);
            logger.log(response);
            notifySuccess('Order item deleted successfully')
            mutate()
        } catch (error) {
            logger.error(error)
            notifyError(error.message)
        }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const compareArray = (allArray: any[], selectedArray: any[]) => {
        allArray.map((v: any) => {
            v?.data?.map((variant: any) => {
                const isFoundInSelected = selectedArray.find((selected: any) =>
                    selected.variant.id === variant.variant.id &&
                    selected.variantOption.id === variant.variantOption.id
                )

                if (isFoundInSelected) {
                    variant.isSelected = true
                } else {
                    variant.isSelected = false
                }
            })
        })

        return allArray;
    }

    const res = compareArray(orderItem.product.productStocks, orderItem.orderItemVariants);

    return (
        <>
            <li key={orderItem.id} className="flex py-6 px-4 sm:px-6 border my-2">
                <div className="flex-shrink-0">
                    <p
                        className="font-medium text-gray-700 hover:text-gray-800"
                    >
                        <img src={imageLink} alt={''} className="w-16 rounded-md" />
                    </p>
                </div>

                <div className="ml-6 flex flex-1 flex-col">
                    <div className="flex">
                        <div className="min-w-0 flex-1">
                            <p
                                className="font-medium text-gray-700 hover:text-gray-800"
                            >
                                <h4 className="text-sm font-bold">{orderItem?.product?.title}</h4>
                            </p>

                            <div className='my-2'>
                                {res.map((productStock, index) => {
                                    return (
                                        <div key={index}>
                                            <Variants
                                                productStock={productStock} selectedItemVariantOptions={orderItem.orderItemVariants}
                                                config={{ selectedVariants, setVariants, setProductStocks }}
                                                productStocks={res}
                                                productInfo={{
                                                    orderId,
                                                    productId: orderItem?.product?.id,
                                                    quantity: orderItem?.quantity
                                                }}
                                                mutate={mutate}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <OrderItemQuantity quantity={orderItem?.quantity} config={{ orderId, productId: orderItem?.product?.id, mutate }} />
                        </div>
                        <div>
                            <button
                                type="button"
                                className="ml-4 font-medium text-ecom-theme sm:ml-0 sm:mt-2"
                                onClick={handleClick}
                            >
                                <span>
                                    <DeleteIcon className="h-5 w-5" />
                                </span>
                            </button>
                        </div>
                    </div>

                    <div className="flex flex-1 items-end justify-between pt-2">
                        <p className="mt-1 text-sm font-medium text-gray-900">৳{orderItem.mrp}</p>

                        <div className="ml-4">
                            <label htmlFor="quantity" className="sr-only">
                                Quantity
                            </label>
                            x {orderItem.quantity}
                        </div>
                    </div>
                </div>
            </li>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Typography sx={{ paddingY: "1rem", paddingX: "1rem" }}>
                    Do you want to delete this item?
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleOk}>Yes</Button>
                </Box>
            </Popover>

        </>
    )
}
