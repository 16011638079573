import { Box, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import withPermission from 'src/components/permission/withPermission';
import { permissions } from 'src/constants/permissions';
import { notifyError, notifySuccess } from 'src/generic-ui';
import ActionBar from 'src/generic-ui/ActionBar';
import { logger, productRatingService } from 'src/services';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { IProduct } from 'src/types';
import SideBarModal from 'src/components/modals/SideBarModal';
import EmptyState from 'src/generic-ui/EmptyStates';
import SearchProducts from 'src/components/SearchProducts/SearchProducts';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from "@mui/icons-material/Delete";

function CreateReviewRating() {
    const [rating, setRating] = useState<number | null>(null);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedProduct, setSelectedProducts] = useState<any>([]);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const handleRatingChange = (value: number) => {
        if (rating === value) {
            setRating(null);
        } else {
            setRating(value);
        }
    };

    const onToggleModal = () => setModalVisible(prev => !prev);

    const onCloseModal = () => {
        onToggleModal();
    };


    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors: formikError,
        touched,
        setFieldValue,
    } = useFormik({
        initialValues: {
            product: "",
            comment: "",
            rating: "",
        },
        enableReinitialize: true,
        async onSubmit(values, action) {
            try {
                setSubmitting(true);
                const response = await productRatingService.createProductRating(values);
                if (response) {
                    notifySuccess('review & rating created successfully');
                    setFieldValue('rating', "");
                    setFieldValue('product', "");
                }
                action.resetForm();
            } catch (error) {
                logger.error(error);
                notifyError(error.message);
            } finally {
                setSubmitting(false);
            }
        },
    });

    const onSelectProduct = (product: IProduct) => {
        setSelectedProducts([{ ...product }]);
        setFieldValue('product', product.id)
    };

    return (
        <>
            <ActionBar title="Create rating & review" />
            <div className='my-5'>
                <form onSubmit={handleSubmit}>
                    <div className="flex items-center">
                        <span className='mr-3'>Rating number: </span>{[1, 2, 3, 4, 5].map(value => (
                            <button
                                type="button"
                                key={value}
                                className={`pr-2 hover:text-yellow-500 focus:outline-none ${rating && value <= rating ? 'text-yellow-500' : 'text-gray-400'
                                    }`}
                                onClick={() => {
                                    handleRatingChange(value)
                                    setFieldValue('rating', value)
                                }}
                            >
                                {rating && value <= rating ? (
                                    <StarIcon className="h-5 w-5" />
                                ) : (
                                    <StarBorderIcon className="h-5 w-5" />
                                )}
                            </button>
                        ))}
                        {rating && <div className="ml-2">{rating} star</div>}
                    </div>


                    <TextField
                        rows={5}
                        name="comment"
                        multiline
                        placeholder='write comment'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full mt-3"
                        value={values.comment}
                    >
                    </TextField>

                    {selectedProduct?.length > 0 ? selectedProduct.map((product: IProduct, index: number) => {
                        const thumbImageLink = product?.images?.find(image => image?.isThumb === true).link;
                        return (
                            <div className="border rounded-md mt-3" key={index}>
                                <div className="flex p-3">
                                    <img src={thumbImageLink} alt="" className="w-30 h-20" />
                                    <div className="mx-4">
                                        <div className="flex w-full">
                                            <p className="font-semibold capitalize my-1">{product?.title}</p>
                                        </div>
                                        <p className="my-1">৳{product?.newPrice}</p>
                                        <p className="my-1 flex">
                                            {product?.stats ? <>
                                                <StarIcon className="h-5 w-5 mb-0.5 text-yellow-500" /> {product?.stats?.totalRating}
                                            </> : null}
                                        </p>
                                    </div>
                                    <div className="flex w-full">
                                        <button className="ml-auto" onClick={() => setSelectedProducts([])}> <DeleteIcon className="h-5 w-5" /></button>

                                    </div>
                                </div>
                            </div>
                        )
                    }) : <EmptyState mainTitle={"No product found"} tagline="add new product for creating review" buttonTitle="add product" className="mt-3 py-5 border-2 rounded-md border-dashed" onClick={(e) => setModalVisible(true)} showButton />}

                    {isModalVisible && (
                        <SideBarModal
                            isVisible={isModalVisible}
                            onClose={onCloseModal}
                            allowBackdrop={false}
                            left={60}
                            title="Add Product"
                        >
                            <Box p="0.5rem">
                                <SearchProducts
                                    onClick={onSelectProduct}
                                    selectedProducts={selectedProduct?.map(p => p.id)}
                                />
                            </Box>
                        </SideBarModal>
                    )}
                    <LoadingButton
                        loadingIndicator="Loading..."
                        type="submit"
                        loading={isSubmitting}
                        color="primary"
                        className={"bg-indigo-600 hover:bg-indigo-800 mt-3 text-white"}
                    >
                        Submit
                    </LoadingButton>
                </form>
            </div>
        </>
    )
}


export default withPermission(CreateReviewRating, permissions['others'].permissionSet['rating-review'].create);