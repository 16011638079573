import { Dispatch, SetStateAction, useState } from 'react'
import { IModals, QueryParamsType } from 'src/types';
import { Box, Button, Grid } from '@mui/material';
import SideBarModal from 'src/components/modals/SideBarModal';
import { useRole } from 'src/hooks/acl/role/useRole';
import MuiMultiSelectChipBox from 'src/generic-ui/MuiMultiSelectChipBox';

interface IFilterSidebar extends IModals {
    isVisible: boolean;
    selectedOptions: any,
    setSelectedOptions: Dispatch<SetStateAction<any>>,
    showResetAllButton: boolean;
    onReset: () => void;
    onClose: any;
}

export default function FilterSidebar({
    selectedOptions,
    setSelectedOptions,
    isVisible,
    onClose,
    showResetAllButton,
    onReset
}: IFilterSidebar) {
    const [pageSize, setPageSize] = useState<number>(100);
    const [page, setPage] = useState<number>(0);
    const query: Record<string, QueryParamsType> = {
        limit: pageSize,
        page: page + 1,
    };

    const { isLoading, roles, mutate, error, meta } = useRole(query);
    const modifiedRoles = roles?.filter((role) => role?.isActive === true && role?.title !== "*").map((role) => {
        return {
            label: role?.title,
            value: role?.id
        }
    });

    return (
        <>
            <SideBarModal
                isVisible={isVisible}
                onClose={onClose}
                left={70}
                allowBackdrop={false}
                title={"Filter User"}
            >
                <Box sx={{ display: "flex", flexDirection: "column", margin: "1rem" }}>
                    <Box
                        sx={{
                            background: "white",
                            position: "sticky",
                            top: 0,
                            bottom: 20,
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                            zIndex: 5,
                        }}
                    >
                        <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                            <Grid item xs={12}>
                                <MuiMultiSelectChipBox data={modifiedRoles} config={{ selectedOptions, setSelectedOptions }} title="roles" />
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "left",
                                        marginTop: "1rem",
                                    }}
                                >
                                    {showResetAllButton ? (
                                        <Button
                                            className="bg-red-400 hover:bg-red-800 text-white"
                                            onClick={e => onReset()}
                                        >
                                            Reset All
                                        </Button>
                                    ) : (
                                        <div></div>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </SideBarModal>
        </>
    )
}
