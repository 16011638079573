import useRequest from "../useRequest";

export const useProductRatingDetails = (id?: string | null) => {
  const url = `/product-ratings/${id}`;
  const { data, ...rest } = useRequest(!!id ? url : null, url);
  return {
    data: data as any,
    ...rest,
  };
};
