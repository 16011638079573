import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import withPermission from 'src/components/permission/withPermission';
import { permissions } from 'src/constants/permissions';
import { notifyError, notifySuccess } from 'src/generic-ui';
import ActionBar from 'src/generic-ui/ActionBar';
import Spinner from 'src/generic-ui/Spinner';
import { useCity } from 'src/hooks/address/useCity';
import { WarehouseSchema } from 'src/schemas/addressSchema';
import { logger } from 'src/services';
import { warehouseService } from 'src/services/warehouse.service';
import { QueryParamsType } from 'src/types';

function CreateWarehouse() {
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const query: Record<string, QueryParamsType> = {
        limit: 100,
        page: 1,
      };
   const { cities, isLoading } = useCity(query)

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors: formikError,
        touched,
        setValues,
    } = useFormik({
        initialValues: {
            name: "",
            emails: "",
            city: "",
        },
        validationSchema: WarehouseSchema,
        enableReinitialize: true,
        async onSubmit(values, action) {
            try {
                setSubmitting(true);
                await warehouseService.createWarehouse({ ...values });
                notifySuccess("warehouse created successfully");
                action.resetForm()
            } catch (error) {
                logger.error(error);
                notifyError(error.message)
            } finally {
                setSubmitting(false);
            }
        },
    });

    if(isLoading) return <Spinner />

    return (
        <div>
            <ActionBar title="Create warehouse" />
            <Grid item xs={12} md={6} lg={6} xl={6}>
                <form onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Name"
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {formikError.name && touched.name ? (
                        <p className="text-red-600">{formikError.name}</p>
                    ) : null}

                    <TextField
                        rows={5}
                        name="emails"
                        multiline
                        placeholder='write emails (ex. exp_1@ex.com,exp_2@ex.com)'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full mt-3"
                        value={values.emails}
                    >
                    </TextField>
                    {formikError.name && touched.name ? (
                        <p className="text-red-600">{formikError.name}</p>
                    ) : null}

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        label="Select Parent Catalog"
                        margin="dense"
                        placeholder="Select Parent Catalog"
                        onChange={handleChange}
                        name="city"
                        value={values.city}
                        className="mt-3"
                    >
                        {cities?.map((item: any, key: number) => {
                            return (
                                <MenuItem key={key} value={item?.id}>
                                    {item?.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {formikError.city && touched.city ? (
                        <p className="text-red-600">{formikError.city}</p>
                    ) : null}

                    <LoadingButton
                        loadingIndicator="Loading..."
                        type="submit"
                        loading={isSubmitting}
                        color="primary"
                        className={"bg-indigo-600 hover:bg-indigo-800 mt-2 text-white"}
                    >
                        Submit
                    </LoadingButton>
                </form>
            </Grid>
        </div>
    )
}

export default withPermission(CreateWarehouse, permissions['location'].permissionSet.warehouse.create);