import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { logger } from "src/services";
import { IMeta } from "src/types";
import useSWR, { SWRConfiguration, SWRResponse, Cache } from "swr";
import axiosInstance from "../helpers/axios/axiosInstance";

export type GetRequest = AxiosRequestConfig | null | undefined;

interface Return<Data, Error>
  extends Pick<
    SWRResponse<AxiosResponse<Data>, AxiosError<Error>>,
    "isValidating" | "error" | "mutate" | "isLoading"
  > {
  data: Data | undefined;
  meta?: IMeta;
  response: AxiosResponse<Data> | undefined;
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<
    SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>,
    "initialData"
  > {
  initialData?: Data;
}

export default function useRequest<Data = unknown, Error = unknown>(
  cacheKey: string | undefined | null,
  url: string,
  config?: SWRConfiguration & { clearCache?: boolean },
): Return<Data, Error> {
  const {
    data: response,
    error,
    isValidating,
    isLoading,
    mutate,
  } = useSWR<any, AxiosError<Error>>(
    !!cacheKey ? cacheKey : null,
    () => {
      return axiosInstance().get(url);
    },
    {
      ...(!!config && config),
    },
  );

  return {
    data: response && response.data,
    response,
    error,
    isValidating,
    isLoading,
    mutate,
    meta: response?.meta,
  };
}
