import { ImagesEntity } from 'src/types';

interface IOrderItemCard {
    orderItem: any;
}

export default function OrderItemBasicCard({ orderItem }: IOrderItemCard) {
    const imageLink = orderItem?.product?.images?.filter((image: ImagesEntity) => image.isThumb === true)[0]
        ?.link;

    return (
        <>
            <li key={orderItem.id} className="flex py-6 px-4 sm:px-6 border my-2">
                <div className="flex-shrink-0">
                    <p
                        className="font-medium text-gray-700 hover:text-gray-800"
                    >
                        <img src={imageLink} alt={''} className="w-16 rounded-md" />
                    </p>
                </div>

                <div className="ml-6 flex flex-1 flex-col">
                    <div className="flex">
                        <div className="min-w-0 flex-1">
                            <p
                                className="font-medium text-gray-700 hover:text-gray-800"
                            >
                                <h4 className="text-sm font-bold">{orderItem?.product?.title}</h4>
                            </p>

                            <div className='my-2'>
                                {orderItem.orderItemVariants.map((orderItemVariant, index) => {
                                    return (
                                        <div className='flex my-2' key={index}>
                                            <b>{orderItemVariant.variant.title}</b> 
                                            <span className='ml-2 border rounded-md px-2'>{orderItemVariant.variantOption.title}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-1 items-end justify-between pt-2">
                        <p className="mt-1 text-sm font-medium text-gray-900">৳{orderItem.mrp}</p>

                        <div className="ml-4">
                            <label htmlFor="quantity" className="sr-only">
                                Quantity
                            </label>
                            x {orderItem.quantity}
                        </div>
                    </div>
                </div>
            </li>
        </>
    )
}
