import { configureStore } from "@reduxjs/toolkit";
import adminUserReducer from "src/features/adminUserSlice";
import orderReducer from "src/features/orderSlice";
import galleryReducer from "src/features/gallery/galleryslice"

export default configureStore({
  reducer: {
    admin: adminUserReducer,
    orders: orderReducer,
    selectedGalleryImages: galleryReducer
  },
  devTools: true,
});

/*
    FETCHING STATE INSIDE COMPONENT
    const todos = useSelector((state) => state.todos);
*/

/* 
    SET OR DISPATCH STATE INSIDE COMOPONENT
    const dispatch = useDispatch();
    dispatch(setAdminDetails(details)); // setAdminDetails is a reducer function
*/
