import { Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import classNames from "classnames";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

interface IStepperComponent {
  steps: string[];
  getStepContent: (step) => React.ReactNode;
  finishedMessage?: string;
  validationSchema: any;
  initialFormValue: any;
  onSubmitHandler: (el: any) => void;
  persistKeyName: string;
  navigateLink?: string;
}

export default function StepperComponent({
  steps,
  getStepContent,
  finishedMessage,
  initialFormValue,
  onSubmitHandler,
  persistKeyName,
  validationSchema,
  navigateLink,
}: IStepperComponent) {
  const navigate = useNavigate();
  const [savedValues, setSavedValues] = useState(
    localStorage.getItem(persistKeyName)
      ? JSON.parse(localStorage.getItem(persistKeyName))
      : initialFormValue
  );

  const formik = useFormik({
    initialValues: savedValues,
    validationSchema,
    onSubmit: async (values, helper) => {
      onSubmitHandler(values);
      const setStep = 0;
      localStorage.setItem("stepper-step", setStep.toString());
      helper.resetForm();
      if (navigateLink) {
        navigate(navigateLink);
      }
    },
  });

  useEffect(() => {
    localStorage.setItem(persistKeyName, JSON.stringify(formik.values));
  }, [formik.values]);

  useEffect(() => {
    console.log("🚀 ~ StepperComponent ~ formik.errors:", formik.errors);
  }, [formik.errors]);

  const step = Number(localStorage.getItem("stepper-step"));
  const [activeStep, setActiveStep] = useState(step ? step : 0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const { handleSubmit } = formik;

  useEffect(() => {
    localStorage.setItem("stepper-step", activeStep.toString());
  }, [activeStep]);

  return (
    <>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        className="border bg-white rounded-md"
      >
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          {finishedMessage}
        </Typography>
      ) : (
        <>
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit} className="">
              {getStepContent(activeStep)}
              <div className="my-5">
                <Button
                  type="button"
                  className={classNames(
                    "bg-indigo-600 text-white",
                    activeStep === 0
                      ? " !bg-indigo-400 !text-white"
                      : "hover:bg-indigo-800"
                  )}
                  variant="contained"
                  color="primary"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  back
                </Button>

                {activeStep === steps.length - 1 ? null : (
                  <Button
                    className={classNames(
                      "bg-indigo-600 hover:bg-indigo-800",
                      activeStep === steps.length - 1 ? "" : "ml-2"
                    )}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}

                {activeStep === steps.length - 1 ? (
                  <Button
                    type="submit"
                    className={classNames(
                      "ml-2 bg-indigo-600 text-white",
                      activeStep === steps.length - 1
                        ? "hover:bg-indigo-800"
                        : "bg-indigo-400"
                    )}
                    disabled={activeStep === steps.length - 1 ? false : true}
                  >
                    submit
                  </Button>
                ) : null}
              </div>
            </form>
          </FormikProvider>
        </>
      )}
    </>
  );
}
