import { useRoutes } from "react-router-dom";
import routes from "src/router";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import { Provider } from "react-redux";
import store from "./store";
import ToastNotification, { notifyCustom, notifySuccess } from "./generic-ui/ToastNotification";
import { useEffect, useState } from "react";
import { getBaseUrl } from "./helpers/configs/envConfig";

function App() {
  const content = useRoutes(routes);
  const [isOrderNotification, setIsOrderNotifcation] = useState(false);
  useEffect(() => {
    const url = `${getBaseUrl()}/sse/connect`;
    if (typeof (EventSource) !== "undefined") {
      const eventSource = new EventSource(url);
      eventSource.onmessage = (event) => {
        const newData = JSON.parse(event.data);
        notifyCustom(newData?.payload?.message, newData?.payload?.orderCode)
        setIsOrderNotifcation(true);
        setTimeout(() => {
          setIsOrderNotifcation(false)
        }, 7000);
      };
      return () => {
        eventSource.close();
      };
    } else {
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {content}
          {isOrderNotification ?  <ToastNotification position="top-right" reverseOrder={false} /> : <ToastNotification position="top-center" reverseOrder={false} />}
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
}
export default App;
