import { Autocomplete, TextField } from '@mui/material'
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { demoAutoCompleteList } from 'src/@fakedb/demo'
import { useCategories } from 'src/hooks/catalogs/useCategories';
import { QueryParamsType } from 'src/types/query-params';

export default function Categories({ queryConfig, categoryConfig }: any) {
    const { values, handleChange, handleBlur, errors, touched } = useFormikContext<any>();
    const { department } = queryConfig;
    const { category, setCategory } = categoryConfig;

    const query: Record<string, QueryParamsType> = {
        limit: 40,
        page: 1
    }
    query['department'] = department;
    const { categories, isLoading, mutate } = useCategories(query);

    const [selectedCategory, setSelectedCategory] = useState<Record<string, string>>(values.category ? values.category : {})

    useEffect(() => {
        setSelectedCategory(values.category)
        setCategory(values.category)
    }, [values.department]);

    useEffect(() => {
        mutate();
    }, [mutate]);

    if (isLoading) return null;

    const modifiedCategories = categories.filter((category) => category.isActive === true).map((activeCategory) => {
        return {
            label: activeCategory.title,
            value: activeCategory.id
        }
    });

    const defaultOption = modifiedCategories.find(option => option.value === selectedCategory);

    return (
        <>
            <Autocomplete
                id="combo-box-demo"
                fullWidth
                defaultValue={defaultOption}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                className="mt-4"
                options={modifiedCategories}
                renderInput={(params) => <TextField {...params} label="select categories" />}
                onChange={(event: any, newValue: Record<string, any>) => {
                    handleChange('category')(newValue.value);
                    setCategory(newValue.value);
                }}
                onBlur={handleBlur}
            />
            {errors.category && touched.category ? (
                <p className="text-red-600">{errors.category}</p>
            ) : null}
        </>
    )
}
