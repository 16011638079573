import useRequest from "src/hooks/useRequest";

export const useBannerDetails = (id?: string) => {
  const url = `/banners/${id}`;
  const { data, mutate, ...rest } = useRequest(!!id ? url : null, url);

  return {
    data: data as any,
    isLoading: rest.isLoading,
    error: rest.error,
    mutate,
  };
};
