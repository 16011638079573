import { IInitialProductForm } from "src/pages/product";

export const parsedProductFormRequest = (body: IInitialProductForm) => {
  const {
    title,
    description,
    specification,
    liftingPrice,
    liftingPriceVat,
    mrp,
    mrpVat,
    stock,
    manageCustomInventory,
    brand,
    department,
    category,
    // subCategory,
    images,
    variants,
  } = body;

  return {
    title,
    description,
    specification,
    liftingPrice,
    liftingPriceVat,
    mrp,
    mrpVat,
    stock,
    manageCustomInventory,
    brand,
    department,
    category,
    // subCategory,
    variants,
    images,
  };
};

export const parsedProductUpdateFormRequest = (metaData: any) => {
  const {
    title,
    description,
    specification,
    unit,
    brand,
    department,
    category,
    subCategory,
    images,
    variants,
    isActive,
    isFeatured,
    isForceStockout,
    isNewArrival,
    isBulk,
    specialCategories,
  } = metaData;

  const parsedUpdateProductObject = {
    title,
    description,
    specification,
    unit,
    brand,
    department,
    category,
    subCategory,
  };
  if (!!isBulk) {
    parsedUpdateProductObject["isActive"] = isActive;
    parsedUpdateProductObject["isFeatured"] = isFeatured;
    parsedUpdateProductObject["isForceStockout"] = isForceStockout;
    parsedUpdateProductObject["isNewArrival"] = isNewArrival;
    parsedUpdateProductObject["specialCategories"] = specialCategories;
  }

  images.length > 0 ? (parsedUpdateProductObject["images"] = images) : null;
  variants.length > 0
    ? (parsedUpdateProductObject["variants"] = variants)
    : null;
  return parsedUpdateProductObject;
};
