import React from 'react'

export default function OrderAmountInfo({ orderDetails }: any) {
  return (
    <div className='border p-1'>
      <div>
        <div className="px-2 py-2">
          <p className="capitalize font-semibold py-4 px-5">
            amount information
          </p>
          <div className="flex items-center justify-between px-6 py-2">
            <dt className="text-sm">Sub total</dt>
            <dd className="text-sm font-bold text-gray-900">৳{orderDetails.subTotal}</dd>
          </div>
          <div className="flex items-center justify-between px-6 py-2">
            <dt className="text-sm">Delivery charnge</dt>
            <dd className="text-sm text-gray-900 font-bold">৳{orderDetails.deliveryCharge}</dd>
          </div>
          <div className="flex items-center justify-between px-6 py-2">
            <dt className="text-sm">Discount</dt>
            <dd className="text-sm text-gray-900 font-bold">৳{orderDetails.discount}</dd>
          </div>
          <div className="flex items-center justify-between px-6 py-2">
            <dt className="text-sm">Coupon discount</dt>
            <dd className="text-sm text-gray-900 font-bold">৳{orderDetails.couponDiscount}</dd>
          </div>
          <hr />
          <div className="flex items-center justify-between px-6 py-2">
            <dt className="text-sm">Total</dt>
            <dd className="text-sm font-bold text-gray-900">৳{orderDetails.total}</dd>
          </div>
        </div>
      </div>
    </div>
  )
}
