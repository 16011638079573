import { Box, ListItem, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import classNames from 'classnames';

export type Item = {
  id: string;
  title: string;
  imageLink?: string;
  secondary?: string;
};

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)',
    borderRadius: '5px'
  }
});

export type DraggableListItemProps = {
  item: Item;
  index: number;
  cName?: string;
};

const DraggableListItem = ({ item, index, cName }: DraggableListItemProps) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : 'border my-1 rounded-md'}
        >
          <DragIndicatorIcon className='mr-2'/>
          {item.imageLink ?
            <img src={item.imageLink} className={classNames('border', cName)} />
            : null}

          <Box className='ml-2'>
            <div className='font-semibold'>{item.title}</div>
            <div>{item?.secondary}</div>
          </Box>
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
