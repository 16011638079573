import { Autocomplete, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import Popover from "src/generic-ui/Popover";
import ToastNotification, {
  notifyError,
  notifySuccess,
} from "src/generic-ui/ToastNotification";
import { replaceSpecialCharacter } from "src/helpers/utility/convert-string";
import { logger } from "src/services";
import { orderService } from "src/services/order.service";
import { ORDER_STATUS } from "src/types";

export default function ChangeOrderStatus({ mutate, id, currentStatus }: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<string>("");

  const initialOrderChangeUpdateFormValue: {
    orderStatus: string;
    comments: string;
  } = {
    orderStatus: "",
    comments: "",
  };
  const { handleBlur, handleChange, values, handleSubmit, submitForm } =
    useFormik({
      initialValues: initialOrderChangeUpdateFormValue,
      onSubmit: async (values, helper) => {
        try {
          console.log(values);

          const response = await orderService.updateOrderStatus(id, values);
          logger.log(response);
          mutate();
          notifySuccess("Order status updated successfully");
          helper.resetForm();
        } catch (error) {
          logger.error(error);
          notifyError(error.message);
        }
      },
    });

  const defaultStatusOption = ORDER_STATUS.map((option) =>
    replaceSpecialCharacter(option, " ")
  );

  return (
    <form>
      <Autocomplete
        id="combo-box-demo"
        placeholder="status"
        fullWidth
        className=""
        getOptionDisabled={(option) => {
          if (currentStatus === "PENDING") {
            return option != ORDER_STATUS[1] && option != ORDER_STATUS[5];
          } else if (currentStatus === "CONFIRMED") {
            return option != ORDER_STATUS[2] && option != ORDER_STATUS[5];
          } else if (currentStatus === "PROCESSING") {
            return option != ORDER_STATUS[3] && option != ORDER_STATUS[5];
          } else if (currentStatus === "OUT_FOR_DELIVERY") {
            return (
              option != ORDER_STATUS[4] &&
              option != ORDER_STATUS[5] &&
              option != ORDER_STATUS[6] &&
              option != ORDER_STATUS[7]
            );
          } else if (
            currentStatus === "DELIVERED" ||
            currentStatus === "CANCELLED"
          ) {
            return true;
          }
        }}
        value={values.orderStatus}
        isOptionEqualToValue={(option, value) => option === value}
        options={ORDER_STATUS}
        renderInput={(params) => (
          <TextField
            {...params}
            label="order status"
            placeholder="select order status"
          />
        )}
        onChange={(event: any, newValue: string | null) => {
          handleChange("orderStatus")(newValue);
          setSelectedOrderStatus(newValue);
          setOpen(true);
        }}
        onBlur={handleBlur}
      />

      <Popover
        title=""
        width={600}
        open={open}
        setOpen={setOpen}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="h-80 w-80 object-center mx-auto">
          <p className="text-base font-semibold my-4">Update order status</p>
          <p>Status: {values.orderStatus}</p>
          <TextField
            rows={5}
            name="comments"
            multiline
            className="w-full my-3"
            placeholder="comments (optional)"
            onChange={handleChange}
            onBlur={handleBlur}
          ></TextField>
          <Button
            className="bg-indigo-500 hover:bg-indigo-600 text-white"
            onClick={() => {
              submitForm();
              setOpen(false);
              setSelectedOrderStatus("");
            }}
          >
            submit
          </Button>
        </div>
      </Popover>
    </form>
  );
}
