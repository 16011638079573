import { useFormikContext } from 'formik';
import TextEditor from 'src/generic-ui/RichTextEditor';

export default function DescAndSpec() {
    const { values, handleChange, errors, touched } = useFormikContext<any>();
    return (
        <>
            <div className="my-3">
                <p className="mb-3 text-sm capitalize font-bold">description</p>
                <TextEditor value={values.description} onChange={(value) => {
                    handleChange('description')(value)
                }} />
                <div className='mt-12'>
                {errors.description && touched.description ? (
                <p className="text-red-600">{errors.description}</p>
            ) : null}
                </div>
            </div>

        
            <div className="mt-10 mb-16">
                <p className="mb-3 text-sm capitalize font-bold">specification</p>
                <TextEditor value={values.specification} onChange={(value) => {
                    handleChange('specification')(value)
                }} />
            </div>

            {errors.specification && touched.specification ? (
                <p className="text-red-600">{errors.specification}</p>
            ) : null}
        </>
    )
}
