import { createSlice } from "@reduxjs/toolkit";

export const gallerySlice = createSlice({
  name: "gallery",
  initialState: [],
  reducers: {
    setGallaryImages: (state, action) => {
        return action.payload
    }
  },
});

// this is for dispatch
export const { setGallaryImages } = gallerySlice.actions;

// this is for configureStore
export default gallerySlice.reducer;
