import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import SideBarModal from "src/components/modals/SideBarModal";
import SuspenseLoader from "src/components/SuspenseLoader";
import { notifyError, notifySuccess } from "src/generic-ui";
import { useWarehouseDetails } from "src/hooks/address/useWarehouseDetails";
import { WarehouseSchema, WarehouseSchemaType } from "src/schemas/addressSchema";
import { logger } from "src/services";
import { warehouseService } from "src/services/warehouse.service";
import { IModals } from "src/types";

interface ISidebar extends IModals {
    selectedId?: string;
    onRefresh?: () => void;
}
export default function Sidebar({
    isVisible,
    onClose,
    selectedId,
    onRefresh,
}: ISidebar) {
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const { isLoading, data, mutate, error, cities } = useWarehouseDetails(selectedId);

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors: formikError,
        touched,
        setValues,
    } = useFormik({
        initialValues: {
            name: data?.name || "",
            emails: data?.emails || "",
            city: data?.cityId || "",
        },
        validationSchema: WarehouseSchema,
        enableReinitialize: true,
        onSubmit(values) {
            onEditSubmit(values);
        },
    });

    if (isLoading) {
        return <SuspenseLoader />;
    }

    const onEditSubmit = async (values: WarehouseSchemaType) => {
        try {
            setSubmitting(true);
            await warehouseService.updateWarehouse({ ...values, id: selectedId });
            onRefresh?.();
            onClose();
            notifySuccess("warehouse updated successfully")
        } catch (error) {
            logger.error(error);
            notifyError(error.message)
        } finally {
            setSubmitting(false);
        }
    };

    const onDelete = async () => {
        try {
            setSubmitting(true);
            await warehouseService.deleteWarehouse(selectedId);
            notifySuccess("warehouse deleted successfully")
            onRefresh?.();
            onClose();
        } catch (error) {
            logger.error(error);
            notifyError(error.message)
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <SideBarModal
                isVisible={isVisible}
                onClose={onClose}
                title={!selectedId ? "Add New" : "Edit Item"}
                renderEndIcon={
                    !!selectedId && (
                        <IconButton onClick={onDelete} disabled={isSubmitting}>
                            <DeleteIcon className="text-red-400" />
                        </IconButton>
                    )
                }
            >
                <Box paddingX={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Name"
                                name="name"
                                type="text"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {formikError.name && touched.name ? (
                                <p className="text-red-600">{formikError.name}</p>
                            ) : null}

                            <TextField
                                rows={5}
                                name="emails"
                                multiline
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="w-full mt-3"
                                value={values.emails}
                            >
                            </TextField>
                            {formikError.name && touched.name ? (
                                <p className="text-red-600">{formikError.name}</p>
                            ) : null}

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth
                                label="Select Parent Catalog"
                                margin="dense"
                                placeholder="Select Parent Catalog"
                                onChange={handleChange}
                                name="city"
                                value={values.city}
                                className="mt-2"
                            >
                                {cities?.map((item: any, key: number) => {
                                    return (
                                        <MenuItem key={key} value={item?.id}>
                                            {item?.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {formikError.city && touched.city ? (
                                <p className="text-red-600">{formikError.city}</p>
                            ) : null}

                            <LoadingButton
                                loadingIndicator="Loading..."
                                type="submit"
                                loading={isSubmitting}
                                color="primary"
                                className={"bg-indigo-600 hover:bg-indigo-800 mt-2 text-white"}
                            >
                                Submit
                            </LoadingButton>
                        </form>
                    </Grid>
                </Box>
            </SideBarModal>
        </>
    );
}
