import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  DEBOUNCE_DELAY,
  DEFAULT_PAGE_SIZE,
} from "src/constants/globalConstants";
import BreadCrumbs from "src/generic-ui/BreadCrumbs";
import { DataTable } from "src/generic-ui/DataTable";
import Switch from "src/generic-ui/Switch";
import { useTestimonialsList } from "src/hooks/testimonials/useTestimonialsList";
import { useDebounce } from "src/hooks/useDebounce";
import { logger, productRatingService } from "src/services";
import { testimonialService } from "src/services/testimonial.service";
import { ITestimonial } from "src/types";
import { QueryParamsType } from "src/types/query-params";
import { permissions } from "src/constants/permissions";
import withPermission from "src/components/permission/withPermission";
import { Helmet } from "react-helmet-async";
import Sidebar from "./Sidebar";
import { useProductRating } from "src/hooks/product-rating/useProductRating";
import ActionBar from "src/generic-ui/ActionBar";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

function RatingReview() {
  const [isSidebarVisible, setVisible] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [selectedId, setSelected] = useState<string | null>(null);
  const debouncedSearchTerm = useDebounce({
    searchQuery: searchTerm,
    delay: DEBOUNCE_DELAY,
  });
  const query: Record<string, QueryParamsType> = {
    limit: pageSize,
    page: page + 1,
  };
  if (!!debouncedSearchTerm) query["searchTerm"] = debouncedSearchTerm;

  const { isLoading, productRatings, mutate, error, meta } =
    useProductRating(query);
  const onToggleSideBar = () => setVisible((prev) => !prev);

  const [rowCountState, setRowCountState] = useState(meta?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.total !== undefined ? meta?.total : prevRowCountState
    );
  }, [meta, setRowCountState]);

  const onClose = () => {
    onToggleSideBar();
    setSelected(null);
  };

  const handleOnCellClick = ({ row }: { row: ITestimonial }) => {
    setSelected(row.id);
    onToggleSideBar();
  };

  const onRefresh = () => {
    mutate();
  };

  const updateElement = async ({
    keyName,
    id,
    value,
  }: {
    keyName: string;
    id: string;
    value: any;
  }) => {
    try {
      await productRatingService.updateProductRating({ id, [keyName]: value });
    } catch (error) {
      logger.error(error);
    } finally {
      onRefresh();
    }
  };

  const columns: any = [
    {
      field: "product.title",
      headerName: "Product Name",
      flex: 1,
      renderCell: ({ row }: { row: any }) => <>{row?.product?.title}</>,
    },
    {
      field: "rating",
      headerName: "Rating number",
      flex: 1,
    },

    {
      field: "comment",
      headerName: "comment",
      flex: 1,
    },

    {
      field: "user.name",
      headerName: "username",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <>
          {row?.user?.firstName && row?.user?.lastName
            ? row?.user?.firstName + " " + row?.user?.lastName
            : "Random user"}
        </>
      ),
    },

    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      renderCell: ({ row }: { row: ITestimonial }) => (
        <Switch
          isChecked={!!row.isActive}
          showPopOver={true}
          onClick={(isChecked: boolean) =>
            updateElement({ keyName: "isActive", value: isChecked, id: row.id })
          }
        />
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: ITestimonial }) => (
        <IconButton
          onClick={() => {
            handleOnCellClick({ row });
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  if (error) {
    return <div>Something Went Wrong</div>;
  }

  const onReset = () => {
    setSearchTerm("");
  };

  const showResetAllButton = useMemo(() => {
    return !!debouncedSearchTerm;
  }, [debouncedSearchTerm]);

  return (
    <>
      <Helmet>
        <title>AAAF LTD - Rating & Review</title>
      </Helmet>
      <ActionBar title="Ratings & reviews">
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <input
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className=" block  rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
          />
          {showResetAllButton && (
            <Tooltip arrow title="reset filter">
              <IconButton
                className="bg-red-500 hover:bg-red-600 text-white ml-2 p-1.5"
                onClick={() => {
                  onReset();
                }}
              >
                <RestartAltIcon fontSize="medium" className="text-white" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </ActionBar>
      <DataTable
        columns={columns}
        data={productRatings}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        disableColumnMenu={true}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCountState={rowCountState}
      />
      {isSidebarVisible && (
        <Sidebar
          isVisible={isSidebarVisible}
          onClose={onClose}
          selectedId={selectedId}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
}

export default withPermission(
  RatingReview,
  permissions["others"].permissionSet["rating-review"].view
);
