import { QueryParamsType } from "src/types/query-params";
import qs from "qs";
import useRequest from "src/hooks/useRequest";

export const useMostOrderedProducts = ( query: Record<string, QueryParamsType>) => {
  const url = `/dashboard/most-ordered-products?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);
  return {
    data: data as any,
    mutate,
    isLoading,
    error,
    ...rest,
  };
};
