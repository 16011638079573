import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import classNames from 'classnames';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import withPermission from 'src/components/permission/withPermission';
import { permissions } from 'src/constants/permissions';
import { notifyError, notifySuccess } from 'src/generic-ui';
import ActionBar from 'src/generic-ui/ActionBar';
import TextEditor from 'src/generic-ui/RichTextEditor';
import Spinner from 'src/generic-ui/Spinner';
import { useSubscription } from 'src/hooks/subscription/useSubscription';
import { logger, marketingService } from 'src/services';
import SendIcon from '@mui/icons-material/Send';
import { ISendEmailRequestBody } from 'src/types';
import { parsedSendEmailRequestPayload } from 'src/transformer';
function SendEmail() {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const { isLoading, subscriptions, mutate, error, meta } = useSubscription({});

    const initialSendEmailValue : ISendEmailRequestBody = {
        sendToAll: false,
        emails: [],
        subject: "",
        message: ""
    }

    const { handleBlur, handleChange, values, handleSubmit, setFieldValue } =
        useFormik({
            initialValues: initialSendEmailValue,
            onSubmit: async (values, helper) => {
                setSubmitting(true);
                try {
                    await marketingService.sendEmailToSubscriber(parsedSendEmailRequestPayload(values));
                    notifySuccess("Email sent to subscribers");
                    helper.resetForm();
                    setSelectAll(false);
                    setSelectedOptions([])
                } catch (error) {
                    logger.error(error);
                    notifyError(error.message);
                }
                finally {
                    setSubmitting(false)
                }
            },
        });

    useEffect(() => {
        setFieldValue('emails', selectedOptions);
    }, [selectedOptions]);

    useEffect(() => {
        if(selectAll) {
            setFieldValue('sendToAll', true);
        }
    }, [selectAll]);

    if (isLoading) return <Spinner />;

    const modifiedSubscriptions = subscriptions.map((subscription) => {
        return {
            label: subscription.email,
            value: subscription.email,
        }
    })

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions.includes(value)) {
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                return [...prevSelectedOptions, value];
            }
        });
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        if (checked) {
            setSelectedOptions(modifiedSubscriptions.map((option) => option.value));
        } else {
            setSelectedOptions([]);
        }
    };

    return (
        <>
            <ActionBar title="Send Emails" />
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormGroup className='mt-5 border rounded-md p-4'>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
                                }
                                label="Select All"
                            />
                            {modifiedSubscriptions?.map((option) => (
                                <FormControlLabel
                                    key={option.value}
                                    control={
                                        <Checkbox
                                            checked={selectedOptions.includes(option.value)}
                                            onChange={handleOptionChange}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </FormGroup>
                        <LoadingButton
                            loadingIndicator="Loading..."
                            type="submit"
                            loading={isSubmitting}
                            color="primary"
                            className={classNames(
                                "bg-indigo-600 hover:bg-indigo-800 mt-2 text-white",
                            )}
                        >
                           <SendIcon className='h-5 w-5 mr-1'/> Send
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Subject"
                            name="subject"
                            type="text"
                            className='mt-5'
                            onBlur={handleBlur}
                            value={values.subject}
                            onChange={handleChange}
                        />
                        <TextEditor value={values.message} onChange={(value) => {
                            handleChange('message')(value)
                        }} className="mt-5 h-48" />
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default withPermission(SendEmail, permissions['marketing'].permissionSet.email.send);