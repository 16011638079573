import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import CancelIcon from '@mui/icons-material/Cancel';

export default function ProductImages({ modifiedImages, config}: any) {
    const { setFieldValue } = useFormikContext<any>();
    const { setOpenGallery } = config;
    return (
        <div>
            <RadioGroup name="isThumb">
                <div className="grid grid-cols-5">
                    {modifiedImages.filter((image) => image.isDeleted === false).map((image, index) => {
                        return (
                            <div key={index} className={classNames("relative h-18 w-16 mx-4", image.isDeleted ? " h-16 w-14" : "")}>
                                <span className="absolute -right-2 -top-2 cursor-pointer" onClick={() => {
                                    const copyImages = [...modifiedImages];
                                    const modifiedSelectedImages = copyImages.map(imageBox => {
                                        if (image.idx === imageBox.idx) {
                                            imageBox.isDeleted = !imageBox.isDeleted;
                                        }
                                        return imageBox;
                                    });
                                    setFieldValue('images', modifiedSelectedImages)
                                }}>
                                    <CancelIcon className="h-4 w-4 fill-red-600 z-10 bg-white rounded-full " />
                                </span>
                                <img src={image.link} />
                                <FormControlLabel value="end" control={
                                    <Radio
                                        checked={image.isThumb ? true : false}
                                        onChange={(event) => {
                                            const copyImages = [...modifiedImages];
                                            const modifiedSelectedImages = copyImages.map(imageBox => {
                                                if (image.idx === imageBox.idx) {
                                                    imageBox.isThumb = !imageBox.isThumb;
                                                    imageBox.isSelectedForThumb = true
                                                } else {
                                                    imageBox.isThumb = false;
                                                    imageBox.isSelectedForThumb = false;
                                                }
                                                return imageBox;
                                            });
                                            setFieldValue('images', modifiedImages)
                                        }}
                                        value="isThumb"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }} size="small" />
                                } label="thumb" />
                            </div>
                        )
                    })}
                </div>
            </RadioGroup>

            <Button className="" onClick={() => setOpenGallery(true)}>+ Add new image</Button>

        </div>
    )
}
